import React from 'react'
import PopoverHelp from '../../utils/buttons/popoverHelp'
import './inputText'

const InputText = ({ label, onChange, value, ...props }) => {
  return (
    <>
      <div className='input-box'>
        <div className='popover-help'>
          <label className='label-text'>{label}</label>
          {props.popoverHelp ? <PopoverHelp content={props.popoverHelp} /> : ''}
        </div>
        <div className='form-group'>
          <input
            className='form-control'
            onChange={onChange}
            value={value}
            {...props}
          />
          {props.error && (
            <>
              <div style={{ color: 'red' }} className='error'>
                {props.error}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default InputText
