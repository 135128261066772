import React from 'react'
import './sideBar.css'
import { NavLink } from 'react-router-dom'
import GroupIcon from '@material-ui/icons/Group'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import { PermissionAgent } from '../features/permissions/permissionAgent'

const SideBar = () => {
  return (
    <div className='dashboard-sidebar'>
      <div className='dashboard-nav-trigger'>
        <div className='dashboard-nav-trigger-btn'>
          <i className='la la-bars'></i> Dashboard Nav
        </div>
      </div>
      <div className='dashboard-nav-container'>
        <div className='humburger-menu'>
          <div className='humburger-menu-lines side-menu-close'></div>
        </div>
        <div className='side-menu-wrap'>
          <ul className='side-menu-ul'>
            <PermissionAgent
              resource={'dashboard'}
              scope={'retrieve'}
              Component={() => (
                <li className='sidenav__item'>
                  <NavLink activeClassName='active' to='/dashboard'>
                    <i className='la la-dashboard'></i>Dashboard
                  </NavLink>
                </li>
              )}
            />
            <PermissionAgent
              resource={'user'}
              scope={'retrieve'}
              Component={() => (
                <>
                  <li className='sidenav__item'>
                    <NavLink activeClassName='active' to='/users'>
                      <i className='la la-user'></i>Usuários
                    </NavLink>
                  </li>
                </>
              )}
            />
            <PermissionAgent
              resource={'candidate'}
              scope={'list'}
              Component={() => (
                <li className='sidenav__item'>
                  <NavLink activeClassName='active' to='/candidates'>
                    <i className='la la-user'></i>Candidatos
                  </NavLink>
                </li>
              )}
            />
            <PermissionAgent
              resource={'client'}
              scope={'retrieve'}
              Component={() => (
                <li className='sidenav__item'>
                  <NavLink activeClassName='active' to='/clients'>
                    <i className='la la-user'></i>Clientes
                  </NavLink>
                </li>
              )}
            />
            <PermissionAgent
              resource={'group'}
              scope={'retrieve'}
              Component={() => (
                <li className='sidenav__item'>
                  <NavLink activeClassName='active' to='/groups'>
                    <GroupIcon
                      style={{ marginRight: '8px' }}
                      fontSize='small'
                    />
                    Grupos
                  </NavLink>
                </li>
              )}
            />
            <PermissionAgent
              resource={'knowledge-area'}
              scope={'retrieve'}
              Component={() => (
                <li className='sidenav__item'>
                  <NavLink activeClassName='active' to='/knowledge-areas'>
                    <LibraryBooksIcon
                      style={{ marginRight: '8px' }}
                      fontSize='small'
                    />
                    Áreas de Conhecimento
                  </NavLink>
                </li>
              )}
            />
            <PermissionAgent
              resource={'behavior-area'}
              scope={'retrieve'}
              Component={() => (
                <li className='sidenav__item'>
                  <NavLink activeClassName='active' to='/behavior-area'>
                    <LibraryBooksIcon
                      style={{ marginRight: '8px' }}
                      fontSize='small'
                    />
                    Áreas de Comportamento
                  </NavLink>
                </li>
              )}
            />
            <PermissionAgent
              resource={'quiz'}
              scope={'retrieve'}
              Component={() => (
                <li className='sidenav__item'>
                  <NavLink activeClassName='active' to='/quizzes'>
                    <i className='la la-file-text-o'></i>Provas
                  </NavLink>
                </li>
              )}
            />
            <PermissionAgent
              resource={'reports'}
              scope={'retrieve'}
              Component={() => (
                <li className='sidenav__item'>
                  <NavLink activeClassName='active' to='/reports'>
                    <i className='la la-bolt'></i>Relatórios
                  </NavLink>
                </li>
              )}
            />
          </ul>
        </div>
      </div>
    </div>
  )
}

export default SideBar
