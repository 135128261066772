import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import $ from 'jquery'
import Message from '../utils/messages/message'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import InputText from '../utils/form/inputText'
import InputMask from '../utils/form/inputMask'
import SelectInput from '../utils/form/selectInput'
import HttpRequests from '../api/httpRequests'

function NewClient() {
  let history = useHistory()
  const [loading, setLoading] = useState(true)
  const [msgType, setMsgType] = useState('')

  useEffect(() => {
    window.$('.sort-ordering-select-no-search').selectpicker()
    if (loading === true) {
      $('.preloader').show()
      setLoading(false)
    } else {
      $('.preloader').delay('500').fadeOut(1000)
    }
  })

  if (loading === false) {
    return (
      <>
        <Formik
          initialValues={{
            name: '',
            phone: '',
            email: '',
            status: 'active'
          }}
          validationSchema={Yup.object({
            name: Yup.string()
              .min(2, 'Deve ter entre 2 e 80 caracteres')
              .max(80, 'Deve ter entre 2 e 80 caracteres')
              .required('Campo obrigatório'),
            email: Yup.string()
              .max(80, 'Deve no máximo 80 caracteres')
              .email('E-mail inválido'),
            phone: Yup.string()
              .transform(value => value.replace(/[^\d]/g, ''))
              .min(13, 'Digite um número de telefone válido')
              .max(14, 'Digite um número de telefone válido'),
            status: Yup.string().required('Campo obrigatório')
          })}
          onSubmit={async (values, { setSubmitting }) => {
            $('.preloader').show()

            try {
              let data = {
                name: values.name,
                status: values.status
              }

              if (values.email !== '') {
                data = { ...data, email: values.email }
              }

              if (values.phone !== '') {
                data = { ...data, phone: values.phone.replace(/[^\d.]/g, '') }
              }

              await HttpRequests.creatClient(data)
              setSubmitting(false)
              setMsgType('insert-successful')
              history.push('/clients')
            } catch (error) {
              switch (error.response.status) {
                case 401:
                  setMsgType('401')
                  break

                case 403:
                  setMsgType('403')
                  break

                case 409:
                  setMsgType('409')
                  break

                default:
                  setMsgType('500')
              }

              window.scrollTo(0, 0)
            } finally {
              $('.preloader').delay('500').fadeOut(1000)
            }
          }}
        >
          <Form>
            <div className='row mt-5'>
              <div className='col-lg-12'>
                <Message msgType={msgType} />
                <div className='card-box-shared'>
                  <div className='card-box-shared-title'>
                    <h3 className='widget-title'>Cadastrar Cliente</h3>
                  </div>
                  <div className='card-box-shared-body'>
                    <div className='user-form'>
                      <div className='contact-form-action'>
                        <div className='row'>
                          <div className='col-lg-6 col-sm-6'>
                            <InputText
                              label='Nome do Cliente'
                              name='name'
                              type='text'
                            />
                          </div>
                          <div className='col-lg-6 col-sm-6'>
                            <InputText
                              label='E-mail'
                              name='email'
                              type='text'
                            />
                          </div>
                          <div className='col-lg-6 col-sm-6'>
                            <InputMask
                              label='Telefone'
                              name='phone'
                              type='text'
                              mask={[
                                '+',
                                /[1-9]/,
                                /\d/,
                                ' ',
                                '(',
                                /[1-9]/,
                                /\d/,
                                ')',
                                ' ',
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/
                              ]}
                            />
                          </div>
                          <div className='col-lg-6 col-sm-6'>
                            <SelectInput
                              label='Status'
                              name='status'
                              className='sort-ordering-select-no-search'
                            >
                              <option value='active'>Ativo</option>
                              <option value='inactive'>Inativo</option>
                            </SelectInput>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12'>
                <button className='theme-btn' type='submit'>
                  Cadastrar
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      </>
    )
  }
  return <div></div>
}

export default NewClient
