import keycloak from '../keycloak'
import HttpRequests from './httpRequests'

class AuthService {
  onKeycloakEvent = async event => {
    if (event === 'onAuthSuccess') {
      keycloak
        .loadUserProfile()
        .then(function (profile) {
          localStorage.setItem('user_info', JSON.stringify(profile, null, '  '))
        })
        .catch(function () {
          alert('Failed to load user profile')
        })
    }
  }

  onKeycloakTokens = tokens => {
    if (localStorage.getItem('tokens')) {
      localStorage.removeItem('tokens')
    }
    localStorage.setItem('tokens', JSON.stringify(tokens, null, '  '))
  }

  async getPermissions() {
    let response = await HttpRequests.getPermissions()

    let resp = response.data.data
    let data = {}

    for (let i = 0; i < resp.length; i++) {
      let rsname = resp[i]['rsname']
      data[rsname] = {}

      for (let n = 0; n < resp[i]['scopes'].length; n++) {
        let scope = resp[i]['scopes'][n].split(':')[1]
        data[rsname][scope] = true
      }
    }

    data = {
      ...data,
      userRole: keycloak.resourceAccess['rocketquiz-admin-backend'].roles[0],
      clientInfo: response.data.client === null ? null : response.data.client
    }

    return data
  }
}

export default new AuthService()
