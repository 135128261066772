import { configureStore } from '@reduxjs/toolkit'

import applicationReducer from '../features/applications/applicationsSlice'
import quizReducer from '../features/quizzes/quizzesSlice'
import permissionsReducer from '../features/permissions/permissionsSlice'

export default configureStore({
  reducer: {
    application: applicationReducer,
    quiz: quizReducer,
    permissions: permissionsReducer
  }
})
