import React, { useState, useRef, useMemo } from 'react'
import JoditEditor from 'jodit-react'
import PopoverHelp from '../../utils/buttons/popoverHelp'
import './textAreaEditable.css'

const TextAreaEditableJodit = ({ onChange, ...props }) => {
  const editor = useRef(null)
  const [content, setContent] = useState(
    props.initialValue ? props.initialValue : ''
  )

  const config = useMemo(
    () => ({
      language: 'pt_br',
      readonly: false,
      placeholder: '',
      height: 300,
      style: {
        color: '#000'
      }
    }),
    []
  )

  const handleEditorChange = content => {
    setContent(content)
    const syntheticEvent = {
      target: {
        name: props.name,
        value: content
      }
    }
    onChange(syntheticEvent)
  }

  return (
    <>
      <div className='input-box'>
        <div className='popover-help'>
          <label className='label-text'>{props.label}</label>
          {props.popoverHelp ? <PopoverHelp content={props.popoverHelp} /> : ''}
        </div>
        <div className='form-group'>
          <JoditEditor
            ref={editor}
            value={content}
            config={config}
            tabIndex={1} // tabIndex of textarea
            onBlur={newContent => handleEditorChange(newContent)} // preferred to use only this option to update the content for performance reasons
          />
        </div>
      </div>
      {props.error && (
        <>
          <div style={{ color: 'red' }} className='error'>
            {props.error}
          </div>
        </>
      )}
    </>
  )
}

export default TextAreaEditableJodit
