import React, { useState } from 'react'
import $ from 'jquery'
import './fileUploader.css'
import HttpRequests from '../../../api/httpRequests'
import { FaFileImport } from 'react-icons/fa'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

const FileUploader = props => {
  const [loading, setLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)

  const hiddenFileInput = React.useRef(null)

  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  const handleChange = async event => {
    props.setMsgType('')

    const file = event.target.files[0]

    if (
      file.type ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      setSelectedFile(file)

      submitFile(file)
    } else {
      setSelectedFile(null)
      props.setMsgType('invalid-xlsx')
    }
  }

  // BEGIN: Modal

  const columns = {
    0: 'A',
    1: 'B',
    2: 'C',
    3: 'D',
    4: 'E',
    5: 'F',
    6: 'G',
    7: 'H',
    8: 'I',
    9: 'J',
    10: 'K',
    11: 'L'
  }

  const [state, setState] = React.useState({
    importError: null,
    open: false
  })

  const handleClose = () => {
    setState({ ...state, open: false })
  }

  // END: Modal

  const submitFile = async file => {
    $('.preloader').show()
    setLoading(true)

    const formData = new FormData()
    formData.append('quiz_template', file)

    try {
      await HttpRequests.uploadQuestions(formData, props.uuid)

      document.location.reload()
    } catch (error) {
      switch (error.response.status) {
        case 401:
          props.setMsgType('401')
          break

        case 403:
          props.setMsgType('403')
          break

        case 406:
          props.setMsgType('406')
          break

        case 409:
          props.setMsgType('409')
          break

        case 412:
          props.setMsgType('412')
          break

        case 422:
          setState({
            ...state,
            importError: error.response.data.errors,
            open: true
          })
          break

        case 423:
          props.setMsgType('423')
          break

        case 424:
          props.setMsgType('424')
          break

        default:
          props.setMsgType('500')
      }

      setLoading(false)
    } finally {
      setSelectedFile(null)
      window.scrollTo(0, 0)
      $('.preloader').delay('500').fadeOut(1000)
    }
  }

  if (loading == false) {
    return (
      <>
        <button
          type='button'
          className='btn-file-upload btn-add-client'
          onClick={handleClick}
        >
          <FaFileImport />
        </button>
        <div className='file-name'>
          {selectedFile !== null ? selectedFile.name : ''}
        </div>
        <input
          style={{ display: 'none' }}
          type='file'
          ref={hiddenFileInput}
          onChange={handleChange}
        />
        {state.importError !== null ? (
          <Dialog
            open={state.open}
            maxWidth='md'
            fullWidth={true}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>Atenção!</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                Encontramos um ou mais erros na planilha:
                <div>
                  <hr />
                </div>
                {state.importError.map(error => {
                  return (
                    <div key={Math.random().toString(36).slice(2)}>
                      <p>
                        <b>Linha:</b> {error.row} | <b>Coluna:</b>{' '}
                        {columns[error.column]}
                      </p>
                      <p>{error.message}</p>
                      <hr />
                    </div>
                  )
                })}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <div className='edit-action'>
                <ul className='edit-list'>
                  <li>
                    <button
                      className='theme-btn edit-btn'
                      onClick={handleClose}
                    >
                      Ok
                    </button>
                  </li>
                </ul>
              </div>
            </DialogActions>
          </Dialog>
        ) : (
          ''
        )}
      </>
    )
  }

  return <div></div>
}

export default FileUploader
