import React from 'react'
import { Line } from 'react-chartjs-2'

const testsCompletedChart = props => {
  const data = {
    labels: props.data.labels,
    datasets: [
      {
        label: '# of Votes',
        data: props.data.data,
        fill: false,
        backgroundColor: 'rgb(81, 190, 120)',
        borderColor: 'rgba(81, 190, 120, 0.5)'
      }
    ]
  }

  const options = {
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ]
    }
  }

  return (
    <div className='chart-item'>
      <div className='chart-headline margin-bottom-40px'>
        <h3 className='widget-title font-size-18'>Conclusões dos testes</h3>
      </div>
      <Line height={300} data={data} options={options} />
    </div>
  )
}

export default testsCompletedChart
