import React, { useState } from 'react'
import Spinner from './spinner'
import './logo.css'
import HttpRequests from '../../api/httpRequests'
import RoundButtonDelete from '../../utils/buttons/roundButtonDelete'

export default function UploadLogo(props) {
  const [loading, setLoading] = useState(false)
  const [logo, setLogo] = useState(props.logo)
  const [selectedFile, setSelectedFile] = useState(null)

  const handlerLogoFile = e => {
    const file = e.target.files[0]

    if (file.type === 'image/png' || file.type === 'image/jpeg') {
      setSelectedFile(file)
      props.setMsgType('')
    } else {
      setSelectedFile(null)
      props.setMsgType('invalid-logo')
    }
  }

  const renderLogo = () => {
    if (logo !== null) {
      return (
        <>
          <div className='client-logo-area'>
            <img src={logo} />
            <RoundButtonDelete size='small' onClick={removeImage} />
          </div>
        </>
      )
    } else {
      return (
        <div className=''>
          <label className='label-text'>Logo</label>
          <div className='form-group'>
            <div className='upload-input'>
              <input
                type='file'
                className='form-control'
                onChange={handlerLogoFile}
              />
              <div className='edit-action'>
                <ul className='edit-list'>
                  <li>
                    <span className='theme-btn edit-btn' onClick={submitLogo}>
                      <i className='la la-edit mr-1 font-size-16'></i>Upload
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  const submitLogo = async () => {
    if (selectedFile) {
      setLoading(true)
      const formData = new FormData()
      formData.append('logo', selectedFile)

      try {
        const response = await HttpRequests.uploadImageClient(
          formData,
          props.client_id
        )
        setLogo(response.data.data.logo)
      } catch (error) {
        switch (error.response.status) {
          case 401:
            props.setMsgType('401')
            break

          case 403:
            props.setMsgType('403')
            break

          case 406:
            props.setMsgType('406')
            break

          case 409:
            props.setMsgType('409')
            break

          default:
            props.setMsgType('500')
        }
      } finally {
        setLoading(false)
      }
    } else {
      props.setMsgType('invalid-logo')
    }
  }

  const removeImage = async () => {
    setLoading(true)
    props.setMsgType('')

    try {
      await HttpRequests.deleteImageClient(props.client_id)
      setLogo(null)
    } catch (error) {
      switch (error.response.status) {
        case 401:
          props.setMsgType('401')
          break

        case 403:
          props.setMsgType('403')
          break

        case 406:
          props.setMsgType('406')
          break

        case 409:
          props.setMsgType('409')
          break

        case 412:
          props.setMsgType('412')
          break

        case 423:
          props.setMsgType('423')
          break

        case 424:
          props.setMsgType('424')
          break

        default:
          props.setMsgType('500')
      }
    } finally {
      setLoading(false)
    }
  }

  if (loading === true) {
    return (
      <div className='client-logo-area'>
        <Spinner />
      </div>
    )
  }

  return renderLogo()
}
