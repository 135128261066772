import React, { useState } from 'react'
import DateFnsUtils from '@date-io/date-fns' // choose your lib
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'

export default function InputDatePicker({ label, ...props }) {
  const [selectedDate, handleDateChange] = useState(null)

  const handleChange = e => {
    if (e == 'Invalid Date') {
      handleDateChange(new Date())
    } else {
      handleDateChange(e)
      props.handleStartDate(e)
    }
  }

  return (
    <>
      <div className='input-box'>
        <label className='label-text'>{label}</label>
        <div className='form-group'>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              {...props}
              autoOk={true}
              clearable
              value={selectedDate}
              placeholder=''
              onChange={e => handleChange(e)}
              format='dd/MM/yyyy'
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
    </>
  )
}
