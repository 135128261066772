import React from 'react'
import { useHistory } from 'react-router-dom'
import $ from 'jquery'
import '../features/candidates/candidates.css'
import TablePaginated from '../features/candidates/tablePaginated'
import HttpRequests from '../api/httpRequests'
import { useKeycloak } from '@react-keycloak/web'

export default function Candidates() {
  let history = useHistory()
  const { keycloak } = useKeycloak()

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nome',
        accessor: 'candidate_name'
      },
      {
        Header: 'E-mail',
        accessor: 'candidate_email'
      },
      {
        Header: '',
        accessor: 'candidate_keycloak_id'
      }
    ],
    []
  )

  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const fetchIdRef = React.useRef(0)

  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex, filters }) => {
      const fetchId = ++fetchIdRef.current
      setLoading(true)

      try {
        let data = {
          per_page: pageSize,
          page: pageIndex + 1
        }

        if (filters !== undefined) {
          if (
            filters.candidate_name !== null ||
            filters.candidate_name !== ''
          ) {
            data.candidate_name = filters.candidate_name
          }
          if (
            filters.candidate_email !== null ||
            filters.candidate_email !== ''
          ) {
            data.candidate_email = filters.candidate_email
          }
        }

        const response = await HttpRequests.listCandidates(data)

        if (fetchId === fetchIdRef.current) {
          setData(response.data.data)
          setLoading(false)
        }

        document.title = 'Candidatos - Admin Rocketquiz'
      } catch (error) {
        switch (error.response.status) {
          case 401:
            keycloak.logout()
            break

          case 403:
            history.push('/error/403')
            break

          case 404:
            history.push('/error/404')
            break

          default:
            history.push('/error/500')
        }
      } finally {
        $('.preloader').delay('250').fadeOut(500)
      }
    },
    []
  )
  return (
    <TablePaginated
      columns={columns}
      data={data}
      fetchData={fetchData}
      loading={loading}
    />
  )
}
