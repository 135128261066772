import React, { useState } from 'react'
import Spinner from './spinner'
import './image.css'
import HttpRequests from '../../../api/httpRequests'

export default function UploadImage(props) {
  const [loading, setLoading] = useState(false)
  const [logo, setLogo] = useState(props.logo)
  const [selectedFile, setSelectedFile] = useState(null)
  const [msgType, setMsgType] = useState('')

  const handlerLogoFile = e => {
    const file = e.target.files[0]
    if (file.type === 'image/png' || file.type === 'image/jpeg') {
      setSelectedFile(file)
      setMsgType('')
    } else {
      setSelectedFile(null)
      setMsgType('Selecione JPG ou PNG.')
    }
  }

  const renderLogo = () => {
    if (logo !== null) {
      return <img src={logo} />
    } else {
      return (
        <div className='render-logo'>
          <label className='label-text'>Imagem</label>
          <div className='form-group'>
            <div className='upload-input'>
              <input
                type='file'
                className='form-control'
                onChange={handlerLogoFile}
              />
              <div className='edit-action'>
                <ul className='edit-list'>
                  <li>
                    <span className='theme-btn edit-btn' onClick={submitLogo}>
                      <i className='la la-edit mr-1 font-size-16'></i>Upload
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <span className='validation-msg-image-upload'>{msgType}</span>
        </div>
      )
    }
  }

  const submitLogo = async () => {
    if (selectedFile) {
      setLoading(true)
      const formData = new FormData()
      formData.append('image', selectedFile)
      try {
        const response = await HttpRequests.uploadImageAnswer(
          formData,
          props.uuid
        )
        setLogo(response.data.data.image)
      } catch {
        setMsgType('internal-error')
      } finally {
        setLoading(false)
      }
    } else {
      setMsgType('Selecione JPG ou PNG.')
    }
  }

  if (loading === true) {
    return (
      <div className='client-logo-area'>
        <Spinner />
      </div>
    )
  }

  return renderLogo()
}
