import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import $ from 'jquery'
import Message from '../utils/messages/message'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import InputText from '../features/inputText/inputText'
import SelectInput from '../features/selectInput/selectInput'
import TextAreaEditableJodit from '../features/textAreaEditable/textAreaEditableJodit'
import '../features/newQuiz/newQuiz.css'
import { convertToTimestamp } from '../utils/utilFunctions'
import HttpRequests from '../api/httpRequests'
import InputDatePickerTime from '../features/inputDatePickerTime/inputDatePickerTime'
import { ActivatePreloader } from '../template/preloader'

function NewQuiz() {
  let history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [msgType, setMsgType] = useState('')
  const [groups, setGroups] = useState(null)
  const [addtionalFields, setAddtionalFields] = useState([])

  const listGroups = async () => {
    try {
      const response = await HttpRequests.listGroups({ per_page: 100 })
      setGroups(response.data.data)
      setIsLoading(false)
    } catch (error) {
      history.push('/error_500')
    } finally {
      $('.preloader').delay('500').fadeOut(1000)
    }
  }

  const handleNumberAdditionalFields = e => {
    const numberAdditionalFields = e.target.value
    let newAddtionalFields = []
    if (numberAdditionalFields > 0) {
      for (let i = 0; i < numberAdditionalFields; i++) {
        newAddtionalFields.push({
          id: '',
          label: '',
          instructions: '',
          required: '',
          regex_validation: '',
          register_validation: false,
          integration_field: 'false',
          status: ''
        })
      }
    }

    if (numberAdditionalFields === '0') {
      newAddtionalFields.length = 0
    }

    setAddtionalFields(newAddtionalFields)
    formik.setValues({
      ...formik.values,
      addtionalFields: newAddtionalFields
    })
    formik.handleChange({
      target: {
        name: 'numberAdditionalFields',
        value: numberAdditionalFields
      }
    })
  }

  const onSubmit = async fields => {
    let addtionalFieldsSubmit = []
    if (fields.addtionalFields.length > 0) {
      fields.addtionalFields.map((addtionalFields, i) => {
        addtionalFieldsSubmit[i] = {
          id: addtionalFields.id,
          required: addtionalFields.required,
          register_validation: addtionalFields.register_validation
        }

        if (addtionalFields.label !== '') {
          addtionalFieldsSubmit[i] = {
            ...addtionalFieldsSubmit[i],
            label: addtionalFields.label
          }
        }

        if (addtionalFields.instructions !== '') {
          addtionalFieldsSubmit[i] = {
            ...addtionalFieldsSubmit[i],
            instructions: addtionalFields.instructions
          }
        }

        if (addtionalFields.regex_validation !== '') {
          addtionalFieldsSubmit[i] = {
            ...addtionalFieldsSubmit[i],
            regex_validation: addtionalFields.regex_validation
          }
        }

        if (addtionalFields.integration_field !== '') {
          addtionalFieldsSubmit[i] = {
            ...addtionalFieldsSubmit[i],
            integration_field: addtionalFields.integration_field
          }
        }

        if (addtionalFields.status !== '') {
          addtionalFieldsSubmit[i] = {
            ...addtionalFieldsSubmit[i],
            status: addtionalFields.status
          }
        }
      })
    }

    let data = {
      title: fields.title,
      instructions: fields.instructions !== '' ? fields.instructions : null,
      final_message: fields.finalMessage !== '' ? fields.finalMessage : null,
      lang: fields.lang !== '' ? fields.lang : null,
      start_date:
        fields.startDate !== '' ? convertToTimestamp(fields.startDate) : null,
      end_date:
        fields.endDate !== '' ? convertToTimestamp(fields.endDate) : null,
      order_type: fields.orderType !== '' ? fields.orderType : '',
      show_results: fields.showResults !== '' ? fields.showResults : null,
      quiz_type: fields.quizType !== '' ? fields.quizType : null,
      group: fields.group !== '' ? fields.group : null,
      reapply_time:
        fields.reapplyTime !== '' ? fields.reapplyTime * 86400 : null
    }

    if (fields.addtionalFields.length > 0) {
      data = { ...data, additional_fields: addtionalFieldsSubmit }
    }

    if (fields.externalIds !== '') {
      data = { ...data, external_ids: fields.externalIds }
    }

    const response = await HttpRequests.createQuiz(data)
    if (response.status_code !== 201) {
      setMsgType('insert-failed')
      return
    }
    history.push('/quizzes')
  }

  const formik = useFormik({
    initialValues: {
      title: '',
      instructions: '',
      finalMessage: '',
      lang: '',
      startDate: '',
      endDate: '',
      orderType: '',
      showResults: false,
      addtionalFields: [],
      amountQuestions: 0,
      externalIds: '',
      quizType: '',
      group: '',
      numberAdditionalFields: '',
      reapplyTime: ''
    },
    validationSchema: Yup.object().shape({
      title: Yup.string()
        .min(1, 'Deve ter entre 1 e 80 caracteres')
        .max(80, 'Deve ter entre 1 e 80 caracteres')
        .required('Campo obrigatório'),
      instructions: Yup.string()
        .min(1, 'Deve ter entre 1 e 4000 caracteres')
        .max(4000, 'Deve ter entre 1 e 4000 caracteres'),
      finalMessage: Yup.string()
        .min(1, 'Deve ter entre 1 e 2000 caracteres')
        .max(2000, 'Deve ter entre 1 e 2000 caracteres'),
      lang: Yup.string()
        .min(5, 'Deve ter 5 caracteres')
        .max(5, 'Deve ter 5 caracteres')
        .required('Campo obrigatório'),
      startDate: Yup.date()
        .nullable()
        .default(() => new Date()),
      endDate: Yup.date()
        .nullable()
        .default(() => null)
        .when('startDate', (startDate, schema) => {
          if (startDate != 'Invalid Date') {
            return schema.min(
              startDate,
              'A data de término deve ser maior que a data de início'
            )
          } else {
            return schema.min(
              new Date(),
              'A data de término deve ser maior que a data de hoje'
            )
          }
        }),
      orderType: Yup.string()
        .max(10, 'Deve ter no máximo 10 caracteres')
        .required('Campo obrigatório'),
      showResults: Yup.string().required('Campo obrigatório'),
      addtionalFields: Yup.array().of(
        Yup.object().shape({
          id: Yup.string()
            .matches('^[0-9a-zA-Z_-]+$', 'Não é permitido espaço ou caracteres')
            .required('Campo obrigatório'),
          label: Yup.string()
            .min(1, 'Deve ter entre 1 e 60 caracteres')
            .max(60, 'Deve ter entre 1 e 60 caracteres'),
          instructions: Yup.string().max(
            60,
            'Deve ter no máximo 60 caracteres'
          ),
          required: Yup.string().required('Campo obrigatório'),
          regex_validation: Yup.string().max(
            255,
            'Deve ter no máximo 255 caracteres'
          ),
          register_validation: Yup.string().required('Campo obrigatório'),
          status: Yup.string().required('Campo obrigatório'),
          integration_field: Yup.string().required('Campo obrigatório')
        })
      ),
      amountQuestions: Yup.string()
        .min(0, 'Digite nº de questões')
        .required('Campo obrigatório'),
      externalIds: Yup.string()
        .min(1, 'Deve ter entre 1 e 40 caracteres')
        .max(40, 'Deve ter entre 1 e 40 caracteres'),
      quizType: Yup.string().required('Campo obrigatório'),
      group: Yup.string().required('Campo obrigatório'),
      reapplyTime: Yup.number()
        .positive()
        .integer()
        .min(1, 'Deve ter no mínimo 1 dia')
    }),
    onSubmit: onSubmit
  })

  useEffect(() => {
    window.$('.sort-ordering-select-no-search').selectpicker()
  }, [addtionalFields])

  useEffect(() => {
    listGroups()
  }, [])

  return (
    <>
      <ActivatePreloader show={isLoading} />
      {!isLoading && (
        <form className='form-question' onSubmit={formik.handleSubmit}>
          <div className='row mt-5'>
            <div className='col-lg-12'>
              <Message msgType={msgType} />
              <div className='card-box-shared'>
                <div className='card-box-shared-title'>
                  <h3 className='widget-title'>Cadastrar Prova</h3>
                </div>
                <div className='card-box-shared-body'>
                  <div className='user-form'>
                    <div className='contact-form-action'>
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className='input-dates'>
                            <InputDatePickerTime
                              name='startDate'
                              label='Data de início'
                              initialDate={null}
                              value={formik.values.startDate}
                              onChange={formik.handleChange}
                              error={formik.errors.startDate}
                            />
                            <InputDatePickerTime
                              name='endDate'
                              label='Data de término'
                              initialDate={null}
                              value={formik.values.endDate}
                              onChange={formik.handleChange}
                              error={formik.errors.endDate}
                            />
                            <InputText
                              label='Período para reaplicação'
                              name='reapplyTime'
                              value={formik.values.reapplyTime}
                              onChange={formik.handleChange}
                              error={formik.errors.reapplyTime}
                              type='number'
                              popoverHelp='Informe o período de dias onde será possível o candidato se reaplicar nesta prova.'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-12'>
                          <InputText
                            label='Título'
                            name='title'
                            type='text'
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            error={formik.errors.title}
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <SelectInput
                            label='Grupo'
                            name='group'
                            className='sort-ordering-select-no-search'
                            value={formik.values.group}
                            onChange={formik.handleChange}
                            error={formik.errors.group}
                          >
                            <option value=''>Selecione</option>
                            {groups.map(group => (
                              <option key={`${group.uuid}`} value={group.uuid}>
                                {group.name}
                              </option>
                            ))}
                          </SelectInput>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <SelectInput
                            label='Idioma'
                            name='lang'
                            className='sort-ordering-select-no-search'
                            value={formik.values.lang}
                            onChange={formik.handleChange}
                            error={formik.errors.lang}
                          >
                            <option value=''>Selecione</option>
                            <option value='pt_br'>Português</option>
                            <option value='en_us'>Inglês</option>
                            <option value='es_es'>Espanhol</option>
                          </SelectInput>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <SelectInput
                            label='Tipo'
                            name='quizType'
                            className='sort-ordering-select-no-search'
                            value={formik.values.quizType}
                            onChange={formik.handleChange}
                            error={formik.errors.quizType}
                          >
                            <option value=''>Selecione</option>
                            <option value='technical'>Técnica</option>
                            <option value='behavioral'>Comportamental</option>
                          </SelectInput>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <SelectInput
                            label='Ordenação'
                            name='orderType'
                            className='sort-ordering-select-no-search'
                            value={formik.values.orderType}
                            onChange={formik.handleChange}
                            error={formik.errors.orderType}
                          >
                            <option value=''>Selecione</option>
                            <option value='ordered'>Ordenado</option>
                            <option value='random'>Randômico</option>
                          </SelectInput>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <SelectInput
                            label='Mostrar resultados'
                            name='showResults'
                            className='sort-ordering-select-no-search'
                            value={formik.values.showResults}
                            onChange={formik.handleChange}
                            error={formik.errors.showResults}
                          >
                            <option value='true'>Sim</option>
                            <option value='false'>Não</option>
                          </SelectInput>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <InputText
                            label='ID Externo'
                            name='externalIds'
                            type='text'
                            value={formik.values.externalIds}
                            onChange={formik.handleChange}
                            error={formik.errors.externalIds}
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-12'>
                          <TextAreaEditableJodit
                            name='instructions'
                            label='Instruções'
                            onChange={formik.handleChange}
                            error={formik.errors.instructions}
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-12'>
                          <TextAreaEditableJodit
                            name='finalMessage'
                            label='Mensagem final'
                            onChange={formik.handleChange}
                            error={formik.errors.finalMessage}
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-4'>
                          <SelectInput
                            label='Campos Adicionais'
                            name='numberAdditionalFields'
                            className='sort-ordering-select-no-search'
                            value={formik.values.numberAdditionalFields}
                            onChange={e => handleNumberAdditionalFields(e)}
                            error={formik.errors.numberAdditionalFields}
                          >
                            <option value='0'>Nenhum</option>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => (
                              <option key={i} value={i}>
                                {i}
                              </option>
                            ))}
                          </SelectInput>
                        </div>
                        <div className='col-md-4'></div>
                      </div>
                      {addtionalFields.length > 0 &&
                        addtionalFields.map((ticket, i) => (
                          <div key={i} className='list-group list-group-flush'>
                            <h5 className=''>Campo {i + 1}</h5>
                            <div className='row'>
                              <div className='col-md-3'>
                                <SelectInput
                                  label='Campo de Integração'
                                  name={`addtionalFields.${i}.integration_field`}
                                  className='sort-ordering-select-no-search'
                                  onChange={formik.handleChange}
                                  value={
                                    formik.values.addtionalFields.length > 0 &&
                                    formik.values.addtionalFields[i]
                                      .integration_field
                                  }
                                  error={
                                    formik.errors.addtionalFields &&
                                    formik.errors.addtionalFields.length > i &&
                                    formik.errors.addtionalFields[i] &&
                                    formik.errors.addtionalFields[i]
                                      .integration_field
                                  }
                                >
                                  <option value='true'>Sim</option>
                                  <option value='false'>Não</option>
                                </SelectInput>
                              </div>
                              <div className='col-md-3'>
                                <InputText
                                  label='Identificador'
                                  name={`addtionalFields.${i}.id`}
                                  onChange={formik.handleChange}
                                  value={
                                    formik.values.addtionalFields.length > 0 &&
                                    formik.values.addtionalFields[i].id
                                  }
                                  error={
                                    formik.errors.addtionalFields &&
                                    formik.errors.addtionalFields.length > i &&
                                    formik.errors.addtionalFields[i] &&
                                    formik.errors.addtionalFields[i].id
                                  }
                                  type='text'
                                />
                              </div>
                              <div className='col-md-3'>
                                <InputText
                                  label='Nome do Campo'
                                  name={`addtionalFields.${i}.label`}
                                  onChange={formik.handleChange}
                                  value={
                                    formik.values.addtionalFields.length > 0 &&
                                    formik.values.addtionalFields[i].label
                                  }
                                  error={
                                    formik.errors.addtionalFields &&
                                    formik.errors.addtionalFields.length > i &&
                                    formik.errors.addtionalFields[i] &&
                                    formik.errors.addtionalFields[i].label
                                  }
                                  type='text'
                                />
                              </div>
                              <div className='col-md-3'>
                                <InputText
                                  label='Instruções'
                                  name={`addtionalFields.${i}.instructions`}
                                  onChange={formik.handleChange}
                                  value={
                                    formik.values.addtionalFields.length > 0 &&
                                    formik.values.addtionalFields[i]
                                      .instructions
                                  }
                                  error={
                                    formik.errors.addtionalFields &&
                                    formik.errors.addtionalFields.length > i &&
                                    formik.errors.addtionalFields[i] &&
                                    formik.errors.addtionalFields[i]
                                      .instructions
                                  }
                                  popoverHelp='Esta mensagem será exibida para auxiliar o candidato no preenchimento do campo.'
                                  type='text'
                                />
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-3'>
                                <SelectInput
                                  className='sort-ordering-select-no-search'
                                  label='Regex Validation'
                                  name={`addtionalFields.${i}.regex_validation`}
                                  onChange={formik.handleChange}
                                  value={
                                    formik.values.addtionalFields.length > 0 &&
                                    formik.values.addtionalFields[i]
                                      .regex_validation
                                  }
                                  error={
                                    formik.errors.addtionalFields &&
                                    formik.errors.addtionalFields.length > i &&
                                    formik.errors.addtionalFields[i] &&
                                    formik.errors.addtionalFields[i]
                                      .regex_validation
                                  }
                                >
                                  <option value=''>Selecione</option>
                                  <option value='^[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}$'>
                                    CPF
                                  </option>
                                  <option value='^[0-9]*$'>
                                    Somente números
                                  </option>
                                  <option value='^[0-9]{2}$'>
                                    Somente números (2 Digitos)
                                  </option>
                                  <option value='^[0-9]{3}$'>
                                    Somente números (3 Digitos)
                                  </option>
                                  <option value='^[0-9]{4}$'>
                                    Somente números (4 Digitos)
                                  </option>
                                  <option value='^[0-9]{5}$'>
                                    Somente números (5 Digitos)
                                  </option>
                                  <option value='^[0-9]{6}$'>
                                    Somente números (6 Digitos)
                                  </option>
                                  <option value='^[0-9]{7}$'>
                                    Somente números (7 Digitos)
                                  </option>
                                  <option value='^[0-9]{8}$'>
                                    Somente números (8 Digitos)
                                  </option>
                                  <option value='^[0-9]{9}$'>
                                    Somente números (9 Digitos)
                                  </option>
                                  <option value='^[0-9]{10}$'>
                                    Somente números (10 Digitos)
                                  </option>
                                  <option value='^[a-zA-Z]+$'>
                                    Somente letras
                                  </option>
                                  <option value='^[a-zA-Z]{2}$'>
                                    Somente letras (2 Digitos)
                                  </option>
                                  <option value='^[a-zA-Z]{3}$'>
                                    Somente letras (3 Digitos)
                                  </option>
                                  <option value='^[a-zA-Z]{4}$'>
                                    Somente letras (4 Digitos)
                                  </option>
                                  <option value='^[a-zA-Z]{5}$'>
                                    Somente letras (5 Digitos)
                                  </option>
                                  <option value='^[a-zA-Z]{6}$'>
                                    Somente letras (6 Digitos)
                                  </option>
                                  <option value='^[a-zA-Z]{7}$'>
                                    Somente letras (7 Digitos)
                                  </option>
                                  <option value='^[a-zA-Z]{8}$'>
                                    Somente letras (8 Digitos)
                                  </option>
                                  <option value='^[a-zA-Z]{9}$'>
                                    Somente letras (9 Digitos)
                                  </option>
                                  <option value='^[a-zA-Z]{10}$'>
                                    Somente letras (10 Digitos)
                                  </option>
                                  <option value='^.{2}$'>
                                    Qualquer caractere (2 Digitos)
                                  </option>
                                  <option value='^.{3}$'>
                                    Qualquer caractere (3 Digitos)
                                  </option>
                                  <option value='^.{4}$'>
                                    Qualquer caractere (4 Digitos)
                                  </option>
                                  <option value='^.{5}$'>
                                    Qualquer caractere (5 Digitos)
                                  </option>
                                  <option value='^.{6}$'>
                                    Qualquer caractere (6 Digitos)
                                  </option>
                                  <option value='^.{7}$'>
                                    Qualquer caractere (7 Digitos)
                                  </option>
                                  <option value='^.{8}$'>
                                    Qualquer caractere (8 Digitos)
                                  </option>
                                  <option value='^.{9}$'>
                                    Qualquer caractere (9 Digitos)
                                  </option>
                                  <option value='^.{10}$'>
                                    Qualquer caractere (10 Digitos)
                                  </option>
                                </SelectInput>
                              </div>
                              <div className='col-md-3'>
                                <SelectInput
                                  className='sort-ordering-select-no-search'
                                  label='Status'
                                  name={`addtionalFields.${i}.status`}
                                  onChange={formik.handleChange}
                                  value={
                                    formik.values.addtionalFields.length > 0 &&
                                    formik.values.addtionalFields[i].status
                                  }
                                  error={
                                    formik.errors.addtionalFields &&
                                    formik.errors.addtionalFields.length > i &&
                                    formik.errors.addtionalFields[i] &&
                                    formik.errors.addtionalFields[i].status
                                  }
                                >
                                  <option value=''>Selecione</option>
                                  <option value='active'>Ativo</option>
                                  <option value='inactive'>Inativo</option>
                                </SelectInput>
                              </div>
                              <div className='col-md-3'>
                                <SelectInput
                                  className='sort-ordering-select-no-search'
                                  label='Obrigatório'
                                  name={`addtionalFields.${i}.required`}
                                  onChange={formik.handleChange}
                                  value={
                                    formik.values.addtionalFields.length > 0 &&
                                    formik.values.addtionalFields[i].required
                                  }
                                  error={
                                    formik.errors.addtionalFields &&
                                    formik.errors.addtionalFields.length > i &&
                                    formik.errors.addtionalFields[i] &&
                                    formik.errors.addtionalFields[i].required
                                  }
                                >
                                  <option value=''>Selecione</option>
                                  <option value='true'>Sim</option>
                                  <option value='false'>Não</option>
                                </SelectInput>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12'>
              <button className='theme-btn' type='submit'>
                Cadastrar
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  )
}

export default NewQuiz
