import React from 'react'
import { useSelector } from 'react-redux'

export const PermissionAgent = ({
  Component,
  componentProps,
  resource,
  scope
}) => {
  const permissions = useSelector(state => state.permissions.value)

  const isAuthorized = () =>
    Object.prototype.hasOwnProperty.call(permissions, resource) &&
    Object.prototype.hasOwnProperty.call(permissions[resource], scope)

  return isAuthorized() && <Component {...componentProps} />
}
