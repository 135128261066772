import React from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { addApplicationInfo } from './applicationsSlice'
import { useTable, usePagination } from 'react-table'
import './tablePaginated.css'
import $ from 'jquery'
import AlertMessage from './alertMessage'
import { RiQuestionAnswerLine } from 'react-icons/ri'
import { convertTimestampFormated } from '../../utils/utilFunctions'
import { AiOutlineFileAdd } from 'react-icons/ai'
import HttpRequests from '../../api/httpRequests'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import { useHistory } from 'react-router-dom'

function TablePaginated({ columns, data, fetchData }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: -1
    },
    usePagination
  )

  const quizInfo = useSelector(state => state.quiz)
  let history = useHistory()
  const [msgType, setMsgType] = React.useState('')
  const dispatch = useDispatch()

  const handleChangePage = rowCells => {
    let applicationInfo = {
      candidateName: rowCells[0].value,
      candidateEmail: rowCells[1].value,
      uuid: rowCells[7].value
    }

    dispatch(addApplicationInfo(applicationInfo))
    history.push('/evaluation/' + rowCells[7].value)
  }

  // BEGIN: Modal
  const [open, setOpen] = React.useState(false)
  const [rowApplicationData, setRowApplicationData] = React.useState(null)
  const [resultMsg, setResultMsg] = React.useState(null)

  const handleClickOpen = rowData => {
    setResultMsg(null)
    setOpen(true)
    setRowApplicationData(rowData)
  }

  const handleClose = () => {
    setMsgType('')
    setOpen(false)
  }
  // END: Modal

  const allowReapply = async () => {
    $('.preloader').show()

    try {
      await HttpRequests.allowReapply(
        {
          application: rowApplicationData[7].value
        },
        quizInfo.uuid
      )

      setMsgType('204')
      setResultMsg(true)
      setRowApplicationData(null)

      fetchData({ pageIndex, pageSize })
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            setMsgType('401')
            break

          case 403:
            setMsgType('403')
            break

          case 404:
            setMsgType('404')
            break

          case 409:
            setMsgType('409')
            break

          case 424:
            setMsgType('424')
            break

          default:
            setMsgType('500')
        }
      } else {
        history.push('/error/500')
      }
    } finally {
      $('.preloader').delay('500').fadeOut(1000)
    }
  }

  const [candidateNameFilter, setCandidateNameFilter] = React.useState(null)
  const [candidateEmailFilter, setCandidateEmailFilter] = React.useState(null)

  const handleCandidateNameFilter = e => {
    setCandidateNameFilter(e.target.value)
  }

  const handleCandidateEmailFilter = e => {
    setCandidateEmailFilter(e.target.value)
  }

  const handleSearch = () => {
    $('.preloader').show()
    let filters = {
      candidate_name: candidateNameFilter,
      candidate_email: candidateEmailFilter
    }
    fetchData({ pageIndex, pageSize, filters })
  }

  const handleKeyPressInputSearch = e => {
    if (
      e.key === 'Enter' &&
      (candidateNameFilter !== null || candidateEmailFilter !== null)
    ) {
      handleSearch()
    }
  }

  React.useEffect(() => {
    if (!quizInfo.uuid) {
      history.push('/quizzes')
    }

    $('.preloader').show()
    window.$('.sort-ordering-select-no-search').selectpicker()
    fetchData({ pageIndex, pageSize })
  }, [fetchData, pageIndex, pageSize])

  return (
    <div>
      <div className='card-box-shared'>
        <div className='card-box-shared-title'>
          <h3 className='widget-title'>{quizInfo.quizName} - Aplicações</h3>
          <div className='search-table-area'>
            <div className='search-table-input form-group'>
              <input
                onKeyPress={e => handleKeyPressInputSearch(e)}
                onChange={e => handleCandidateNameFilter(e)}
                className='form-control'
                type='text'
                name='search'
                placeholder='Pesquisar por nome'
              />
              <input
                onKeyPress={e => handleKeyPressInputSearch(e)}
                onChange={e => handleCandidateEmailFilter(e)}
                className='form-control'
                type='text'
                name='search'
                placeholder='Pesquisar e-mail'
              />
              <span
                onClick={handleSearch}
                className='la la-search search-icon'
              ></span>
            </div>
          </div>
        </div>
        <div className='card-box-shared-body'>
          {page.length > 0 ? (
            <div>
              <div className='statement-table purchase-table table-responsive mb-5'>
                <table className='table' {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup, headerGroupIndex) => (
                      <tr
                        key={`headerGroup-${headerGroupIndex}`}
                        {...headerGroup.getHeaderGroupProps()}
                      >
                        {headerGroup.headers.map((column, columnIndex) => (
                          <th
                            key={`column-${columnIndex}`}
                            className=''
                            scope='col'
                            {...column.getHeaderProps()}
                          >
                            {column.render('Header')}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row, rowIndex) => {
                      prepareRow(row)
                      return (
                        <tr key={`row-${rowIndex}`} {...row.getRowProps()}>
                          {row.cells.map((cell, cellIndex) => {
                            if (cell.column.id === 'uuid') {
                              return (
                                <td
                                  key={`cell-${rowIndex}-${cellIndex}`}
                                  style={{ width: '121px' }}
                                  {...cell.getCellProps()}
                                >
                                  <div className='statement-info'>
                                    <div className='edit-action'>
                                      <ul className='edit-list action-btn'>
                                        <li>
                                          <button
                                            type='button'
                                            onClick={() =>
                                              handleChangePage(cell.row.cells)
                                            }
                                            className='theme-btn questions-btn'
                                            title='Questões'
                                          >
                                            <RiQuestionAnswerLine
                                              className='react-icons'
                                              size='1em'
                                            />
                                          </button>
                                        </li>
                                        <li>
                                          <button
                                            type='button'
                                            onClick={() =>
                                              handleClickOpen(cell.row.cells)
                                            }
                                            className='theme-btn questions-btn'
                                            title='Reaplicar'
                                          >
                                            <AiOutlineFileAdd
                                              className='react-icons'
                                              size='1em'
                                            />
                                          </button>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </td>
                              )
                            }
                            if (
                              cell.column.id === 'start_date' ||
                              cell.column.id === 'end_date'
                            ) {
                              return (
                                <td
                                  key={`cell-${rowIndex}-${cellIndex}`}
                                  {...cell.getCellProps()}
                                >
                                  <div className='statement-info'>
                                    {cell.value === null
                                      ? 'Aguardando finalização'
                                      : convertTimestampFormated(
                                          cell.value,
                                          true
                                        )}
                                  </div>
                                </td>
                              )
                            }
                            if (cell.column.id === 'total_time') {
                              return (
                                <td
                                  key={`cell-${rowIndex}-${cellIndex}`}
                                  {...cell.getCellProps()}
                                >
                                  <div className='statement-info'>
                                    {cell.value === null
                                      ? 'Aguardando finalização'
                                      : cell.value}
                                  </div>
                                </td>
                              )
                            }
                            if (cell.column.id === 'results') {
                              return (
                                <td
                                  key={`cell-${rowIndex}-${cellIndex}`}
                                  {...cell.getCellProps()}
                                >
                                  <div className='statement-info'>
                                    {cell.value.length === 0
                                      ? 'Aguardando finalização'
                                      : cell.value}
                                  </div>
                                </td>
                              )
                            }
                            if (cell.column.id === 'score') {
                              return (
                                <td
                                  key={`cell-${rowIndex}-${cellIndex}`}
                                  {...cell.getCellProps()}
                                >
                                  <div className='statement-info'>
                                    {cell.value * 100}
                                  </div>
                                </td>
                              )
                            }
                            if (cell.column.id === 'status') {
                              switch (cell.value) {
                                case 'done':
                                  return (
                                    <td
                                      key={`cell-${rowIndex}-${cellIndex}`}
                                      style={{ width: '50px' }}
                                      {...cell.getCellProps()}
                                    >
                                      <div className='statement-info'>
                                        <span className='badge bg-success text-white p-1'>
                                          Finalizado
                                        </span>
                                      </div>
                                    </td>
                                  )
                                case 'awaiting_evaluation':
                                  return (
                                    <td
                                      key={`cell-${rowIndex}-${cellIndex}`}
                                      style={{ width: '50px' }}
                                      {...cell.getCellProps()}
                                    >
                                      <div className='statement-info'>
                                        <span className='badge bg-primary text-white p-1'>
                                          Em avaliação
                                        </span>
                                      </div>
                                    </td>
                                  )
                                case 'applied':
                                  return (
                                    <td
                                      key={`cell-${rowIndex}-${cellIndex}`}
                                      style={{ width: '50px' }}
                                      {...cell.getCellProps()}
                                    >
                                      <div className='statement-info'>
                                        <span className='badge bg-warning text-white p-1'>
                                          Aplicado
                                        </span>
                                      </div>
                                    </td>
                                  )
                              }
                            }
                            return (
                              <td
                                key={`cell-${rowIndex}-${cellIndex}`}
                                {...cell.getCellProps()}
                              >
                                <div className='statement-info'>
                                  {cell.render('Cell')}
                                </div>
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              <div className='page-navigation-wrap mt-4 mb-4'>
                <div className='page-navigation-inner d-inline-block'>
                  <div className='page-navigation'>
                    <button
                      type='button'
                      className='btn page-go page-prev'
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      <i className='la la-arrow-left icon-table'></i>
                    </button>
                    <ul className='page-navigation-nav'>
                      <li>
                        <span>
                          Página <strong>{pageIndex + 1}</strong>{' '}
                        </span>
                      </li>
                    </ul>
                    <button
                      type='button'
                      className='btn page-go page-next'
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                    >
                      <i className='la la-arrow-right icon-table'></i>
                    </button>
                    <div className='page-size'>
                      <label>Itens por página: </label>
                      <div className='select-input sort-ordering user-form-short'>
                        <select
                          className='sort-ordering-select-no-search'
                          value={pageSize}
                          onChange={e => {
                            setPageSize(Number(e.target.value))
                          }}
                        >
                          {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                              {pageSize}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p>Essa prova ainda não possui aplicações.</p>
          )}
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth={true}
        maxWidth='sm'
      >
        <DialogTitle id='alert-dialog-title'>
          {resultMsg !== null ? 'Operação realizada com sucesso!' : 'Atenção!'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <AlertMessage msgType={msgType} />
            {resultMsg !== null ? (
              <p>
                É possível visualizar todas as aplicações deste candidato
                através do Módulo de Candidatos.
              </p>
            ) : (
              ''
            )}
            {rowApplicationData !== null ? (
              <p>
                Tem certeza que deseja permitir a reaplicação para o candidato{' '}
                <b>{rowApplicationData[1].value}</b> para a prova{' '}
                <b>{quizInfo.quizName}</b>?
              </p>
            ) : (
              ''
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className='edit-action'>
            <ul className='edit-list'>
              {resultMsg === null ? (
                <li>
                  <button className='theme-btn edit-btn' onClick={handleClose}>
                    cancelar
                  </button>
                </li>
              ) : (
                ''
              )}
              {resultMsg === null ? (
                <li>
                  <button className='theme-btn' onClick={allowReapply}>
                    Reaplicar
                  </button>
                </li>
              ) : (
                <li>
                  <button className='theme-btn' onClick={handleClose}>
                    Fechar
                  </button>
                </li>
              )}
            </ul>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default TablePaginated
