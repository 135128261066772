import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import SelectInput from '../selectInput/selectInput'
import TextAreaEditableJodit from '../textAreaEditable/textAreaEditableJodit'
import UploadImageQuestion from '../questions/uploadImage/uploadImage'
import InputText from '../inputText/inputText'
import Answers from '../questions/answers'
import RoundButtonDelete from '../../utils/buttons/roundButtonDelete'
import ButtonGreen from '../../utils/buttons/buttonGreen'
import validationSchema from './validationSchema'
import './questionForm.css'

const questionForm = ({
  knowledgeAreas,
  question,
  updateQuestion,
  handleFormikChange,
  handleDelete,
  setMsgType
}) => {
  const formik = useFormik({
    initialValues: { ...question },
    validationSchema: validationSchema,
    onSubmit: values => {
      updateQuestion(values)
    }
  })

  useEffect(() => {
    handleFormikChange(formik.values)
  }, [formik.values, handleFormikChange])

  return (
    <>
      <form className='form-question' onSubmit={formik.handleSubmit}>
        <div className='row'>
          <div className='col-lg-6 col-sm-6'>
            <SelectInput
              label='Área de conhecimento'
              name={`knowledge_area`}
              className='sort-ordering-select-no-search'
              value={formik.values.knowledge_area}
              onChange={formik.handleChange}
              error={formik.errors.knowledge_area}
            >
              <option value=''>Selecione</option>
              {knowledgeAreas.map(function (knowledgeArea) {
                return (
                  <option key={knowledgeArea.uuid} value={knowledgeArea.uuid}>
                    {knowledgeArea.name}
                  </option>
                )
              })}
            </SelectInput>
          </div>
          <div className='col-lg-6 col-sm-6'>
            <SelectInput
              label='Permitir pular'
              name={`allow_skip`}
              className='sort-ordering-select-no-search'
              value={formik.values.allow_skip}
              onChange={formik.handleChange}
              error={formik.errors.allow_skip}
            >
              <option value='true'>Sim</option>
              <option value='false'>Não</option>
            </SelectInput>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-6 col-sm-6'>
            <TextAreaEditableJodit
              name={`description`}
              label='Descrição'
              weidth={200}
              onChange={formik.handleChange}
              error={formik.errors.description}
              initialValue={
                question.description !== '' ? question.description : ''
              }
            />
          </div>
          <div className='col-lg-6 col-sm-6'>
            <UploadImageQuestion
              uuid={question.uuid}
              setMsg=''
              logo={
                question.image && question.image !== '' ? question.image : null
              }
              setMsgType={setMsgType}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12 col-sm-12'>
            <InputText
              name={`instructions`}
              label='Instruções'
              onChange={formik.handleChange}
              value={formik.values.instructions}
              error={formik.errors.instructions}
              popoverHelp='As instruções serão exibidas antes do candidato iniciar a questão.'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-4 col-sm-4'>
            <InputText
              label='Ordem'
              name={`order`}
              onChange={formik.handleChange}
              error={formik.errors.order}
              value={formik.values.order}
              type='number'
            />
          </div>
          <div className='col-lg-4 col-sm-4'>
            <InputText
              label='Tempo de Resposta'
              name={`time`}
              onChange={formik.handleChange}
              error={formik.errors.time}
              value={formik.values.time}
              type='number'
            />
          </div>
          <div className='col-lg-4 col-sm-4'>
            <InputText
              label='Peso da Questão'
              popoverHelp='Pesos maiores influenciarão mais na nota final da prova'
              name={`weight`}
              onChange={formik.handleChange}
              error={formik.errors.weight}
              value={formik.values.weight}
              type='number'
            />
          </div>
        </div>
        <Answers questionField={question} />
        <div className='row'>
          {question.type === 'open_ended' && (
            <div className='col-lg-2 col-sm-2'>
              <InputText
                label='Limite de caracteres'
                name={`answer_character_limit`}
                onChange={formik.handleChange}
                value={formik.values.answer_character_limit}
                type='number'
              />
            </div>
          )}
        </div>
        <div className='row '>
          <div className='question-action-buttons'>
            <ButtonGreen type='submit' description='Salvar' />
            <div className='btn-delete-answer'>
              <RoundButtonDelete onClick={() => handleDelete(question.uuid)} />
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default questionForm
