import React from 'react'
import { useDispatch } from 'react-redux'
import { addInfo } from './quizzesSlice'
import { convertToTimestamp } from '../../utils/utilFunctions'
import { Link, NavLink, useHistory } from 'react-router-dom'
import { useTable, usePagination } from 'react-table'
import './tablePaginated.css'
import $ from 'jquery'
import AlertMessage from './alertMessage'
import { RiQuestionAnswerLine } from 'react-icons/ri'
import { BiEdit } from 'react-icons/bi'
import { GiPapers } from 'react-icons/gi'
import { SiMicrosoftexcel } from 'react-icons/si'
import { AiOutlineCopy } from 'react-icons/ai'
import PopoverHelp from '../../utils/buttons/popoverHelp'
import HttpRequests from '../../api/httpRequests'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import InputDatePicker from '../../utils/simpleForm/inputDatePicker'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import Input from '@material-ui/core/Input'
import TextField from '@material-ui/core/TextField'
import { PermissionAgent } from '../permissions/permissionAgent'

function TablePaginated({ columns, data, fetchData }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: -1
    },
    usePagination
  )

  const [msgType, setMsgType] = React.useState('')

  // BEGIN: Modal
  const [open, setOpen] = React.useState(false)
  const [rowQuizData, setRowQuizData] = React.useState('')
  const [startDate, setStartDate] = React.useState(null)
  const [endDate, setEndDate] = React.useState(null)
  const [dateValidationMsg, setDateValidationMsg] = React.useState('')
  const [exportResultMsg, setExportResultMsg] = React.useState('')

  const handleClickOpen = rowData => {
    setStartDate(null)
    setEndDate(null)
    setDateValidationMsg('')
    setExportResultMsg('')
    setOpen(true)
    setRowQuizData(rowData)
  }

  const handleClose = () => {
    setOpen(false)
    setRowQuizData('')
  }

  const handleStartDate = e => {
    setDateValidationMsg('')
    setExportResultMsg('')
    setStartDate(e)
  }

  const handleEndDate = e => {
    setDateValidationMsg('')
    setExportResultMsg('')
    setEndDate(e)
  }

  const showExportResultMsg = () => {
    let cssClass = ''
    let msg = ''

    if (exportResultMsg === true) {
      cssClass = 'alert alert-success'
      msg = 'Aplicações exportadas com sucesso!'
    } else {
      cssClass = 'alert alert-danger'
      msg = 'Não existem aplicações disponíveis.'
    }

    return (
      <div className={cssClass + ' alert-dismissible fade show'} role='alert'>
        {msg}
      </div>
    )
  }
  // END: Modal

  const exportXLS = async () => {
    let quizId = rowQuizData.cells[3].value
    let quizName = rowQuizData.cells[0].value.replace(/\s/g, '-')

    let startDateTimestamp =
      convertToTimestamp(startDate) === 0 ? null : convertToTimestamp(startDate)
    let endDateTimestamp =
      convertToTimestamp(endDate) === 0 ? null : convertToTimestamp(endDate)

    if (
      startDateTimestamp > 0 &&
      endDateTimestamp !== null &&
      endDateTimestamp < startDateTimestamp
    ) {
      setDateValidationMsg('A data final tem que ser maior que a data inicial.')
      return
    }

    let quizParams = {
      quiz: quizId
    }

    if (startDateTimestamp > 0) {
      quizParams = { ...quizParams, start_date: startDateTimestamp }
    }

    if (endDateTimestamp > 0) {
      quizParams = { ...quizParams, end_date: endDateTimestamp }
    }

    try {
      $('.preloader').show()

      const response = await HttpRequests.exportApplications(quizParams)

      let url = ''
      let link = ''
      switch (response.status) {
        case 200:
          url = window.URL.createObjectURL(new Blob([response.data]))
          link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${quizName}.xlsx`)
          document.body.appendChild(link)
          link.click()

          setExportResultMsg(true)

          break

        case 204:
          setExportResultMsg(false)
          break
      }
    } catch (error) {
      alert('Link não disponível')
    } finally {
      $('.preloader').delay('500').fadeOut(1000)
    }
  }

  let history = useHistory()

  const dispatch = useDispatch()

  const handleChangePage = rowCells => {
    let quizInfo = {
      quizName: rowCells[0].value,
      uuid: rowCells[3].value
    }

    dispatch(addInfo(quizInfo))
    history.push('/applications/' + rowCells[3].value)
  }

  const [filterByTitle, setFilterByTitle] = React.useState(null)

  const handleFilterByTitle = e => {
    setFilterByTitle(e.target.value)
  }

  const handleFilterByStatus = e => {
    setFilterByStatus(e.target.value)
  }

  const handleSearch = () => {
    $('.preloader').show()
    fetchData({
      pageIndex,
      pageSize,
      filterByTitle,
      filterByGroup,
      filterByStatus
    })
  }

  const statuses = ['published', 'unpublished', 'in_testing', 'archived']

  const statusesTranslation = {
    published: 'Publicado',
    unpublished: 'Não publicado',
    in_testing: 'Em teste',
    archived: 'Arquivado'
  }

  const handleKeyPressInputSearch = e => {
    if (e.key === 'Enter' && filterByTitle !== null) {
      handleSearch()
    }
  }
  const [filterByStatus, setFilterByStatus] = React.useState([])

  const [filterByGroup, setFilterByGroup] = React.useState('')

  const handlefilterByGroup = e => {
    setFilterByGroup(e.target.value)
  }

  React.useEffect(() => {
    handleSearch()
  }, [filterByGroup, filterByStatus])

  const [groups, setGroups] = React.useState([])

  const listGroups = async () => {
    try {
      const response = await HttpRequests.listGroups({ per_page: 100 })
      setGroups(response.data.data)
      window.$('.sort-ordering-select-no-search').selectpicker('refresh')
    } catch (error) {
      history.push('/error_500')
    } finally {
      $('.preloader').delay('500').fadeOut(1000)
    }
  }

  const [status, setStatus] = React.useState({ quizStatus: null })

  const listQuizzes = async () => {
    try {
      const response = await HttpRequests.listQuizzes({ per_page: 100 })
      setStatus({
        ...status,
        quizStatus: response.data.data.status
      })
    } catch (error) {
      history.push('/error_500')
    } finally {
      $('.preloader').delay('500').fadeOut(1000)
    }
  }

  React.useEffect(() => {
    window.$('.sort-ordering-select-no-search').selectpicker()
    $('.preloader').show()
    listGroups()
    listQuizzes()
  }, [])

  const copyQuiz = async uuid => {
    $('.preloader').show()

    try {
      await HttpRequests.copyQuiz(uuid)
      fetchData({ pageIndex, pageSize })
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            setMsgType('401')
            break

          case 403:
            setMsgType('403')
            break

          case 404:
            setMsgType('404')
            break

          default:
            setMsgType('500')
        }
      } else {
        history.push('/error/500')
      }
    } finally {
      $('.preloader').delay('500').fadeOut(1000)
    }
  }

  const style = {
    color: '#424242'
  }

  React.useEffect(() => {
    $('.preloader').show()
    window.$('.sort-ordering-select-no-search').selectpicker()
    fetchData({ pageIndex, pageSize })
  }, [fetchData, pageIndex, pageSize])

  return (
    <div>
      <div className='card-box-shared'>
        <div className='card-box-shared-title'>
          <h3 className='widget-title'>Provas</h3>
          <div className='search-table-area-with-add-button'>
            <NavLink className='theme-btn' to='/quizzes/create'>
              Adicionar
            </NavLink>
            <div className='input-filters-group'>
              <FormControl className='sort-ordering-select-no-search'>
                <InputLabel id='search-status'>Pesquisar por Status</InputLabel>
                <Select
                  multiple
                  id='form-search-status'
                  value={filterByStatus}
                  input={<Input />}
                  onChange={e => handleFilterByStatus(e)}
                  renderValue={selected =>
                    selected
                      .map(status => statusesTranslation[status])
                      .join(', ')
                  }
                >
                  {statuses.map(status => (
                    <MenuItem key={statusesTranslation[status]} value={status}>
                      <Checkbox
                        checked={filterByStatus.indexOf(status) > -1}
                        defaultChecked
                        style={style}
                      />
                      <ListItemText primary={statusesTranslation[status]} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl className='sort-ordering-select-no-search'>
                <InputLabel id='search-status'>Pesquisar por Grupo</InputLabel>
                <Select
                  id='form-search-status'
                  value={filterByGroup}
                  input={<Input />}
                  onChange={e => handlefilterByGroup(e)}
                >
                  <option value='' className='options-groups'>
                    Todos
                  </option>
                  {groups.map(function (group) {
                    return (
                      <option
                        key={group.uuid}
                        value={group.uuid}
                        className='options-groups'
                      >
                        {group.name}
                      </option>
                    )
                  })}
                </Select>
              </FormControl>
              <div className='search-table-input form-group' id='search'>
                <FormControl noValidate autoComplete='off' id='search-status'>
                  <TextField
                    id='form-search-status'
                    onKeyPress={e => handleKeyPressInputSearch(e)}
                    onChange={e => handleFilterByTitle(e)}
                    style={{ width: '250px' }}
                    type='text'
                    name='search'
                    placeholder='Pesquisa por Título'
                  />
                </FormControl>
                <span
                  onClick={handleSearch}
                  className='la la-search search-icon'
                ></span>
              </div>
            </div>
          </div>
        </div>
        <div className='card-box-shared-body'>
          <AlertMessage msgType={msgType} />
          <div className='statement-table purchase-table table-responsive mb-5'>
            <table className='table' {...getTableProps()}>
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    key={headerGroup.id}
                  >
                    {headerGroup.headers.map(column => {
                      if (column.id === 'status') {
                        return (
                          <th className='' scope='col' key={column.id}>
                            <div className='popover-help'>
                              {column.render('Header')}
                              <PopoverHelp content='No Status "Em Teste", apenas os administradores e recrutadores conseguirão fazer a prova. As respostas também não serão registradas no relatório. Somente o Administrador pode adicionar usuários' />
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? ' 🔽'
                                    : ' 🔼'
                                  : ''}
                              </span>
                            </div>
                          </th>
                        )
                      }

                      return (
                        <th className='' scope='col' key={column.id}>
                          <div className='popover-help'>
                            {column.render('Header')}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? ' 🔽'
                                  : ' 🔼'
                                : ''}
                            </span>
                          </div>
                        </th>
                      )
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map(row => {
                  prepareRow(row)
                  return (
                    <tr key={row.id} {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        if (cell.column.id == 'uuid') {
                          return (
                            <td
                              key={cell.column.id}
                              style={{ width: '244px' }}
                              {...cell.getCellProps()}
                            >
                              <div className='statement-info'>
                                <div className='edit-action'>
                                  <ul className='edit-list action-btn'>
                                    <PermissionAgent
                                      resource={'application'}
                                      scope={'list'}
                                      Component={() => (
                                        <li>
                                          <button
                                            type='button'
                                            onClick={() =>
                                              handleChangePage(cell.row.cells)
                                            }
                                            className='theme-btn questions-btn'
                                            title='Aplicações'
                                          >
                                            <GiPapers
                                              className='react-icons'
                                              size='1em'
                                            />
                                          </button>
                                        </li>
                                      )}
                                    />
                                    <PermissionAgent
                                      resource={'application'}
                                      scope={'export'}
                                      Component={() => (
                                        <li>
                                          <button
                                            className='theme-btn questions-btn'
                                            title='Exportar Aplicações'
                                            onClick={() =>
                                              handleClickOpen(cell.row)
                                            }
                                          >
                                            <SiMicrosoftexcel
                                              className='react-icons'
                                              size='1em'
                                            />
                                          </button>
                                        </li>
                                      )}
                                    />
                                    <PermissionAgent
                                      resource={'question'}
                                      scope={'list'}
                                      Component={() => (
                                        <li>
                                          <Link
                                            className='theme-btn questions-btn'
                                            to={
                                              '/quizzes/' +
                                              cell.value +
                                              '/questions'
                                            }
                                            title='Questões'
                                          >
                                            <RiQuestionAnswerLine
                                              className='react-icons'
                                              size='1em'
                                            />
                                          </Link>
                                        </li>
                                      )}
                                    />
                                    <PermissionAgent
                                      resource={'quiz'}
                                      scope={'copy'}
                                      Component={() => (
                                        <li>
                                          <button
                                            className='theme-btn questions-btn'
                                            title='Copiar'
                                            onClick={() => copyQuiz(cell.value)}
                                          >
                                            <AiOutlineCopy
                                              className='react-icons'
                                              size='1em'
                                            />
                                          </button>
                                        </li>
                                      )}
                                    />
                                    <PermissionAgent
                                      resource={'quiz'}
                                      scope={'retrieve'}
                                      Component={() => (
                                        <li>
                                          <Link
                                            className='theme-btn edit-btn'
                                            to={'/quizzes/' + cell.value}
                                            title='Editar'
                                          >
                                            <BiEdit
                                              className='react-icons'
                                              size='1em'
                                            />
                                          </Link>
                                        </li>
                                      )}
                                    />
                                  </ul>
                                </div>
                              </div>
                            </td>
                          )
                        }
                        if (cell.column.id === 'link') {
                          return (
                            <td key={cell.column.id} {...cell.getCellProps()}>
                              <div className='statement-info'>
                                <a
                                  href={`${process.env.REACT_APP_CANDIDATE_URL}/${cell.row.cells[3].value}`}
                                  target='_blank'
                                  rel='noreferrer'
                                >{`${process.env.REACT_APP_CANDIDATE_URL}/${cell.row.cells[3].value}`}</a>
                              </div>
                            </td>
                          )
                        }
                        if (cell.column.id == 'status') {
                          switch (cell.value) {
                            case 'published':
                              return (
                                <td
                                  style={{ width: '50px' }}
                                  {...cell.getCellProps()}
                                >
                                  <div className='statement-info'>
                                    <span className='badge bg-success text-white p-1'>
                                      Publicado
                                    </span>
                                  </div>
                                </td>
                              )
                            case 'unpublished':
                              return (
                                <td
                                  style={{ width: '50px' }}
                                  {...cell.getCellProps()}
                                >
                                  <div className='statement-info'>
                                    <span className='badge bg-danger text-white p-1'>
                                      Não publicado
                                    </span>
                                  </div>
                                </td>
                              )
                            case 'in_testing':
                              return (
                                <td
                                  style={{ width: '50px' }}
                                  {...cell.getCellProps()}
                                >
                                  <div className='statement-info'>
                                    <span className='badge bg-warning text-white p-1'>
                                      Em Teste
                                    </span>
                                  </div>
                                </td>
                              )
                            case 'archived':
                              return (
                                <td
                                  style={{ width: '50px' }}
                                  {...cell.getCellProps()}
                                >
                                  <div className='statement-info'>
                                    <span className='badge bg-secondary text-white p-1'>
                                      Arquivado
                                    </span>
                                  </div>
                                </td>
                              )
                          }
                        }
                        return (
                          <td key={cell.column.id} {...cell.getCellProps()}>
                            <div className='statement-info'>
                              {cell.render('Cell')}
                            </div>
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className='page-navigation-wrap mt-4 mb-4'>
            <div className='page-navigation-inner d-inline-block'>
              <div className='page-navigation'>
                <button
                  type='button'
                  className='btn page-go page-prev'
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <i className='la la-arrow-left icon-table'></i>
                </button>
                <ul className='page-navigation-nav'>
                  <li>
                    <span>
                      Página <strong>{pageIndex + 1}</strong>{' '}
                    </span>
                  </li>
                </ul>
                <button
                  type='button'
                  className='btn page-go page-next'
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  <i className='la la-arrow-right icon-table'></i>
                </button>
                <div className='page-size'>
                  <label>Itens por página: </label>
                  <div className='select-input sort-ordering user-form-short'>
                    <select
                      className='sort-ordering-select-no-search'
                      value={pageSize}
                      onChange={e => {
                        setPageSize(Number(e.target.value))
                      }}
                    >
                      {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          {pageSize}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth={true}
        maxWidth='sm'
      >
        <DialogTitle id='alert-dialog-title'>Exportar Aplicações</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {exportResultMsg !== '' ? showExportResultMsg() : ''}
            <div className='input-dates'>
              <InputDatePicker
                label='Data inicial'
                handleStartDate={handleStartDate}
              />
              <InputDatePicker
                label='Data final'
                handleStartDate={handleEndDate}
              />
            </div>
            <div className='validation-msg-dates'>
              <span>{dateValidationMsg}</span>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className='edit-action'>
            <ul className='edit-list'>
              <li>
                <button className='theme-btn edit-btn' onClick={handleClose}>
                  cancelar
                </button>
              </li>
              <li>
                <button className='theme-btn' onClick={() => exportXLS()}>
                  Exportar
                </button>
              </li>
            </ul>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default TablePaginated
