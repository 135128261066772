import React from 'react'
import { useField } from 'formik'
import PopoverHelp from '../../utils/buttons/popoverHelp'
import './selectInput.css'

export default function SelectInput({ label, ...props }) {
  const [field, meta] = useField(props)
  return (
    <>
      <div className='input-box'>
        <div className='popover-help'>
          <label className='label-text'>{label}</label>
          {props.popoverHelp ? <PopoverHelp content={props.popoverHelp} /> : ''}
        </div>
        <div className='form-group'>
          <div className='sort-ordering user-form-short'>
            <select {...field} {...props} />
            {meta.touched && meta.error ? (
              <div style={{ color: 'red' }} className='error'>
                {meta.error}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}
