import React from 'react'
import { Radar } from 'react-chartjs-2'

const avgScoreByKnowledgesChart = props => {
  const data = {
    labels: props.data.labels,
    datasets: [
      {
        label: 'Médias',
        data: props.data.data,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1
      }
    ]
  }

  const options = {
    scale: {
      ticks: { beginAtZero: true }
    }
  }

  return (
    <div>
      <div className='chart-area'>
        <div className='chart-item'>
          <div className='chart-headline margin-bottom-40px'>
            <h3 className='widget-title font-size-18'>
              Nota media por área de conhecimento
            </h3>
          </div>
          <Radar data={data} options={options} />
        </div>
      </div>
    </div>
  )
}

export default avgScoreByKnowledgesChart
