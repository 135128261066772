import React from 'react'

const MessageClosable = props => {
  const [msgType, setMsgType] = React.useState('')
  let cssClass = ''
  let msg = ''

  switch (msgType) {
    case 'deactivate':
      cssClass = 'alert alert-success'
      msg = 'Registro desativado com sucesso!'
      break
    case 'activate':
      cssClass = 'alert alert-success'
      msg = 'Registro ativado com sucesso!'
      break

    case 'update-success':
      cssClass = 'alert alert-success'
      msg = 'Registro atualizado com sucesso!'
      break

    case 'invalid-image':
      cssClass = 'alert alert-danger'
      msg = 'Selecione um arquivo JPG ou PNG.'
      break

    case 'invalid-xlsx':
      cssClass = 'alert alert-danger'
      msg = 'Apenas formato .xlsx é permitido.'
      break

    case 'internal-error':
      cssClass = 'alert alert-danger'
      msg = 'Ocorreu um erro interno ao processar a solicitação.'
      break

    case 'error-save-answer':
      cssClass = 'alert alert-danger'
      msg = 'Ocorreu um erro ao salvar a resposta.'
      break

    case 'error-load-content':
      cssClass = 'alert alert-danger'
      msg = 'Ocorreu um erro ao carregar o conteúdo.'
      break

    case 'insert-failed':
      cssClass = 'alert alert-danger'
      msg = 'Ocorreu um erro ao tentar salvar o registro.'
      break

    case 'record-already-exists':
      cssClass = 'alert alert-danger'
      msg = 'Esse registro já existe.'
      break

    case 'reapplied-successful':
      cssClass = 'alert alert-success'
      msg = 'Reaplicação realizada com sucesso!'
      break

    case '401':
      cssClass = 'alert alert-danger'
      msg = 'Você não está autenticado.'
      break

    case '403':
      cssClass = 'alert alert-danger'
      msg = 'Você não possui permissão para está operação.'
      break

    case '406':
      cssClass = 'alert alert-danger'
      msg = 'Os dados informados não foram aceitos.'
      break

    case '409':
      cssClass = 'alert alert-danger'
      msg = 'Esse registro já existe.'
      break

    case '412':
      cssClass = 'alert alert-danger'
      msg = 'Não atende aos pré-requisitos.'
      break

    case '423':
      cssClass = 'alert alert-danger'
      msg =
        'Este registro está bloqueado. Verifique se está indisponível, desatualizado ou se possui aplicações.'
      break

    case '424':
      cssClass = 'alert alert-danger'
      msg = 'Uma ou mais dependências não são válidas.'
      break

    case '500':
      cssClass = 'alert alert-danger'
      msg = 'Ocorreu um erro interno ao processar a solicitação.'
      break

    default:
      msg = ''
      break
  }

  React.useEffect(() => {
    setMsgType(props.msgType)
    window.scrollTo(0, 0)
    setTimeout(function () {
      setMsgType('')
    }, 15000)
  }, [props.msgType])

  return (
    <div className={cssClass + ' alert-dismissible fade show'} role='alert'>
      {msg}
    </div>
  )
}
export default MessageClosable
