import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    addApplicationInfo(state, action) {
      const { candidateName, candidateEmail, uuid } = action.payload
      state.candidateName = candidateName
      state.candidateEmail = candidateEmail
      state.uuid = uuid
    }
  }
})

export const { addApplicationInfo, postUpdated } = applicationSlice.actions

export default applicationSlice.reducer
