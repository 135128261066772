import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'

const SwitchStatus = withStyles({
  switchBase: {
    color: '#c54949',
    '&$checked': {
      color: '#51be78'
    },
    '&$checked + $track': {
      backgroundColor: '#388554'
    }
  },
  checked: {},
  track: {}
})(Switch)

export default function CustomizedSwitches(props) {
  return <SwitchStatus {...props} />
}
