import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    addPermissions(state, action) {
      const { userPermissions } = action.payload
      state.value = userPermissions
    }
  }
})

export const { addPermissions } = permissionsSlice.actions

export default permissionsSlice.reducer
