import React from 'react'
import { Link, NavLink, useHistory } from 'react-router-dom'
import { useTable, usePagination } from 'react-table'
import './tablePaginated.css'
import $ from 'jquery'
import MessageClosable from '../../utils/messages/messageClosable'
import HttpRequests from '../../api/httpRequests'
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight'
import SwitchButton from '../../utils/buttons/switch'
import PreloaderLight from '../../template/preloaderLight'
import { PermissionAgent } from '../permissions/permissionAgent'

function TablePaginated({ columns, data, fetchData }) {
  let history = useHistory()
  const [loading, setloading] = React.useState(false)

  const deactivate = async uuid => {
    setloading(true)
    try {
      await HttpRequests.patchBehaviorAerea({ status: 'inactive' }, uuid)
      fetchData({ pageIndex, pageSize })
    } catch {
      history.push('/error_500')
    } finally {
      setloading(false)
    }
  }

  const activate = async uuid => {
    setloading(true)
    try {
      await HttpRequests.patchBehaviorAerea({ status: 'active' }, uuid)
      fetchData({ pageIndex, pageSize })
    } catch {
      history.push('/error_500')
    } finally {
      setloading(false)
    }
  }

  const handleSwitchStatus = event => {
    switch (event.target.checked) {
      case true:
        activate(event.target.name)
        break
      case false:
        deactivate(event.target.name)
        break
    }
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: -1
    },
    usePagination
  )

  const [msgType] = React.useState('')

  React.useEffect(() => {
    $('.preloader').show()
    window.$('.sort-ordering-select-no-search').selectpicker()
    fetchData({ pageIndex, pageSize })
  }, [fetchData, pageIndex, pageSize])

  return (
    <div>
      {loading === true ? <PreloaderLight /> : ''}
      <div className='card-box-shared'>
        <div className='card-box-shared-title'>
          <h3 className='widget-title'>Áreas de Comportamento</h3>
          <PermissionAgent
            resource={'behavior-area'}
            scope={'create'}
            Component={() => (
              <NavLink
                className='theme-btn btn-add-client'
                to='/behavior-area/create'
              >
                Adicionar
              </NavLink>
            )}
          />
        </div>
        <div className='card-box-shared-body'>
          <MessageClosable msgType={msgType} />
          <div className='statement-table purchase-table table-responsive mb-5'>
            <table className='table' {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup, headerGroupIndex) => (
                  <tr
                    key={`headerGroup-${headerGroupIndex}`}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column, columnIndex) => (
                      <th
                        key={`header-${headerGroupIndex}-${columnIndex}`}
                        className=''
                        scope='col'
                        {...column.getHeaderProps()}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, rowIndex) => {
                  prepareRow(row)
                  return (
                    <tr key={`row-${rowIndex}`} {...row.getRowProps()}>
                      {row.cells.map((cell, cellIndex) => {
                        if (cell.column.id == 'uuid') {
                          return (
                            <td
                              key={`cell-${rowIndex}-${cellIndex}`}
                              style={{ width: '125px' }}
                              {...cell.getCellProps()}
                            >
                              <div className='statement-info'>
                                <div className='edit-action'>
                                  <ul className='edit-list action-btn'>
                                    <li>
                                      <Link
                                        className='theme-btn questions-btn'
                                        to={
                                          '/behavior-area/' +
                                          cell.value +
                                          '/behaviors'
                                        }
                                        title='Comportamentos'
                                      >
                                        <SubdirectoryArrowRightIcon fontSize='small' />
                                      </Link>
                                    </li>
                                    <PermissionAgent
                                      resource={'behavior-area'}
                                      scope={'retrieve'}
                                      Component={() => (
                                        <li>
                                          <Link
                                            className='theme-btn edit-btn'
                                            to={'/behavior-area/' + cell.value}
                                            title='Editar'
                                          >
                                            <i className='no-description la la-edit mr-1 font-size-16'></i>
                                          </Link>
                                        </li>
                                      )}
                                    />
                                  </ul>
                                </div>
                              </div>
                            </td>
                          )
                        }
                        if (cell.column.id == 'status') {
                          if (cell.value == 'active') {
                            return (
                              <td
                                key={`cell-${rowIndex}-${cellIndex}`}
                                style={{ width: '100px' }}
                                {...cell.getCellProps()}
                              >
                                <div className='statement-info'>
                                  <PermissionAgent
                                    resource={'client'}
                                    scope={'update'}
                                    Component={() => (
                                      <SwitchButton
                                        checked={
                                          cell.value === 'active' ? true : false
                                        }
                                        onChange={handleSwitchStatus}
                                        name={row.allCells[2].value}
                                      />
                                    )}
                                  />
                                </div>
                              </td>
                            )
                          } else {
                            return (
                              <td
                                key={`cell-${rowIndex}-${cellIndex}`}
                                {...cell.getCellProps()}
                              >
                                <div className='statement-info'>
                                  <PermissionAgent
                                    resource={'client'}
                                    scope={'update'}
                                    Component={() => (
                                      <SwitchButton
                                        checked={
                                          cell.value === 'active' ? true : false
                                        }
                                        onChange={handleSwitchStatus}
                                        name={row.allCells[2].value}
                                      />
                                    )}
                                  />
                                </div>
                              </td>
                            )
                          }
                        }
                        return (
                          <td
                            key={`cell-${rowIndex}-${cellIndex}`}
                            {...cell.getCellProps()}
                          >
                            <div className='statement-info'>
                              {cell.render('Cell')}
                            </div>
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className='page-navigation-wrap mt-4 mb-4'>
            <div className='page-navigation-inner d-inline-block'>
              <div className='page-navigation'>
                <button
                  type='button'
                  className='btn page-go page-prev'
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <i className='la la-arrow-left icon-table'></i>
                </button>
                <ul className='page-navigation-nav'>
                  <li>
                    <span>
                      Página <strong>{pageIndex + 1}</strong>{' '}
                    </span>
                  </li>
                </ul>
                <button
                  type='button'
                  className='btn page-go page-next'
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  <i className='la la-arrow-right icon-table'></i>
                </button>
                <div className='page-size'>
                  <label>Itens por página: </label>
                  <div className='select-input sort-ordering user-form-short'>
                    <select
                      className='sort-ordering-select-no-search'
                      value={pageSize}
                      onChange={e => {
                        setPageSize(Number(e.target.value))
                      }}
                    >
                      {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          {pageSize}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TablePaginated
