import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import $ from 'jquery'
import '../features/dashboard/dashboard.css'
import TestsCompleted from '../features/dashboard/widgets/testsCompleted'
import RunningTests from '../features/dashboard/widgets/runningTests'
import PercentageCompleted from '../features/dashboard/widgets/percentageCompleted'
import TotalCandidates from '../features/dashboard/widgets/totalCandidates'
import AverageExecutionTime from '../features/dashboard/widgets/averageExecutionTime'
import AverageScore from '../features/dashboard/widgets/averageScore'
import TestsCompletedCharts from '../features/dashboard/charts/testsCompletedChart'
import ExecutionLanguageChart from '../features/dashboard/charts/executionLanguageChart'
import AvgScoreByKnowledgesChart from '../features/dashboard/charts/avgScoreByKnowledgesChart'
import FilterUI from '../features/dashboard/filterUI'
import DatePickerFilter from '../features/dashboard/datePickerFilter'
import HttpRequests from '../api/httpRequests'
import SearchIcon from '@material-ui/icons/Search'
import { convertToTimestampMidnight } from '../utils/utilFunctions'
import { FiDelete } from 'react-icons/fi'

export default function Dashboard() {
  const [loading, setLoading] = useState(true)
  const [groups, setGroups] = useState(null)
  const [quizzes, setQuizzes] = useState(null)
  const [dashboardData, setDashboardData] = useState(null)

  const [groupFilter, setGroupFilter] = useState(null)
  const [quizFilter, setQuizFilter] = useState(null)
  const [startDateFilter, setStartDateFilter] = useState(null)
  const [endDateFilter, setEndDateFilter] = useState(null)

  let history = useHistory()

  const getContents = async () => {
    try {
      const responseGroup = await HttpRequests.listGroups({ per_page: 100 })
      setGroups(responseGroup.data.data)

      const responseQuizzes = await HttpRequests.listQuizzes({ per_page: 100 })
      setQuizzes(responseQuizzes.data.data)
      setQuizFilter(responseQuizzes.data.data[0].uuid)

      const responseDashboardData = await HttpRequests.retrieveDashboard({
        quiz: responseQuizzes.data.data[0].uuid
      })
      setDashboardData(responseDashboardData.data.data)

      setLoading(false)
    } catch (error) {
      history.push('/error_500')
    } finally {
      $('.preloader').delay('500').fadeOut(1000)
    }
  }

  const handleSearch = async () => {
    let parameters = {
      group: groupFilter,
      quiz: quizFilter,
      start_date:
        startDateFilter === null
          ? null
          : convertToTimestampMidnight(startDateFilter, true),
      end_date:
        endDateFilter === null
          ? null
          : convertToTimestampMidnight(endDateFilter, false)
    }

    const responseDashboardData = await HttpRequests.retrieveDashboard(
      parameters
    )
    setDashboardData(responseDashboardData.data.data)
  }

  const clearFilters = () => {
    setLoading(true)
    setGroupFilter(null)
    setQuizFilter(null)
    setStartDateFilter(null)
    setEndDateFilter(null)
    setLoading(false)
  }

  const handleGroupFilter = e => {
    setGroupFilter(e.target.value)
  }

  const handleQuizFilter = e => {
    setQuizFilter(e.target.value)
  }

  const handleStartDateFilter = e => {
    setStartDateFilter(e)
  }

  const handleEndDateFilter = e => {
    setEndDateFilter(e)
  }

  useEffect(() => {
    window.$('.sort-ordering-select').selectpicker()

    if (
      loading === true &&
      groups === null &&
      quizzes === null &&
      dashboardData === null
    ) {
      $('.preloader').show()
      getContents()
    } else {
      $('.preloader').delay('500').fadeOut(1000)
    }
  })

  if (loading === false) {
    return (
      <div>
        <h1>Dashboard</h1>
        <div className='row mt-5'>
          <div className='col-lg-12 column-lmd-2-half column-md-2-full'>
            <div className='icon-box d-flex align-items-center'>
              <div className='filters-area'>
                <div className='select-filter'>
                  <FilterUI
                    value={quizFilter === null ? '' : quizFilter}
                    label='Prova'
                    name='groupFilter'
                    data={quizzes}
                    onChange={e => handleQuizFilter(e)}
                  />
                </div>
                <div className='select-filter'>
                  <FilterUI
                    label='Grupo'
                    value={groupFilter === null ? '' : groupFilter}
                    name='groupFilter'
                    data={groups}
                    onChange={e => handleGroupFilter(e)}
                  />
                </div>
                <DatePickerFilter
                  label='Data inicial'
                  value={startDateFilter}
                  handleStartDate={handleStartDateFilter}
                  maxDate={endDateFilter}
                />
                <DatePickerFilter
                  label='Data Final'
                  value={endDateFilter}
                  handleStartDate={handleEndDateFilter}
                  minDate={startDateFilter}
                />
                <span className='filter-search' onClick={() => handleSearch()}>
                  <SearchIcon fontSize='large' />
                </span>
                <FiDelete
                  className='clear-filters'
                  size='2em'
                  onClick={() => clearFilters()}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row mt-3'>
          <TestsCompleted data={dashboardData.quizzes_done_amount} />
          <RunningTests data={dashboardData.quizzes_applied_amount} />
          <PercentageCompleted data={dashboardData.quizzes_done_percent} />
          <TotalCandidates data={dashboardData.candidates_amount} />
          <AverageExecutionTime data={dashboardData.quizzes_done_time_avg} />
          <AverageScore data={dashboardData.quizzes_done_score_avg} />
        </div>
        <div className='row'>
          <div className='col-lg-4 column-lmd-2-half column-md-full'>
            <TestsCompletedCharts data={dashboardData.quizzes_done_per_day} />
          </div>
          <div className='col-lg-4 column-lmd-2-half column-md-full'>
            <ExecutionLanguageChart
              data={dashboardData.quizzes_per_language_amount}
            />
          </div>
          <div className='col-lg-4 column-lmd-2-half column-md-full'>
            <AvgScoreByKnowledgesChart
              data={dashboardData.knowledge_areas_score_avg}
            />
          </div>
        </div>
      </div>
    )
  } else {
    return <div></div>
  }
}
