import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const ColorButton = withStyles(() => ({
  root: {
    color: '#fff',
    textTransform: 'capitalize',
    backgroundColor: '#51be78',
    '&:hover': {
      backgroundColor: '#388554'
    }
  }
}))(Button)

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
    width: '74px'
  }
}))

export default function CustomizedButtons(props) {
  const classes = useStyles()

  return (
    <div>
      <ColorButton
        {...props}
        size='small'
        variant='contained'
        color='primary'
        className={classes.margin}
      >
        {props.description ? props.description : ''}
      </ColorButton>
    </div>
  )
}
