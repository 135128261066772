import axios from 'axios'
import AuthHeader from './authHeader'

const API_URL =
  process.env.REACT_APP_API_SCHEME +
  '://' +
  process.env.REACT_APP_API_HOST +
  (process.env.REACT_APP_API_PORT == 80
    ? ''
    : ':' + process.env.REACT_APP_API_PORT) +
  process.env.REACT_APP_API_ENDPOINT

class HttpRequests {
  //BEGIN: Permissions
  async getPermissions() {
    return await axios({
      method: 'get',
      url: API_URL + '/permissions',
      headers: AuthHeader()
    })
  }

  //BEGIN: Clients
  async creatClient(data) {
    return await axios({
      method: 'post',
      url: API_URL + '/clients',
      headers: AuthHeader(),
      data: data
    })
  }
  async listClients(params) {
    return await axios({
      method: 'get',
      url: API_URL + '/clients',
      headers: AuthHeader(),
      params: params
    })
  }
  async retrieveClient(uuid) {
    return await axios({
      method: 'get',
      url: API_URL + '/clients/' + uuid,
      headers: AuthHeader()
    })
  }
  async patchClient(data, uuid) {
    return await axios({
      method: 'patch',
      url: API_URL + '/clients/' + uuid,
      headers: AuthHeader(),
      data: data
    })
  }
  async uploadImageClient(form, uuid) {
    return await axios.post(API_URL + '/clients/' + uuid + '/logo', form, {
      headers: AuthHeader()
    })
  }
  async deleteImageClient(uuid) {
    return await axios({
      method: 'delete',
      url: API_URL + '/clients/' + uuid + '/logo',
      headers: AuthHeader()
    })
  }

  //BEGIN: Groups
  async createGroup(data) {
    return await axios({
      method: 'post',
      url: API_URL + '/groups',
      headers: AuthHeader(),
      data: data
    })
  }
  async listGroups(params) {
    return await axios({
      method: 'get',
      url: API_URL + '/groups',
      headers: AuthHeader(),
      params: params
    })
  }
  async patchGroup(data, uuid) {
    return await axios({
      method: 'patch',
      url: API_URL + '/groups/' + uuid,
      headers: AuthHeader(),
      data: data
    })
  }
  async retrieveGroup(uuid) {
    return await axios({
      method: 'get',
      url: API_URL + '/groups/' + uuid,
      headers: AuthHeader()
    })
  }

  //BEGIN: Behavior Areas
  async createBehaviorArea(data) {
    return await axios({
      method: 'post',
      url: API_URL + '/behavior-areas',
      headers: AuthHeader(),
      data: data
    })
  }
  async listBehaviorAreas(params) {
    return await axios({
      method: 'get',
      url: API_URL + '/behavior-areas',
      headers: AuthHeader(),
      params: params
    })
  }
  async patchBehaviorAerea(data, uuid) {
    return await axios({
      method: 'patch',
      url: API_URL + '/behavior-areas/' + uuid,
      headers: AuthHeader(),
      data: data
    })
  }
  async retrieveBehaviorAreas(uuid) {
    return await axios({
      method: 'get',
      url: API_URL + '/behavior-areas/' + uuid,
      headers: AuthHeader()
    })
  }

  //BEGIN: Behaviors
  async createBehavior(data) {
    return await axios({
      method: 'post',
      url: API_URL + '/behaviors',
      headers: AuthHeader(),
      data: data
    })
  }
  async listBehaviors(params) {
    return await axios({
      method: 'get',
      url: API_URL + '/behaviors',
      headers: AuthHeader(),
      params: params
    })
  }
  async retrieveBehavior(uuid) {
    return await axios({
      method: 'get',
      url: API_URL + '/behaviors/' + uuid,
      headers: AuthHeader()
    })
  }
  async patchBehavior(data, uuid) {
    return await axios({
      method: 'patch',
      url: API_URL + '/behaviors/' + uuid,
      headers: AuthHeader(),
      data: data
    })
  }

  //BEGIN: Quizzes
  async createQuiz(data) {
    try {
      const response = await axios({
        method: 'post',
        url: API_URL + '/quizzes',
        headers: AuthHeader(),
        data: data
      })
      return {
        status_code: response.status,
        data: response.data
      }
    } catch (error) {
      return {
        status_code: error.response.status,
        data: error.response.data
      }
    }
  }
  async listQuizzes(params) {
    return await axios({
      method: 'get',
      url: API_URL + '/quizzes',
      headers: AuthHeader(),
      params: params
    })
  }
  async retrieveQuiz(uuid) {
    return await axios({
      method: 'get',
      url: API_URL + '/quizzes/' + uuid,
      headers: AuthHeader()
    })
  }
  async patchQuiz(data, uuid) {
    try {
      const response = await axios({
        method: 'patch',
        url: API_URL + '/quizzes/' + uuid,
        headers: AuthHeader(),
        data: data
      })
      return {
        status_code: response.status,
        data: response.data
      }
    } catch (error) {
      return {
        status_code: error.response.status,
        data: error.response.data
      }
    }
  }

  async patchStatus(data, uuid) {
    try {
      const response = await axios({
        method: 'patch',
        url: API_URL + '/quizzes/' + uuid + '/status',
        headers: AuthHeader(),
        data: data
      })
      return {
        status_code: response.status,
        data: response.data
      }
    } catch (error) {
      return {
        status_code: error.response.status,
        data: error.response.data
      }
    }
  }

  async allowReapply(data, uuid) {
    return await axios({
      method: 'post',
      url: API_URL + '/quizzes/' + uuid + '/allow-reapply',
      headers: AuthHeader(),
      data: data
    })
  }

  async copyQuiz(uuid) {
    return await axios({
      method: 'post',
      url: API_URL + '/quizzes/' + uuid + '/copy',
      headers: AuthHeader()
    })
  }

  async exportQuiz(uuid) {
    return await axios({
      method: 'get',
      url: API_URL + '/quizzes/' + uuid + '/export-quiz',
      responseType: 'blob',
      headers: AuthHeader()
    })
  }

  //BEGIN: Knowledge Areas
  async createKnowledgeArea(data) {
    return await axios({
      method: 'post',
      url: API_URL + '/knowledge-areas',
      headers: AuthHeader(),
      data: data
    })
  }
  async listKnowledgeAreas(params) {
    return await axios({
      method: 'get',
      url: API_URL + '/knowledge-areas',
      headers: AuthHeader(),
      params: params
    })
  }
  async patchKnowledgeArea(data, uuid) {
    return await axios({
      method: 'patch',
      url: API_URL + '/knowledge-areas/' + uuid,
      headers: AuthHeader(),
      data: data
    })
  }
  async retrieveKnowledgeArea(uuid) {
    return await axios({
      method: 'get',
      url: API_URL + '/knowledge-areas/' + uuid,
      headers: AuthHeader()
    })
  }

  //BEGIN: Questions
  async createQuestion(data) {
    try {
      const response = await axios({
        method: 'post',
        url: API_URL + '/questions',
        headers: AuthHeader(),
        data: data
      })
      return {
        status_code: response.status,
        data: response.data
      }
    } catch (error) {
      return {
        status_code: error.response.status,
        data: error.response.data
      }
    }
  }
  async listQuestions(params) {
    return await axios({
      method: 'get',
      url: API_URL + '/questions',
      headers: AuthHeader(),
      params: params
    })
  }
  async retrieveQuestion(uuid) {
    return await axios({
      method: 'get',
      url: API_URL + '/questions/' + uuid,
      headers: AuthHeader()
    })
  }
  async deleteQuestion(uuid) {
    return await axios({
      method: 'delete',
      url: API_URL + '/questions/' + uuid,
      headers: AuthHeader()
    })
  }
  async patchQuestion(data, uuid) {
    try {
      const response = await axios({
        method: 'patch',
        url: API_URL + '/questions/' + uuid,
        headers: AuthHeader(),
        data: data
      })
      return {
        status_code: response.status,
        data: response.data
      }
    } catch (error) {
      return {
        status_code: error.response.status,
        data: error.response.data
      }
    }
  }
  async uploadImageQuestion(form, uuid) {
    return await axios.post(API_URL + '/questions/' + uuid + '/image', form, {
      headers: AuthHeader()
    })
  }
  async uploadQuestions(form, uuid) {
    return await axios.post(
      API_URL + '/quizzes/' + uuid + '/questions-import',
      form,
      { headers: AuthHeader() }
    )
  }

  //BEGIN: Answers
  async createAnswer(data) {
    return await axios({
      method: 'post',
      url: API_URL + '/answers',
      headers: AuthHeader(),
      data: data
    })
  }
  async listAnswers(params) {
    return await axios({
      method: 'get',
      url: API_URL + '/answers',
      headers: AuthHeader(),
      params: params
    })
  }
  async retrieveAnswer(uuid) {
    return await axios({
      method: 'get',
      url: API_URL + '/answers/' + uuid,
      headers: AuthHeader()
    })
  }
  async patchAnswer(data, uuid) {
    return await axios({
      method: 'patch',
      url: API_URL + '/answers/' + uuid,
      headers: AuthHeader(),
      data: data
    })
  }
  async deleteAnswer(uuid) {
    return await axios({
      method: 'delete',
      url: API_URL + '/answers/' + uuid,
      headers: AuthHeader()
    })
  }
  async uploadImageAnswer(form, uuid) {
    return await axios.post(API_URL + '/answers/' + uuid + '/image', form, {
      headers: AuthHeader()
    })
  }

  //BEGIN: Users
  async createUser(data) {
    return await axios({
      method: 'post',
      url: API_URL + '/users',
      headers: AuthHeader(),
      data: data
    })
  }
  async listUsers(params) {
    return await axios({
      method: 'get',
      url: API_URL + '/users',
      headers: AuthHeader(),
      params: params
    })
  }
  async patchUser(data, uuid) {
    return await axios({
      method: 'patch',
      url: API_URL + '/users/' + uuid,
      headers: AuthHeader(),
      data: data
    })
  }
  async retrieveUser(uuid) {
    return await axios({
      method: 'get',
      url: API_URL + '/users/' + uuid,
      headers: AuthHeader()
    })
  }

  //BEGIN: Applications
  async listApplications(params) {
    return await axios({
      method: 'get',
      url: API_URL + '/applications',
      headers: AuthHeader(),
      params: params
    })
  }
  async exportApplications(params) {
    return await axios({
      method: 'get',
      url: API_URL + '/applications/export',
      responseType: 'blob',
      headers: AuthHeader(),
      params: params
    })
  }

  //BEGIN: Applications Evaluation
  async listEvaluations(params) {
    return await axios({
      method: 'get',
      url: API_URL + '/application-evaluations',
      headers: AuthHeader(),
      params: params
    })
  }
  async patchEvaluation(data, uuid) {
    return await axios({
      method: 'patch',
      url: API_URL + '/application-evaluations/' + uuid,
      headers: AuthHeader(),
      data: data
    })
  }

  //BEGIN: Dashboard
  async retrieveDashboard(params) {
    return await axios({
      method: 'get',
      url: API_URL + '/dashboard',
      headers: AuthHeader(),
      params: params
    })
  }

  //BEGIN: Candidate
  async listCandidates(params) {
    return await axios({
      method: 'get',
      url: API_URL + '/candidates',
      headers: AuthHeader(),
      params: params
    })
  }

  async listCandidateApplications(params, keycloak_id) {
    return await axios({
      method: 'get',
      url: API_URL + '/candidates/keycloak-id/' + keycloak_id,
      headers: AuthHeader(),
      params: params
    })
  }

  async exportCandidateApplications() {
    return await axios({
      method: 'get',
      url: API_URL + '/candidates/export-applications',
      responseType: 'blob',
      headers: AuthHeader()
    })
  }
}

export default new HttpRequests()
