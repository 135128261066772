import React from 'react'
import { MdTimer } from 'react-icons/md'

const averageExecutionTime = props => {
  return (
    <div className='col-lg-4 column-lmd-2-half column-md-2-full'>
      <div className='icon-box d-flex align-items-center'>
        <div className='icon-element icon-element-bg-1 flex-shrink-0'>
          <MdTimer />
        </div>
        <div className='info-content'>
          <h4 className='info__title mb-2'>Tempo médio de execução</h4>
          <span className='info__count'>{props.data}</span>
        </div>
      </div>
    </div>
  )
}

export default averageExecutionTime
