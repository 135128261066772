import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import $ from 'jquery'
import '../features/evaluation/evaluation.css'
import HttpRequests from '../api/httpRequests'
import Message from '../utils/messages/message'
import SelectInput from '../utils/simpleForm/selectInput'

export default function Evaluation() {
  const applicationInfo = useSelector(state => state.application)
  const quizInfo = useSelector(state => state.quiz)

  let history = useHistory()
  let { uuid } = useParams()
  const [evaluationData, setEvaluationData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [msgType, setMsgType] = useState('')

  const getContents = async () => {
    setMsgType('')

    try {
      const responseEvaluation = await HttpRequests.listEvaluations({
        application: uuid,
        per_page: 100
      })

      let evaluations = []

      const promises = responseEvaluation.data.data.map(
        async (evaluation, i) => {
          let questionResponse = await HttpRequests.retrieveQuestion(
            evaluation.question.uuid
          )

          if (evaluation.answer) {
            let answerResponse = await HttpRequests.retrieveAnswer(
              evaluation.answer.uuid
            )

            evaluations[i] = {
              uuid: evaluation.uuid,
              application: {
                uuid: evaluation.application.uuid
              },
              question: {
                uuid: evaluation.question.uuid,
                description: questionResponse.data.data.description
              },
              answer: {
                uuid: evaluation.answer.uuid,
                description: answerResponse.data.data.description
              },
              time: evaluation.time,
              skipped: evaluation.skipped,
              timeout: evaluation.timeout,
              evaluated: evaluation.evaluated,
              score: evaluation.score
            }
          }
        }
      )

      await Promise.all(promises)

      setEvaluationData(evaluations)
      setLoading(false)
    } catch (error) {
      history.push('/error_500')
    } finally {
      $('.preloader').delay('500').fadeOut(1000)
    }
  }

  const handleEvaluation = async e => {
    setMsgType('')

    if (e.target.value !== '') {
      $('.preloader').show()

      try {
        await HttpRequests.patchEvaluation(
          { score: e.target.value },
          e.target.id
        )
        setMsgType('update-success')
      } catch (error) {
        switch (error.response.status) {
          case 401:
            setMsgType('401')
            break

          case 403:
            setMsgType('403')
            break

          case 406:
            setMsgType('406')
            break

          case 409:
            setMsgType('409')
            break

          case 412:
            setMsgType('412')
            break

          case 423:
            setMsgType('423')
            break

          case 424:
            setMsgType('424')
            break

          default:
            setMsgType('500')
        }
      } finally {
        $('.preloader').delay('500').fadeOut(1000)
        window.scrollTo(0, 0)
      }
    }
  }

  useEffect(() => {
    if (!applicationInfo.candidateName) {
      history.push('/quizzes')
    }

    if (loading === true) {
      $('.preloader').show()
      getContents()
    }
    window.$('.sort-ordering-select-no-search').selectpicker()
  })

  if (loading === false && evaluationData.length === 0) {
    return (
      <div>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='breadcrumb-content dashboard-bread-content d-flex align-items-center justify-content-between'>
              <div className='user-bread-content d-flex align-items-center'>
                <div className='section-heading'>
                  <h2 className='section__title font-size-30'>
                    {quizInfo.quizName}
                  </h2>
                  <div className='rating-wrap d-flex mt-2'>
                    <span className='star-rating-wrap'>
                      <span className='star__count'>
                        Avaliação do candidato:{' '}
                        {`${applicationInfo.candidateName} - ${applicationInfo.candidateEmail}`}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='no-question-message-area'>
              <p className='no-question-message'>
                Não existe questões abertas ou ainda não estão disponíveis.
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (loading === false) {
    return (
      <div>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='breadcrumb-content dashboard-bread-content d-flex align-items-center justify-content-between'>
              <div className='user-bread-content d-flex align-items-center'>
                <div className='section-heading'>
                  <h2 className='section__title font-size-30'>
                    {quizInfo.quizName}
                  </h2>
                  <div className='rating-wrap d-flex mt-2'>
                    <span className='star-rating-wrap'>
                      <span className='star__count'>
                        Avaliação do candidato:{' '}
                        {`${applicationInfo.candidateName} - ${applicationInfo.candidateEmail}`}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='info-message'>
          <Message msgType={msgType} />
        </div>
        {evaluationData.map((data, i) => (
          <div
            key={`${i}-${Math.random().toString(36).slice(2)}`}
            className='row mt-5'
          >
            <div className='col-lg-12'>
              <div className='card-box-shared'>
                <div className='card-box-shared-title'>
                  <h3 className='widget-title'>Questão {i + 1}</h3>
                </div>
                <div className='card-box-shared-body'>
                  <div className='user-form'>
                    <div className='contact-form-action'>
                      <div className='row'>
                        <div className='col-lg-12 col-sm-12'>
                          <div
                            className='evaluation-question-description upload-input-background'
                            dangerouslySetInnerHTML={{
                              __html: data.question.description
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-lg-9 col-sm-9'>
                          <div className='evaluation-answer-description'>
                            <span className='evaluation-answer-label'>
                              Resposta:{' '}
                            </span>
                            <div className='answer-text'>
                              {data.answer.description}
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-3 col-sm-3'>
                          <div className='evaluation-answer-description'>
                            <SelectInput
                              value={
                                evaluationData[i].score === 0 &&
                                evaluationData[i].evaluated === false
                                  ? ''
                                  : evaluationData[i].evaluated === true
                                  ? evaluationData[i].score
                                  : ''
                              }
                              label='Correção'
                              id={data.uuid}
                              name={`score.${i}`}
                              className='sort-ordering-select-no-search'
                              onChange={e => handleEvaluation(e)}
                            >
                              <option value=''>Selecione</option>
                              <option value={1}>Correta</option>
                              <option value={0}>Incorreta</option>
                            </SelectInput>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className='row'>
          <div className='col-md-12'>
            <div className='btn-save-question'>
              <button
                onClick={() => window.history.back()}
                className='theme-btn btn-add-client'
              >
                Retornar
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return <div></div>
}
