import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: '90%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}))

export default function FilterUI(props) {
  const classes = useStyles()

  return (
    <div>
      <FormControl variant='outlined' className={classes.formControl}>
        <InputLabel id='select-filter-outlined-label'>{props.label}</InputLabel>
        <Select
          labelId='select-filter-outlined-label'
          id='select-filter-outlined'
          value={props.value}
          onChange={props.onChange}
          label={props.label}
        >
          <MenuItem value=''>
            <em>Selecione</em>
          </MenuItem>
          {props.data.map(function (data) {
            return (
              <MenuItem key={data.id} value={data.id}>
                {data.title ? data.title : data.name}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </div>
  )
}
