import React from 'react'
import { useHistory } from 'react-router-dom'
import $ from 'jquery'
import '../features/knowledgeAreas/knowledgeAreas.css'
import TablePaginated from '../features/knowledgeAreas/tablePaginated'
import HttpRequests from '../api/httpRequests'
import { useKeycloak } from '@react-keycloak/web'

export default function Client() {
  const history = useHistory()
  const { keycloak } = useKeycloak()

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nome',
        accessor: 'name'
      },
      {
        Header: 'Ativo',
        accessor: 'status'
      },
      {
        Header: '',
        accessor: 'uuid'
      }
    ],
    []
  )
  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const fetchIdRef = React.useRef(0)

  const fetchData = React.useCallback(async ({ pageSize, pageIndex }) => {
    const fetchId = ++fetchIdRef.current
    setLoading(true)

    try {
      const response = await HttpRequests.listKnowledgeAreas({
        per_page: pageSize,
        page: pageIndex + 1
      })

      if (fetchId === fetchIdRef.current) {
        setData(response.data.data)
        setLoading(false)
      }

      document.title = 'Áreas de conhecimento - Admin Rocketquiz'
    } catch (error) {
      switch (error.response.status) {
        case 401:
          keycloak.logout()
          break

        case 403:
          history.push('/error/403')
          break

        case 404:
          history.push('/error/404')
          break

        default:
          history.push('/error/500')
      }
    } finally {
      $('.preloader').delay('250').fadeOut(500)
    }
  }, [])
  return (
    <TablePaginated
      columns={columns}
      data={data}
      fetchData={fetchData}
      loading={loading}
    />
  )
}
