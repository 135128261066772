import React from 'react'
import axios from 'axios'
import { Link, NavLink } from 'react-router-dom'
import { useTable, usePagination } from 'react-table'
import './tablePaginated.css'
import $ from 'jquery'
import AuthHeader from '../../api/authHeader'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import MessageClosable from '../../utils/messages/messageClosable'
import { PermissionAgent } from '../permissions/permissionAgent'

const API_URL =
  process.env.REACT_APP_API_SCHEME +
  '://' +
  process.env.REACT_APP_API_HOST +
  (process.env.REACT_APP_API_PORT == 80
    ? ''
    : ':' + process.env.REACT_APP_API_PORT) +
  process.env.REACT_APP_API_ENDPOINT

function ClientsTable({ columns, data, fetchData }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: -1
    },
    usePagination
  )

  const [msgType, setMsgType] = React.useState('')

  const deleteClient = () => {
    $('.preloader').show()
    axios({
      method: 'delete',
      url: API_URL + '/clients/' + client_uuid,
      headers: AuthHeader()
    })
      .then(response => {
        if (response.status == 204) {
          setMsgType('deleted')
          fetchData({ pageIndex, pageSize })
        }
      })
      .catch(error => {
        console.log(error)
      })

    setOpen(false)
  }

  // BEGIN: Modal
  const [open, setOpen] = React.useState(false)
  const [client_uuid, setClient_uuid] = React.useState('')

  const handleClickOpen = client_uuid => {
    setOpen(true)
    setClient_uuid(client_uuid)
  }

  const handleClose = () => {
    setOpen(false)
    setClient_uuid('')
  }
  // END: Modal

  React.useEffect(() => {
    $('.preloader').show()
    window.$('.sort-ordering-select-no-search').selectpicker()
    fetchData({ pageIndex, pageSize })
  }, [fetchData, pageIndex, pageSize])

  return (
    <div>
      <div className='card-box-shared'>
        <div className='card-box-shared-title'>
          <h3 className='widget-title'>Clientes</h3>
          <PermissionAgent
            resource={'client'}
            scope={'create'}
            Component={() => (
              <NavLink
                className='theme-btn btn-add-client'
                to='/clients/create'
              >
                Adicionar
              </NavLink>
            )}
          />
        </div>
        <div className='card-box-shared-body'>
          <MessageClosable msgType={msgType} />
          <div className='statement-table purchase-table table-responsive mb-5'>
            <table className='table' {...getTableProps()}>
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr
                    key={headerGroup.id}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map(column => (
                      <th
                        key={column.id}
                        className=''
                        scope='col'
                        {...column.getHeaderProps()}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, rowIndex) => {
                  prepareRow(row)
                  return (
                    <tr key={`row-${rowIndex}`} {...row.getRowProps()}>
                      {row.cells.map((cell, cellIndex) => {
                        if (cell.column.id === 'uuid') {
                          return (
                            <td
                              key={`cell-${rowIndex}-${cellIndex}`}
                              {...cell.getCellProps()}
                            >
                              <div
                                style={{
                                  float: 'right'
                                }}
                                className='statement-info'
                              >
                                <div className='edit-action'>
                                  <ul className='edit-list'>
                                    <PermissionAgent
                                      resource={'client'}
                                      scope={'retrieve'}
                                      Component={() => (
                                        <li>
                                          <Link
                                            className='theme-btn edit-btn'
                                            to={'/clients/' + cell.value}
                                          >
                                            <i className='no-description la la-edit mr-1 font-size-16'></i>
                                          </Link>
                                        </li>
                                      )}
                                    />
                                    <PermissionAgent
                                      resource={'client'}
                                      scope={'delete'}
                                      Component={() => (
                                        <li>
                                          <button
                                            className='theme-btn delete-btn'
                                            onClick={() =>
                                              handleClickOpen(cell.value)
                                            }
                                          >
                                            <i className='no-description la la-trash mr-1 font-size-16'></i>
                                          </button>
                                        </li>
                                      )}
                                    />
                                  </ul>
                                </div>
                              </div>
                            </td>
                          )
                        }
                        if (cell.column.id === 'logo') {
                          return (
                            <td
                              key={`cell-${rowIndex}-${cellIndex}`}
                              {...cell.getCellProps()}
                            >
                              <div className='statement-info'>
                                <div className='edit-action'>
                                  <ul className='edit-list'>
                                    <li>
                                      <img
                                        className='logo-thumbnail'
                                        src={cell.value}
                                        alt='Logo'
                                      />
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </td>
                          )
                        }
                        if (cell.column.id === 'status') {
                          return (
                            <td
                              key={`cell-${rowIndex}-${cellIndex}`}
                              {...cell.getCellProps()}
                            >
                              <div className='statement-info'>
                                <span
                                  className={`badge bg-${
                                    cell.value === 'active'
                                      ? 'success'
                                      : 'danger'
                                  } text-white p-1`}
                                >
                                  {cell.value === 'active'
                                    ? 'Ativo'
                                    : 'Inativo'}
                                </span>
                              </div>
                            </td>
                          )
                        }
                        return (
                          <td
                            key={`cell-${rowIndex}-${cellIndex}`}
                            {...cell.getCellProps()}
                          >
                            <div className='statement-info'>
                              {cell.render('Cell')}
                            </div>
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className='page-navigation-wrap mt-4 mb-4'>
            <div className='page-navigation-inner d-inline-block'>
              <div className='page-navigation'>
                <button
                  type='button'
                  className='btn page-go page-prev'
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <i className='la la-arrow-left icon-table'></i>
                </button>
                <ul className='page-navigation-nav'>
                  <li>
                    <span>
                      Página <strong>{pageIndex + 1}</strong>{' '}
                    </span>
                  </li>
                </ul>
                <button
                  type='button'
                  className='btn page-go page-next'
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  <i className='la la-arrow-right icon-table'></i>
                </button>
                <div className='page-size'>
                  <label>Itens por página: </label>
                  <div className='select-input sort-ordering user-form-short'>
                    <select
                      className='sort-ordering-select-no-search'
                      value={pageSize}
                      onChange={e => {
                        setPageSize(Number(e.target.value))
                      }}
                    >
                      {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          {pageSize}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Aviso!'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Tem certeza que deseja excluir este registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className='edit-action'>
            <ul className='edit-list'>
              <li>
                <button className='theme-btn edit-btn' onClick={handleClose}>
                  cancelar
                </button>
              </li>
              <li>
                <button className='theme-btn delete-btn' onClick={deleteClient}>
                  Excluir
                </button>
              </li>
            </ul>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ClientsTable
