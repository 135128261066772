import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import '../features/editClients/editClient.css'
import $ from 'jquery'
import Message from '../utils/messages/message'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import InputText from '../utils/form/inputText'
import InputMask from '../utils/form/inputMask'
import SelectInput from '../utils/form/selectInput'
import UploadLogo from '../features/editClients/uploadLogo'
import HttpRequests from '../api/httpRequests'
import { PermissionAgent } from '../features/permissions/permissionAgent'

function NewClient() {
  let { uuid } = useParams()
  let history = useHistory()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [msgType, setMsgType] = useState('')

  const retrieveClient = async () => {
    $('.preloader').show()
    try {
      const response = await HttpRequests.retrieveClient(uuid)
      setData(response.data.data)
      setLoading(false)
    } catch {
      history.push('/error_500')
    } finally {
      $('.preloader').delay('500').fadeOut(1000)
    }
  }

  useEffect(() => {
    window.$('.sort-ordering-select-no-search').selectpicker()
    if (loading === true) {
      retrieveClient(uuid)
    } else {
      $('.preloader').delay('500').fadeOut(1000)
    }
  })

  if (loading === false) {
    return (
      <>
        <Formik
          initialValues={{
            name: data.name,
            phone: data.phone ? data.phone : '',
            email: data.email ? data.email : '',
            status: data.status
          }}
          validationSchema={Yup.object({
            name: Yup.string()
              .min(2, 'Deve ter entre 2 e 80 caracteres')
              .max(80, 'Deve ter entre 2 e 80 caracteres')
              .required('Campo obrigatório'),
            email: Yup.string()
              .max(80, 'Deve no máximo 80 caracteres')
              .email('E-mail inválido'),
            phone: Yup.string()
              .transform(value => value.replace(/[^\d]/g, ''))
              .min(13, 'Digite um número de telefone válido')
              .max(14, 'Digite um número de telefone válido'),
            status: Yup.string().required('Campo obrigatório')
          })}
          onSubmit={async (values, { setSubmitting }) => {
            $('.preloader').show()

            try {
              let data = {
                name: values.name,
                status: values.status,
                email: values.email !== '' ? values.email : null,
                phone:
                  values.phone !== ''
                    ? values.phone.replace(/[^\d.]/g, '')
                    : null
              }

              await HttpRequests.patchClient(data, uuid)
              setSubmitting(false)
              setMsgType('insert-successful')
              history.push('/clients')
            } catch (error) {
              switch (error.response.status) {
                case 401:
                  setMsgType('401')
                  break

                case 403:
                  setMsgType('403')
                  break

                case 409:
                  setMsgType('409')
                  break

                default:
                  setMsgType('500')
              }

              window.scrollTo(0, 0)
            } finally {
              $('.preloader').delay('500').fadeOut(1000)
            }
          }}
        >
          <Form>
            <div className='row mt-5'>
              <div className='col-lg-12'>
                <Message msgType={msgType} />
                <div className='card-box-shared'>
                  <div className='card-box-shared-title'>
                    <h3 className='widget-title'>Editar Cliente</h3>
                  </div>
                  <div className='card-box-shared-body'>
                    <div className='user-form'>
                      <div className='contact-form-action'>
                        <div className='row'>
                          <div className='col-md-6'>
                            <div className='row'>
                              <div className='col-lg-12 col-sm-12'>
                                <InputText
                                  label='Nome do Cliente'
                                  name='name'
                                  type='text'
                                />
                              </div>
                              <div className='col-lg-12 col-sm-12'>
                                <InputText
                                  label='E-mail'
                                  name='email'
                                  type='text'
                                />
                              </div>
                              <div className='col-lg-12 col-sm-12'>
                                <InputMask
                                  label='Telefone'
                                  name='phone'
                                  type='text'
                                  mask={[
                                    '+',
                                    /[1-9]/,
                                    /\d/,
                                    ' ',
                                    '(',
                                    /[1-9]/,
                                    /\d/,
                                    ')',
                                    ' ',
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/
                                  ]}
                                />
                              </div>
                              <div className='col-lg-12 col-sm-12'>
                                <SelectInput
                                  label='Status'
                                  name='status'
                                  className='sort-ordering-select-no-search'
                                >
                                  <option value='active'>Ativo</option>
                                  <option value='inactive'>Inativo</option>
                                </SelectInput>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <PermissionAgent
                              resource={'client'}
                              scope={'logo'}
                              Component={() => (
                                <UploadLogo
                                  client_id={uuid}
                                  setMsg=''
                                  logo={data.logo}
                                  setMsgType={setMsgType}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12'>
                <button className='theme-btn' type='submit'>
                  Salvar
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      </>
    )
  }
  return <div></div>
}

export default NewClient
