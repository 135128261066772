import React from 'react'
import { useKeycloak } from '@react-keycloak/web'

const AlertMessage = props => {
  const [msgType, setMsgType] = React.useState('')
  const { keycloak } = useKeycloak()
  let cssClass = ''
  let msg = ''

  switch (msgType) {
    case '204':
      cssClass = 'alert alert-success'
      msg = 'Reaplicação efetuada com sucesso!.'
      break

    case '401':
      keycloak.logout()
      break

    case '403':
      cssClass = 'alert alert-danger'
      msg = 'Você não possui permissão para está operação.'
      break

    case '404':
      cssClass = 'alert alert-danger'
      msg = 'Este recurso não foi encontrado.'
      break

    case '409':
      cssClass = 'alert alert-danger'
      msg = 'Essa operação já foi realizada.'
      break

    case '424':
      cssClass = 'alert alert-danger'
      msg = 'Uma ou mais dependências não são válidas.'
      break

    case '500':
      cssClass = 'alert alert-danger'
      msg = 'Ocorreu um erro interno ao processar a solicitação.'
      break

    default:
      msg = ''
      break
  }

  React.useEffect(() => {
    setMsgType(props.msgType)
  }, [props.msgType])

  return (
    <div className={cssClass + ' alert-dismissible fade show'} role='alert'>
      {msg}
    </div>
  )
}
export default AlertMessage
