import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import $ from 'jquery'
import '../features/applications/applications.css'
import TablePaginated from '../features/applications/tablePaginated'
import HttpRequests from '../api/httpRequests'
import { useKeycloak } from '@react-keycloak/web'

export default function Applications() {
  let history = useHistory()
  let { uuid } = useParams()
  const { keycloak } = useKeycloak()

  const columns = React.useMemo(
    () => [
      {
        Header: 'Candidato',
        accessor: 'candidate_name'
      },
      {
        Header: 'E-mail',
        accessor: 'candidate_email'
      },
      {
        Header: 'Data de Início',
        accessor: 'start_date'
      },
      {
        Header: 'Data de Término',
        accessor: 'end_date'
      },
      {
        Header: 'Tempo Gasto',
        accessor: 'total_time'
      },
      {
        Header: 'Score',
        accessor: 'score'
      },
      {
        Header: 'Status',
        accessor: 'status'
      },
      {
        Header: '',
        accessor: 'uuid'
      }
    ],
    []
  )

  const [data, setData] = React.useState([])
  const fetchIdRef = React.useRef(0)

  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex, filters }) => {
      const fetchId = ++fetchIdRef.current

      try {
        let data = {
          per_page: pageSize,
          page: pageIndex + 1,
          quiz: uuid
        }

        if (filters !== undefined) {
          if (
            filters.candidate_name !== null ||
            filters.candidate_name !== ''
          ) {
            data.candidate_name = filters.candidate_name
          }

          if (
            filters.candidate_email !== null ||
            filters.candidate_email !== ''
          ) {
            data.candidate_email = filters.candidate_email
          }
        }

        const response = await HttpRequests.listApplications(data)

        if (fetchId === fetchIdRef.current) {
          setData(response.data.data)
        }
      } catch (error) {
        if (error.response) {
          switch (error.response.status) {
            case 401:
              keycloak.logout()
              break

            case 403:
              history.push('/error/403')
              break

            case 404:
              history.push('/error/404')
              break

            default:
              history.push('/error/500')
          }
        }
      } finally {
        $('.preloader').delay('250').fadeOut(500)
      }
    },
    []
  )

  return <TablePaginated columns={columns} data={data} fetchData={fetchData} />
}
