import React from 'react'
import { Link } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import AuthServiceContext from '../api/authServiceContext'

import './header.css'

const Header = () => {
  const permissions = React.useContext(AuthServiceContext)
  const { keycloak } = useKeycloak()
  const userInfo = JSON.parse(localStorage.getItem('user_info'))

  return (
    <>
      <header className='header-menu-area dashboard-header'>
        <div className='header-menu-content dashboard-menu-content'>
          <div className='container-fluid'>
            <div className='main-menu-content'>
              <div className='row align-items-center'>
                <div className='col-lg-2'>
                  <div className='logo-box'>
                    <Link to='/'>
                      <img
                        className='custom-logo'
                        src={
                          permissions.clientInfo !== null &&
                          permissions.clientInfo.logo !== null
                            ? permissions.clientInfo.logo
                            : '/static/images/rocketmat-logo.png'
                        }
                        alt='logo'
                      />
                    </Link>
                    <div className='menu-toggler'>
                      <i className='la la-bars'></i>
                      <i className='la la-times'></i>
                    </div>
                  </div>
                </div>
                <div className='col-lg-10'>
                  <div className='menu-wrapper'>
                    <div className='logo-right-button d-flex align-items-center'>
                      <div className='header-action-button d-flex align-items-center'>
                        <div className='notification-wrap d-flex align-items-center'>
                          <div className='notification-item mr-3'>
                            <div className='dropdown'>
                              <div
                                className='dropdown-menu'
                                aria-labelledby='notificationDropdownMenu'
                              >
                                <div className='mess-dropdown'>
                                  <div className='mess__title'>
                                    <h4 className='widget-title'>Ajuda</h4>
                                  </div>
                                  <div className='mess__body'>
                                    <a
                                      href='dashboard.html'
                                      className='d-block'
                                    >
                                      <div className='mess__item'>
                                        <div className='icon-element bg-color-1 text-white'>
                                          <i className='la la-bolt'></i>
                                        </div>
                                        <div className='content'>
                                          <span className='time'>
                                            1 hour ago
                                          </span>
                                          <p className='text'>
                                            Your Resume Updated!
                                          </p>
                                        </div>
                                      </div>
                                    </a>
                                    <a
                                      href='dashboard.html'
                                      className='d-block'
                                    >
                                      <div className='mess__item'>
                                        <div className='icon-element bg-color-2 text-white'>
                                          <i className='la la-lock'></i>
                                        </div>
                                        <div className='content'>
                                          <span className='time'>
                                            November 12, 2019
                                          </span>
                                          <p className='text'>
                                            You changed password
                                          </p>
                                        </div>
                                      </div>
                                    </a>
                                    <a
                                      href='dashboard.html'
                                      className='d-block'
                                    >
                                      <div className='mess__item'>
                                        <div className='icon-element bg-color-3 text-white'>
                                          <i className='la la-check-circle'></i>
                                        </div>
                                        <div className='content'>
                                          <span className='time'>
                                            October 6, 2019
                                          </span>
                                          <p className='text'>
                                            You applied for a job{' '}
                                            <span className='color-text'>
                                              Front-end Developer
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </a>
                                    <a
                                      href='dashboard.html'
                                      className='d-block'
                                    >
                                      <div className='mess__item'>
                                        <div className='icon-element bg-color-4 text-white'>
                                          <i className='la la-user'></i>
                                        </div>
                                        <div className='content'>
                                          <span className='time'>
                                            Jun 12, 2019
                                          </span>
                                          <p className='text'>
                                            Your account has been created
                                            successfully
                                          </p>
                                        </div>
                                      </div>
                                    </a>
                                    <a
                                      href='dashboard.html'
                                      className='d-block'
                                    >
                                      <div className='mess__item'>
                                        <div className='icon-element bg-color-5 text-white'>
                                          <i className='la la-download'></i>
                                        </div>
                                        <div className='content'>
                                          <span className='time'>
                                            May 12, 2019
                                          </span>
                                          <p className='text'>
                                            Someone downloaded resume
                                          </p>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                  <div className='btn-box p-2 text-center'>
                                    <a href='dashboard.html'>
                                      Show All Notifications
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='user-action-wrap'>
                          <div className='notification-item user-action-item'>
                            <div className='dropdown'>
                              <button
                                className='notification-btn icon-color dropdown-toggle'
                                type='button'
                                id='userDropdownMenu'
                                data-toggle='dropdown'
                                aria-haspopup='true'
                                aria-expanded='false'
                              >
                                <i className='la la-gear'></i>
                              </button>
                              <div
                                className='dropdown-menu'
                                aria-labelledby='userDropdownMenu'
                              >
                                <div className='mess-dropdown'>
                                  <div className='mess__title d-flex align-items-center'>
                                    <div className='content'>
                                      <h4 className='widget-title font-size-16'>
                                        <a href='#' className='text-white'>
                                          {userInfo.firstName +
                                            ' ' +
                                            userInfo.lastName}
                                        </a>
                                      </h4>
                                    </div>
                                  </div>
                                  <div className='mess__body'>
                                    <ul className='list-items'>
                                      <li className='mb-0'>
                                        <a
                                          href='#'
                                          className='d-block'
                                          onClick={() => keycloak.logout()}
                                        >
                                          <i className='la la-power-off'></i>{' '}
                                          Logout
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
