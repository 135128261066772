import React from 'react'
import { Link, NavLink, useHistory, useParams } from 'react-router-dom'
import { useTable, usePagination } from 'react-table'
import './tablePaginated.css'
import $ from 'jquery'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Message from '../../utils/messages/message'
import HttpRequests from '../../api/httpRequests'
import SwitchButton from '../../utils/buttons/switch'
import PreloaderLight from '../../template/preloaderLight'
import PopoverHelp from '../../utils/buttons/popoverHelp'
import { PermissionAgent } from '../permissions/permissionAgent'

function TablePaginated({ columns, data, fetchData }) {
  let history = useHistory()
  let { uuid } = useParams()
  const [loading, setloading] = React.useState(false)

  // BEGIN: Modal
  const [open, setOpen] = React.useState(false)
  const [setRowUuid] = React.useState('')

  const handleClose = () => {
    setOpen(false)
    setRowUuid('')
  }
  // END: Modal

  const deactivate = async user_uuid => {
    setloading(true)
    try {
      await HttpRequests.patchUser({ status: 'inactive' }, user_uuid)
      setOpen(false)
      fetchData({ pageIndex, pageSize })
    } catch (error) {
      switch (error.response.status) {
        case 401:
          setMsgType('401')
          break

        case 403:
          setMsgType('403')
          break

        case 406:
          setMsgType('406')
          break

        case 409:
          setMsgType('409')
          break

        default:
          setMsgType('500')
      }

      window.scrollTo(0, 0)
    } finally {
      setloading(false)
    }
  }

  const activate = async user_uuid => {
    setloading(true)
    try {
      await HttpRequests.patchUser({ status: 'active' }, user_uuid)
      setOpen(false)
      fetchData({ pageIndex, pageSize })
    } catch {
      history.push('/error_500')
    } finally {
      setloading(false)
    }
  }

  const handleSwitchStatus = (event, user_uuid) => {
    switch (event.target.checked) {
      case true:
        activate(user_uuid)
        break
      case false:
        deactivate(user_uuid)
        break
    }
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: -1
    },
    usePagination
  )

  const [msgType, setMsgType] = React.useState('')

  React.useEffect(() => {
    $('.preloader').show()
    window.$('.sort-ordering-select-no-search').selectpicker()
    fetchData({ pageIndex, pageSize, uuid })
  }, [fetchData, pageIndex, pageSize])

  return (
    <div>
      {loading === true ? <PreloaderLight /> : ''}
      <div className='card-box-shared'>
        <div className='card-box-shared-title'>
          <h3 className='widget-title'>Usuários</h3>
          <PermissionAgent
            resource={'user'}
            scope={'create'}
            Component={() => (
              <NavLink
                className='theme-btn btn-add-client'
                to={'/users/create'}
              >
                Adicionar
              </NavLink>
            )}
          />
        </div>
        <div className='card-box-shared-body'>
          <Message msgType={msgType} />
          <div className='statement-table purchase-table table-responsive mb-5'>
            <table className='table' {...getTableProps()}>
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr
                    key={`${headerGroup.id}-${Math.random()
                      .toString(36)
                      .slice(2)}`}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map(column => {
                      if (column.id === 'role') {
                        return (
                          <th
                            key={`${column.id}-${Math.random()
                              .toString(36)
                              .slice(2)}`}
                            className=''
                            scope='col'
                            {...column.getHeaderProps()}
                          >
                            <div className='popover-help'>
                              {column.render('Header')}
                              <PopoverHelp content='Somente o Administrador pode adicionar usuários' />
                            </div>
                          </th>
                        )
                      }

                      return (
                        <th
                          key={`${column.id}-${Math.random()
                            .toString(36)
                            .slice(2)}`}
                          className=''
                          scope='col'
                          {...column.getHeaderProps()}
                        >
                          <div className='popover-help'>
                            {column.render('Header')}
                          </div>
                        </th>
                      )
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map(row => {
                  prepareRow(row)
                  return (
                    <tr
                      key={`${row.id}-${Math.random().toString(36).slice(2)}`}
                      {...row.getRowProps()}
                    >
                      {row.cells.map(cell => {
                        if (cell.column.id == 'uuid') {
                          return (
                            <td
                              key={`${cell.column.id}-${Math.random()
                                .toString(36)
                                .slice(2)}`}
                              style={{ width: '100px' }}
                              {...cell.getCellProps()}
                            >
                              <div className='statement-info'>
                                <div className='edit-action'>
                                  <ul className='edit-list action-btn'>
                                    <PermissionAgent
                                      resource={'user'}
                                      scope={'retrieve'}
                                      Component={() => (
                                        <li>
                                          <Link
                                            className='theme-btn edit-btn'
                                            to={'/users/' + cell.value}
                                            title='Editar'
                                          >
                                            <i className='no-description la la-edit mr-1 font-size-16'></i>
                                          </Link>
                                        </li>
                                      )}
                                    />
                                  </ul>
                                </div>
                              </div>
                            </td>
                          )
                        }
                        if (cell.column.id == 'role') {
                          let role = ''

                          switch (cell.value) {
                            case 'admin':
                              role = 'Administrador do Sistema'
                              break

                            case 'client_admin':
                              role = 'Administrador'
                              break

                            case 'client_recruiter':
                              role = 'Recrutador'
                              break

                            case 'client_creator':
                              role = 'Criador de Provas'
                              break
                          }

                          return (
                            <td
                              key={`${cell.column.id}-${Math.random()
                                .toString(36)
                                .slice(2)}`}
                              {...cell.getCellProps()}
                            >
                              <div className='statement-info'>
                                <ul className='edit-list action-btn'>{role}</ul>
                              </div>
                            </td>
                          )
                        }
                        if (cell.column.id == 'status') {
                          if (cell.value == 'active') {
                            return (
                              <td
                                key={`${cell.column.id}-${Math.random()
                                  .toString(36)
                                  .slice(2)}`}
                                style={{ width: '50px' }}
                                {...cell.getCellProps()}
                              >
                                <div className='statement-info'>
                                  <SwitchButton
                                    checked={
                                      cell.value === 'active' ? true : false
                                    }
                                    onChange={e =>
                                      handleSwitchStatus(
                                        e,
                                        row.allCells[3].value
                                      )
                                    }
                                    name={row.allCells[2].value}
                                  />
                                </div>
                              </td>
                            )
                          } else {
                            return (
                              <td
                                key={`${cell.column.id}-${Math.random()
                                  .toString(36)
                                  .slice(2)}`}
                                style={{ width: '50px' }}
                                {...cell.getCellProps()}
                              >
                                <div className='statement-info'>
                                  <SwitchButton
                                    checked={
                                      cell.value === 'active' ? true : false
                                    }
                                    onChange={e =>
                                      handleSwitchStatus(
                                        e,
                                        row.allCells[3].value
                                      )
                                    }
                                    name={row.allCells[2].value}
                                  />
                                </div>
                              </td>
                            )
                          }
                        }
                        return (
                          <td
                            key={`${cell.column.id}-${Math.random()
                              .toString(36)
                              .slice(2)}`}
                            {...cell.getCellProps()}
                          >
                            <div className='statement-info'>
                              {cell.render('Cell')}
                            </div>
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className='page-navigation-wrap mt-4 mb-4'>
            <div className='page-navigation-inner d-inline-block'>
              <div className='page-navigation'>
                <button
                  type='button'
                  className='btn page-go page-prev'
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <i className='la la-arrow-left icon-table'></i>
                </button>
                <ul className='page-navigation-nav'>
                  <li>
                    <span>
                      Página <strong>{pageIndex + 1}</strong>{' '}
                    </span>
                  </li>
                </ul>
                <button
                  type='button'
                  className='btn page-go page-next'
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  <i className='la la-arrow-right icon-table'></i>
                </button>
                <div className='page-size'>
                  <label>Itens por página: </label>
                  <div className='select-input sort-ordering user-form-short'>
                    <select
                      className='sort-ordering-select-no-search'
                      value={pageSize}
                      onChange={e => {
                        setPageSize(Number(e.target.value))
                      }}
                    >
                      {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          {pageSize}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Aviso!'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Tem certeza que deseja desativar este registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className='edit-action'>
            <ul className='edit-list'>
              <li>
                <button className='theme-btn edit-btn' onClick={handleClose}>
                  cancelar
                </button>
              </li>
              <li>
                <button className='theme-btn delete-btn' onClick={deactivate}>
                  Desativar
                </button>
              </li>
            </ul>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default TablePaginated
