import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import $ from 'jquery'
import '../features/candidateApplications/candidateApplications.css'
import TablePaginated from '../features/candidateApplications/tablePaginated'
import HttpRequests from '../api/httpRequests'
import { useKeycloak } from '@react-keycloak/web'

export default function CandidateApplications() {
  let history = useHistory()
  let { keycloak_id } = useParams()
  const { keycloak } = useKeycloak()

  const columns = React.useMemo(
    () => [
      {
        Header: 'Prova',
        accessor: 'quiz_title'
      },
      {
        Header: 'Data de Início',
        accessor: 'start_date'
      },
      {
        Header: 'Data de Término',
        accessor: 'end_date'
      },
      {
        Header: 'Tempo Gasto',
        accessor: 'total_time'
      },
      {
        Header: 'Score',
        accessor: 'score'
      },
      {
        Header: 'Status',
        accessor: 'status'
      }
    ],
    []
  )

  const [data, setData] = React.useState([])
  const [candidateInfo, setCandidateInfo] = React.useState({})
  const fetchIdRef = React.useRef(0)

  const fetchData = React.useCallback(async ({ pageSize, pageIndex }) => {
    const fetchId = ++fetchIdRef.current

    try {
      const response = await HttpRequests.listCandidateApplications(
        {
          per_page: pageSize,
          page: pageIndex + 1
        },
        keycloak_id
      )

      if (fetchId === fetchIdRef.current) {
        setData(response.data.data)
        setCandidateInfo({
          candidate_name: response.data.candidate_name,
          candidate_email: response.data.candidate_email
        })
      }
    } catch (error) {
      switch (error.response.status) {
        case 401:
          keycloak.logout()
          break

        case 403:
          history.push('/error/403')
          break

        case 404:
          history.push('/error/404')
          break

        default:
          history.push('/error/500')
      }
    } finally {
      $('.preloader').delay('250').fadeOut(500)
    }
  }, [])

  return (
    <TablePaginated
      columns={columns}
      data={data}
      candidateInfo={candidateInfo}
      fetchData={fetchData}
    />
  )
}
