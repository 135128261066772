import React, { useEffect, useState } from 'react'
import RoundButtonAdd from '../../utils/buttons/roundButtonAdd'
import RoundButtonDelete from '../../utils/buttons/roundButtonDelete'
import InputText from '../inputText/inputText'
import SelectInput from '../selectInput/selectInput'
import HttpRequests from '../../api/httpRequests'
import Message from '../../utils/messages/message'
import './answers.css'
import UploadImageAnswer from '../questions/uploadImageAnswer/uploadImage'

export default function Answers(props) {
  const [loading, setLoading] = useState(true)
  const [msgType, setMsgType] = useState('')
  const [answers, setAnswers] = useState(null)
  const [btnAddBlock, setBtnAddBlock] = useState(false)
  const letters = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z'
  ]

  const [state, setState] = useState({
    answerType: ''
  })

  const listAnswers = async () => {
    try {
      const response = await HttpRequests.listAnswers({
        question: props.questionField.uuid
      })

      if (response.data.data.length > 0) {
        let answerType =
          response.data.data[0].image === null ||
          response.data.data[0].image === ''
            ? 'text'
            : 'image'

        setAnswers(response.data.data)

        setState({
          ...state,
          answerType: answerType
        })
      } else {
        setAnswers([])
        setState({
          ...state,
          answerType: 'text'
        })
      }
      setLoading(false)
    } catch {
      setMsgType('internal-error')
      setLoading(false)
    }
  }

  const addAnswer = async () => {
    if (answers.length < 10) {
      setBtnAddBlock(false)

      try {
        const answersTemp = [...answers]

        const response = await HttpRequests.createAnswer({
          right_answer: false,
          question: props.questionField.uuid,
          order: letters[answers.length]
        })

        answersTemp.push({ ...response.data.data })

        setAnswers(answersTemp)
        setBtnAddBlock(false)
      } catch (error) {
        let errorMsg = ''

        switch (error.response.status) {
          case 401:
            errorMsg = '401'
            break

          case 403:
            errorMsg = '403'
            break

          case 406:
            errorMsg = '406'
            break

          case 409:
            errorMsg = '409'
            break

          case 412:
            errorMsg = '412'
            break

          case 423:
            errorMsg = '423'
            break

          case 424:
            errorMsg = '424'
            break

          default:
            errorMsg = '500'
        }
        setMsgType(errorMsg)
      }
    }
  }

  const deleteAnswer = async i => {
    const answersTemp = [...answers]
    answersTemp.splice(i, 1)
    setAnswers(answersTemp)

    try {
      if (!answers[i].uuid === undefined || !answers[i].uuid == '') {
        await HttpRequests.deleteAnswer(answers[i].uuid)
      }
    } catch (error) {
      let errorMsg = ''

      switch (error.response.status) {
        case 401:
          errorMsg = '401'
          break

        case 403:
          errorMsg = '403'
          break

        case 406:
          errorMsg = '406'
          break

        case 409:
          errorMsg = '409'
          break

        case 412:
          errorMsg = '412'
          break

        case 423:
          errorMsg = '423'
          break

        case 424:
          errorMsg = '424'
          break

        default:
          errorMsg = '500'
      }

      setMsgType(errorMsg)
    }
  }

  const handleAnswerType = e => {
    setState({ ...state, answerType: e.target.value })
  }

  const handleDescription = (e, i) => {
    const answersTemp = [...answers]
    answersTemp[i] = { ...answersTemp[i], description: e.target.value }
    setAnswers(answersTemp)
  }

  const handleRightAnswer = async (e, i) => {
    const answersTemp = [...answers]
    answersTemp[i] = { ...answersTemp[i], right_answer: e.target.value }
    setAnswers(answersTemp)

    try {
      if (answers[i].uuid === undefined || answers[i].uuid == '') {
        await HttpRequests.createAnswer({
          description: answers[i].description,
          right_answer: e.target.value,
          question: props.questionField.uuid
        })
      } else {
        await HttpRequests.patchAnswer(
          {
            description: answers[i].description,
            right_answer: e.target.value
          },
          answers[i].uuid
        )
      }
    } catch (error) {
      let errorMsg = ''

      switch (error.response.status) {
        case 401:
          errorMsg = '401'
          break

        case 403:
          errorMsg = '403'
          break

        case 406:
          errorMsg = '406'
          break

        case 409:
          errorMsg = '409'
          break

        case 412:
          errorMsg = '412'
          break

        case 423:
          errorMsg = '423'
          break

        case 424:
          errorMsg = '424'
          break

        default:
          errorMsg = '500'
      }

      setMsgType(errorMsg)
    }
  }

  const handleOnBlur = async (e, i) => {
    setMsgType('')

    try {
      if (answers[i].image === '' || answers[i].image === null) {
        if (answers[i].uuid === undefined || answers[i].uuid == '') {
          await HttpRequests.createAnswer({
            description: answers[i].description,
            right_answer: answers[i].right_answer,
            question: props.questionField.uuid
          })
        } else {
          await HttpRequests.patchAnswer(
            {
              description: answers[i].description,
              right_answer: answers[i].right_answer
            },
            answers[i].uuid
          )
        }
      }
    } catch (error) {
      let errorMsg = ''

      switch (error.response.status) {
        case 401:
          errorMsg = '401'
          break

        case 403:
          errorMsg = '403'
          break

        case 406:
          errorMsg = '406'
          break

        case 409:
          errorMsg = '409'
          break

        case 412:
          errorMsg = '412'
          break

        case 423:
          errorMsg = '423'
          break

        case 424:
          errorMsg = '424'
          break

        default:
          errorMsg = '500'
      }

      setMsgType(errorMsg)
    }
  }

  useEffect(() => {
    if (loading === true) {
      listAnswers()
    }
    window.$('.sort-ordering-select-no-search').selectpicker()
  }, [])

  return (
    <>
      {!loading && (
        <div>
          <div className='row'>
            {props.questionField.type === 'multiple_choice' ? (
              <div className='col-lg-3 col-sm-3'>
                <SelectInput
                  value={state.answerType}
                  label='Tipo de resposta'
                  name={`questionFields.${props.key}.answerType`}
                  className='sort-ordering-select-no-search'
                  onChange={e => handleAnswerType(e)}
                >
                  <option value={'text'}>Texto</option>
                  <option value='image'>Imagem</option>
                </SelectInput>
              </div>
            ) : (
              ''
            )}
          </div>
          {props.questionField.type === 'multiple_choice' ? (
            <div className='row'>
              <div className='col-md-12'>
                <div className='input-box'>
                  <h3 className='widget-title'>Respostas</h3>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          <div className='status-message'>
            <Message msgType={msgType} />
          </div>
          {props.questionField.type === 'multiple_choice' &&
          props.questionField.answers
            ? answers.map((answer, i) => {
                if (state.answerType === 'text' || !state.answerType) {
                  return (
                    <div className='row' key={`answer-text-${i}`}>
                      <div className='answer-line'>
                        <span className='answer-letter'>
                          {answers[i].order ? answers[i].order : letters[i]}
                        </span>
                        <div className='col-lg-9 col-sm-9'>
                          <InputText
                            label='Descrição'
                            name={`questionFields.${props.key}.answers.description`}
                            type='text'
                            value={answers[i].description}
                            onChange={e => handleDescription(e, i)}
                            onBlur={e => handleOnBlur(e, i)}
                          />
                        </div>
                        <div className='col-lg-2 col-sm-2'>
                          <SelectInput
                            value={answers[i].right_answer}
                            label='Resposta correta'
                            name={`questionFields.${props.key}.answers.rightAnswer`}
                            className='sort-ordering-select-no-search'
                            onChange={e => handleRightAnswer(e, i)}
                          >
                            <option value={false}>Falsa</option>
                            <option value={true}>Verdadeira</option>
                          </SelectInput>
                        </div>
                        <div className='col-lg-1 col-sm-1'>
                          <div className='btn-remove-question'>
                            <RoundButtonDelete
                              size='small'
                              onClick={() => deleteAnswer(i)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }

                return (
                  <div className='row' key={`answer-image-${i}`}>
                    <div className='answer-line-image-upload'>
                      <span className='answer-letter'>{answers[i].order}</span>
                      <div className='answer-image-upload'>
                        <UploadImageAnswer
                          uuid={answers[i].uuid}
                          setMsg=''
                          logo={
                            answers[i].image && answers[i].image !== ''
                              ? answers[i].image
                              : null
                          }
                        />
                      </div>
                      <div className='right-answer-image-upload'>
                        <SelectInput
                          value={answers[i].right_answer}
                          label='Resposta correta'
                          name={`questionFields.${props.key}.answers.rightAnswer`}
                          className='sort-ordering-select-no-search'
                          onChange={e => handleRightAnswer(e, i)}
                        >
                          <option value={false}>Falsa</option>
                          <option value={true}>Verdadeira</option>
                        </SelectInput>
                      </div>
                      <div className=''>
                        <div className='btn-remove-image-upload'>
                          <RoundButtonDelete
                            size='small'
                            onClick={() => deleteAnswer(i)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            : ''}

          {props.questionField.type === 'multiple_choice' ? (
            <div className='row'>
              <div className='col-md-12'>
                <div className='input-box'>
                  <div className='form-group'>
                    {btnAddBlock === false ? (
                      <RoundButtonAdd
                        size='small'
                        onClick={() => addAnswer()}
                      />
                    ) : (
                      <RoundButtonAdd size='small' />
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      )}
    </>
  )
}
