import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import $ from 'jquery'
import Message from '../utils/messages/message'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import InputText from '../utils/form/inputText'
import SelectInput from '../utils/form/selectInput'
import HttpRequests from '../api/httpRequests'
import '../features/newUser/newUser.css'
import AuthServiceContext from '../api/authServiceContext'

function NewUser() {
  let history = useHistory()
  const permissions = useContext(AuthServiceContext)
  const [loading, setLoading] = useState(true)
  const [msgType, setMsgType] = useState('')
  const [clients, setClients] = useState([])
  const [clientInput, setClientInput] = useState(false)

  const listClients = async () => {
    $('.preloader').show()

    try {
      const response = await HttpRequests.listClients()
      setClients(response.data.data)

      setLoading(false)
    } catch {
      setMsgType('error-load-content')
      history.push('/error_500')
    } finally {
      $('.preloader').delay('500').fadeOut(1000)
    }
  }

  const handleRole = (e, values, setValues) => {
    if (e.target.value !== 'admin' && permissions.userRole === 'admin') {
      setClientInput(true)

      setValues({
        ...values,
        isClient: true,
        client: clients.length > 0 ? clients[0].uuid : '',
        role: e.target.value
      })
    } else {
      setClientInput(false)

      let currentValues = { ...values }
      delete currentValues.clients

      currentValues = {
        ...currentValues,
        isClient: false,
        role: e.target.value
      }

      setValues({ ...values, ...currentValues })
    }
  }

  useEffect(() => {
    window.$('.sort-ordering-select-no-search').selectpicker()
    if (loading === true) {
      $('.preloader').show()

      if (permissions.userRole === 'admin') {
        listClients()
      } else {
        setLoading(false)
      }
    } else {
      $('.preloader').delay('500').fadeOut(1000)
    }
  })

  const initialValues = () => {
    let values = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      role: '',
      status: 'active'
    }

    return values
  }

  if (loading === false) {
    return (
      <>
        <Formik
          initialValues={initialValues()}
          validationSchema={Yup.object({
            firstName: Yup.string()
              .min(2, 'Deve ter entre 2 e 80 caracteres')
              .max(80, 'Deve ter entre 2 e 80 caracteres')
              .required('Campo obrigatório'),
            lastName: Yup.string()
              .min(2, 'Deve ter entre 2 e 80 caracteres')
              .max(80, 'Deve ter entre 2 e 80 caracteres')
              .required('Campo obrigatório'),
            email: Yup.string()
              .max(80, 'Deve no máximo 80 caracteres')
              .email('E-mail inválido')
              .required('Campo obrigatório'),
            password: Yup.string()
              .min(6, 'Deve ter entre 6 e 255 caracteres')
              .max(255, 'Deve ter entre 6 e 255 caracteres')
              .required('Campo obrigatório'),
            passwordConfirmation: Yup.string().oneOf(
              [Yup.ref('password'), null],
              'As senhas devem ser iguais'
            ),
            role: Yup.string().required('Campo obrigatório'),
            isClient: Yup.boolean(),
            client: Yup.string().when('isClient', {
              is: true,
              then: Yup.string().required('Campo obrigatório'),
              otherwise: Yup.string()
            }),
            status: Yup.string().required('Campo obrigatório')
          })}
          onSubmit={async (values, { setSubmitting }) => {
            $('.preloader').show()

            try {
              let valuesData = {
                first_name: values.firstName,
                last_name: values.lastName,
                email: values.email,
                password: values.password,
                role: values.role,
                status: values.status
              }

              if (values.role !== 'admin' && permissions.userRole === 'admin') {
                valuesData = { ...valuesData, client: values.client }
              } else {
                delete valuesData.client
              }

              await HttpRequests.createUser(valuesData)

              setSubmitting(false)
              setMsgType('insert-successful')
              history.push('/users')
            } catch (error) {
              switch (error.response.status) {
                case 401:
                  setMsgType('401')
                  break

                case 403:
                  setMsgType('403')
                  break

                case 406:
                  setMsgType('406')
                  break

                case 409:
                  setMsgType('409')
                  break

                default:
                  setMsgType('500')
              }

              window.scrollTo(0, 0)
            } finally {
              $('.preloader').delay('500').fadeOut(1000)
            }
          }}
        >
          {({ values, setValues }) => (
            <Form>
              <div className='row mt-5'>
                <div className='col-lg-12'>
                  <Message msgType={msgType} />
                  <div className='card-box-shared'>
                    <div className='card-box-shared-title'>
                      <h3 className='widget-title'>Cadastrar Novo Usuário</h3>
                    </div>
                    <div className='card-box-shared-body'>
                      <div className='user-form'>
                        <div className='contact-form-action'>
                          <div className='row'>
                            <div className='col-lg-4 col-sm-4'>
                              <InputText
                                label='Nome'
                                name='firstName'
                                type='text'
                                icon=''
                              />
                            </div>
                            <div className='col-lg-4 col-sm-4'>
                              <InputText
                                label='Sobrenome'
                                name='lastName'
                                type='text'
                                icon=''
                              />
                            </div>
                            <div className='col-lg-4 col-sm-4'>
                              <InputText
                                label='E-mail'
                                name='email'
                                type='text'
                                icon=''
                              />
                            </div>
                            <div className='col-lg-4 col-sm-4'>
                              <InputText
                                label='Senha'
                                name='password'
                                type='password'
                                icon=''
                              />
                            </div>
                            <div className='col-lg-4 col-sm-4'>
                              <InputText
                                label='Confirme a senha'
                                name='passwordConfirmation'
                                type='password'
                                icon=''
                              />
                            </div>
                            <div className='col-lg-4 col-sm-4'>
                              <SelectInput
                                label='Permissão'
                                name='role'
                                popoverHelp='Somente o Administrador pode adicionar usuários'
                                className='sort-ordering-select-no-search'
                                onChange={e => handleRole(e, values, setValues)}
                              >
                                <option value=''>selecione</option>
                                {permissions.userRole === 'admin' ? (
                                  <option value='admin'>Admin</option>
                                ) : (
                                  ''
                                )}
                                <option value='client_admin'>
                                  Cliente - Admin
                                </option>
                                <option value='client_recruiter'>
                                  Cliente - Recrutador
                                </option>
                                <option value='client_creator'>
                                  Cliente - Criador de Provas
                                </option>
                              </SelectInput>
                            </div>
                            {clientInput === true &&
                            permissions.userRole === 'admin' ? (
                              <div className='col-lg-4 col-sm-4'>
                                <SelectInput
                                  label='Cliente'
                                  name='client'
                                  className='sort-ordering-select-no-search'
                                >
                                  {clients.length > 0 ? (
                                    clients.map(function (client) {
                                      return (
                                        <option
                                          key={`${client.uuid}-${Math.random()
                                            .toString(36)
                                            .slice(2)}`}
                                          value={client.uuid}
                                        >
                                          {client.name}
                                        </option>
                                      )
                                    })
                                  ) : (
                                    <option value=''>Selecione</option>
                                  )}
                                </SelectInput>
                              </div>
                            ) : (
                              ''
                            )}
                            <div className='col-lg-4 col-sm-4'>
                              <SelectInput
                                label='Status'
                                name='status'
                                className='sort-ordering-select-no-search'
                              >
                                <option value='active'>Ativo</option>
                                <option value='inactive'>Inativo</option>
                              </SelectInput>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-12 '>
                  <div className='new-group-action-buttons'>
                    <button className='theme-btn' type='submit'>
                      Cadastrar
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </>
    )
  }
  return <div></div>
}

export default NewUser
