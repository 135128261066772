import React, { useEffect, useState } from 'react'
import DateFnsUtils from '@date-io/date-fns'
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import { convertTimestamp } from '../../utils/utilFunctions'

const InlineDateTimePicker = ({ name, onChange, error, ...props }) => {
  const [selectedDate, setSelectedDate] = useState(
    name == 'startDate' ? new Date() : null
  )

  const handleChange = e => {
    if (e == 'Invalid Date') {
      setSelectedDate(new Date())
    } else {
      setSelectedDate(e)
    }
    const syntheticEvent = {
      target: {
        name: name,
        value: e
      }
    }
    onChange(syntheticEvent)
  }

  useEffect(() => {
    if (props.initialDate !== null) {
      setSelectedDate(convertTimestamp(props.initialDate))
    }
  }, [])

  return (
    <>
      <div className='input-box'>
        <label className='label-text'>{props.label}</label>
        <div className='form-group'>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker
              {...props}
              autoOk={true}
              variant='inline'
              ampm={false}
              value={selectedDate}
              onChange={handleChange}
              format='dd/MM/yyyy HH:mm'
              invalidDateMessage='Formato de data inválido'
              minDateMessage='A data não pode ser menor que a data atual'
            />
          </MuiPickersUtilsProvider>
          {error && (
            <div style={{ color: 'red' }} className='error'>
              {error}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default InlineDateTimePicker
