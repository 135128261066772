import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import $ from 'jquery'
import Message from '../utils/messages/message'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import InputText from '../utils/form/inputText'
import SelectInput from '../utils/form/selectInput'
import HttpRequests from '../api/httpRequests'
import '../features/newKnowledgeArea/newKnowledgeArea.css'

function NewKnowledgeArea() {
  let history = useHistory()
  const [loading, setLoading] = useState(true)
  const [msgType, setMsgType] = useState('')

  useEffect(() => {
    window.$('.sort-ordering-select-no-search').selectpicker()
    if (loading === true) {
      $('.preloader').show()
      setLoading(false)
    } else {
      $('.preloader').delay('500').fadeOut(1000)
    }
  })

  if (loading === false) {
    return (
      <>
        <Formik
          initialValues={{
            name: '',
            ExternalId: '',
            status: 'active'
          }}
          validationSchema={Yup.object({
            name: Yup.string()
              .min(2, 'Deve ter entre 2 e 80 caracteres')
              .max(80, 'Deve ter entre 2 e 80 caracteres')
              .required('Campo obrigatório'),
            ExternalId: Yup.string()
              .min(1, 'Deve ter entre 1 e 40 caracteres')
              .max(40, 'Deve ter entre 1 e 40 caracteres'),
            status: Yup.string().required('Campo obrigatório')
          })}
          onSubmit={async (values, { setSubmitting }) => {
            $('.preloader').show()

            try {
              let valuesData = {
                name: values.name,
                external_id: values.ExternalId,
                status: values.status
              }

              if (values.ExternalId === '') {
                delete valuesData.external_id
              }

              await HttpRequests.createKnowledgeArea(valuesData)

              setSubmitting(false)
              setMsgType('insert-successful')
              history.push('/knowledge-areas')
            } catch (error) {
              switch (error.response.status) {
                case 401:
                  setMsgType('401')
                  break

                case 403:
                  setMsgType('403')
                  break

                case 406:
                  setMsgType('406')
                  break

                case 409:
                  setMsgType('409')
                  break

                default:
                  setMsgType('500')
              }

              window.scrollTo(0, 0)
            } finally {
              $('.preloader').delay('500').fadeOut(1000)
            }
          }}
        >
          <Form>
            <div className='row mt-5'>
              <div className='col-lg-12'>
                <Message msgType={msgType} />
                <div className='card-box-shared'>
                  <div className='card-box-shared-title'>
                    <h3 className='widget-title'>
                      Cadastrar Área de Conhecimento
                    </h3>
                  </div>
                  <div className='card-box-shared-body'>
                    <div className='user-form'>
                      <div className='contact-form-action'>
                        <div className='row'>
                          <div className='col-lg-4 col-sm-4'>
                            <InputText
                              label='Área de Conhecimento'
                              popoverHelp='Ao criar uma pergunta você selecionará a respectiva área de conhecimento. Ex: Lógica, Desenvolvimento, Português.'
                              name='name'
                              type='text'
                              icon=''
                            />
                          </div>
                          <div className='col-lg-4 col-sm-4'>
                            <InputText
                              label='ID Externo'
                              popoverHelp='Somente utilize em caso de integração. O ID externo identificará a Área de Conhecimento na estrutura de API.'
                              name='ExternalId'
                              type='text'
                              icon=''
                            />
                          </div>
                          <div className='col-lg-4 col-sm-4'>
                            <SelectInput
                              label='Status'
                              name='status'
                              className='sort-ordering-select-no-search'
                            >
                              <option value='active'>Ativo</option>
                              <option value='inactive'>Inativo</option>
                            </SelectInput>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12 '>
                <div className='new-group-action-buttons'>
                  <button className='theme-btn' type='submit'>
                    Cadastrar
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </>
    )
  }
  return <div></div>
}

export default NewKnowledgeArea
