import React from 'react'
import PopoverHelp from '../../utils/buttons/popoverHelp'
import '../form/selectInput.css'

export default function SelectInput({ label, ...props }) {
  return (
    <>
      <div className='input-box'>
        <div className='popover-help'>
          <label className='label-text'>{label}</label>
          {props.popoverHelp ? <PopoverHelp content={props.popoverHelp} /> : ''}
        </div>
        <div className='form-group'>
          <div
            style={props.width ? { width: props.width } : {}}
            className='sort-ordering user-form-short'
          >
            <select {...props} />
          </div>
        </div>
      </div>
    </>
  )
}
