import React from 'react'
import { useField } from 'formik'
import PopoverHelp from '../../utils/buttons/popoverHelp'
import './inputText'

export default function InputText({ label, icon, ...props }) {
  const [field, meta] = useField(props)
  return (
    <>
      <div className='input-box'>
        <div className='popover-help'>
          <label className='label-text'>{label}</label>
          {props.popoverHelp ? <PopoverHelp content={props.popoverHelp} /> : ''}
        </div>
        <div className='form-group'>
          <input className='form-control' {...field} {...props} />
          <span className={icon}></span>
          {meta.touched && meta.error ? (
            <div style={{ color: 'red' }} className='error'>
              {meta.error}
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}
