import React from 'react'
import { Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Page403 from '../template/page403'

export function PrivateRoute({
  component: Component,
  resource,
  scope,
  ...rest
}) {
  const permissions = useSelector(state => state.permissions.value)

  const isAuthorized = () =>
    Object.prototype.hasOwnProperty.call(permissions, resource) &&
    Object.prototype.hasOwnProperty.call(permissions[resource], scope)

  return (
    <Route
      {...rest}
      render={props => {
        return isAuthorized() ? <Component {...props} /> : <Page403 />
      }}
    />
  )
}
