import React from 'react'
import { useTable, usePagination } from 'react-table'
import './tablePaginated.css'
import $ from 'jquery'
import MessageClosable from '../../utils/messages/messageClosable'
import { convertTimestampFormated } from '../../utils/utilFunctions'

function TablePaginated({ columns, data, candidateInfo, fetchData }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: -1
    },
    usePagination
  )

  const [msgType] = React.useState('')

  React.useEffect(() => {
    $('.preloader').show()
    window.$('.sort-ordering-select-no-search').selectpicker()
    fetchData({ pageIndex, pageSize })
  }, [fetchData, pageIndex, pageSize])
  return (
    <div>
      <div className='card-box-shared'>
        <div className='card-box-shared-title'>
          <h3 className='widget-title'>
            {candidateInfo.candidate_name} - {candidateInfo.candidate_email}
          </h3>
        </div>
        <div className='card-box-shared-body'>
          {page.length > 0 ? (
            <div>
              <MessageClosable msgType={msgType} />
              <div className='statement-table purchase-table table-responsive mb-5'>
                <table className='table' {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup, headerGroupIndex) => (
                      <tr
                        key={`headerGroup-${headerGroupIndex}`}
                        {...headerGroup.getHeaderGroupProps()}
                      >
                        {headerGroup.headers.map((column, columnIndex) => (
                          <th
                            key={`header-${headerGroupIndex}-${columnIndex}`}
                            className=''
                            scope='col'
                            {...column.getHeaderProps()}
                          >
                            {column.render('Header')}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row, rowIndex) => {
                      prepareRow(row)
                      return (
                        <tr key={`row-${rowIndex}`} {...row.getRowProps()}>
                          {row.cells.map((cell, cellIndex) => {
                            if (
                              cell.column.id === 'start_date' ||
                              cell.column.id === 'end_date'
                            ) {
                              return (
                                <td
                                  key={`cell-${rowIndex}-${cellIndex}`}
                                  {...cell.getCellProps()}
                                >
                                  <div className='statement-info'>
                                    {cell.value === undefined ||
                                    cell.value === null
                                      ? 'Aguardando finalização'
                                      : convertTimestampFormated(
                                          cell.value,
                                          true
                                        )}
                                  </div>
                                </td>
                              )
                            }
                            if (cell.column.id === 'total_time') {
                              return (
                                <td
                                  key={`cell-${rowIndex}-${cellIndex}`}
                                  {...cell.getCellProps()}
                                >
                                  <div className='statement-info'>
                                    {cell.value === undefined ||
                                    cell.value === null
                                      ? 'Aguardando finalização'
                                      : cell.value}
                                  </div>
                                </td>
                              )
                            }
                            if (cell.column.id === 'results') {
                              return (
                                <td
                                  key={`cell-${rowIndex}-${cellIndex}`}
                                  {...cell.getCellProps()}
                                >
                                  <div className='statement-info'>
                                    {cell.value.length === 0
                                      ? 'Aguardando finalização'
                                      : cell.value}
                                  </div>
                                </td>
                              )
                            }
                            if (cell.column.id === 'score') {
                              return (
                                <td
                                  key={`cell-${rowIndex}-${cellIndex}`}
                                  {...cell.getCellProps()}
                                >
                                  <div className='statement-info'>
                                    {cell.value === undefined ||
                                    cell.value === null
                                      ? 'Aguardando finalização'
                                      : cell.value * 100}
                                  </div>
                                </td>
                              )
                            }
                            if (cell.column.id === 'status') {
                              switch (cell.value) {
                                case 'done':
                                  return (
                                    <td
                                      key={`cell-${rowIndex}-${cellIndex}`}
                                      style={{ width: '50px' }}
                                      {...cell.getCellProps()}
                                    >
                                      <div className='statement-info'>
                                        <span className='badge bg-success text-white p-1'>
                                          Finalizado
                                        </span>
                                      </div>
                                    </td>
                                  )
                                case 'awaiting_evaluation':
                                  return (
                                    <td
                                      key={`cell-${rowIndex}-${cellIndex}`}
                                      style={{ width: '50px' }}
                                      {...cell.getCellProps()}
                                    >
                                      <div className='statement-info'>
                                        <span className='badge bg-primary text-white p-1'>
                                          Em avaliação
                                        </span>
                                      </div>
                                    </td>
                                  )
                                case 'applied':
                                  return (
                                    <td
                                      key={`cell-${rowIndex}-${cellIndex}`}
                                      style={{ width: '50px' }}
                                      {...cell.getCellProps()}
                                    >
                                      <div className='statement-info'>
                                        <span className='badge bg-warning text-white p-1'>
                                          Aplicado
                                        </span>
                                      </div>
                                    </td>
                                  )
                              }
                            }
                            return (
                              <td
                                key={`cell-${rowIndex}-${cellIndex}`}
                                {...cell.getCellProps()}
                              >
                                <div className='statement-info'>
                                  {cell.render('Cell')}
                                </div>
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              <div className='page-navigation-wrap mt-4 mb-4'>
                <div className='page-navigation-inner d-inline-block'>
                  <div className='page-navigation'>
                    <button
                      type='button'
                      className='btn page-go page-prev'
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      <i className='la la-arrow-left icon-table'></i>
                    </button>
                    <ul className='page-navigation-nav'>
                      <li>
                        <span>
                          Página <strong>{pageIndex + 1}</strong>{' '}
                        </span>
                      </li>
                    </ul>
                    <button
                      type='button'
                      className='btn page-go page-next'
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                    >
                      <i className='la la-arrow-right icon-table'></i>
                    </button>
                    <div className='page-size'>
                      <label>Itens por página: </label>
                      <div className='select-input sort-ordering user-form-short'>
                        <select
                          className='sort-ordering-select-no-search'
                          value={pageSize}
                          onChange={e => {
                            setPageSize(Number(e.target.value))
                          }}
                        >
                          {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                              {pageSize}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p>Essa prova ainda não possui aplicações.</p>
          )}
        </div>
      </div>
    </div>
  )
}

export default TablePaginated
