import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import $ from 'jquery'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import AuthService from '../api/authService'
import { PrivateRoute } from './PrivateRoute'
import Header from '../template/header'
import Preloader from '../template/preloader'
import SideBar from '../template/sideBar'
import Footer from '../template/footer'
import Dashboard from '../pages/dashboard'
import Candidate from '../pages/candidates'
import CandidateApplication from '../pages/candidateApplications'
import Clients from '../pages/clients'
import NewClient from '../pages/newClient'
import EditClient from '../pages/editClient'
import Quizzes from '../pages/quizzes'
import NewQuiz from '../pages/newQuiz'
import EditQuiz from '../pages/editQuiz'
import Questions from '../pages/questions'
import NewGroup from '../pages/newGroup'
import EditGroup from '../pages/editGroup'
import Groups from '../pages/groups'
import Page500 from '../template/page500'
import Page404 from '../template/page404'
import Page403 from '../template/page403'
import Page403Blocked from '../template/page403Blocked'
import AuthServiceContext from '../api/authServiceContext'
import NewBehaviorArea from '../pages/newBehaviorArea'
import BehaviorAreas from '../pages/behaviorAreas'
import EditBehaviorAreas from '../pages/editBehaviorAreas'
import newBehavior from '../pages/newBehavior'
import Behaviors from '../pages/behaviors'
import EditBehavior from '../pages/editBehavior'
import NewKnowledgeArea from '../pages/newKnowledgeArea'
import KnowledgeAreas from '../pages/knowledgeAreas'
import EditKnowledgeArea from '../pages/editKnowledgeArea'
import NewUser from '../pages/newUser'
import Users from '../pages/users'
import EditUser from '../pages/editUser'
import ErrorPage from '../template/errorPage'
import Applications from '../pages/applications'
import Evaluation from '../pages/evaluation'
import { useKeycloak } from '@react-keycloak/web'
import { addPermissions } from '../features/permissions/permissionsSlice'

export const AppRouter = () => {
  const { keycloak } = useKeycloak()
  const [loading, setLoading] = useState(true)
  const [apiErrors, setApiErrors] = useState(false)
  const userPermissions = useSelector(state => state.permissions.value)
  const dispatch = useDispatch()

  const getPermissions = async () => {
    $('.preloader').show()
    try {
      const response = await AuthService.getPermissions()
      dispatch(addPermissions({ userPermissions: response }))
      setLoading(false)
      $('.preloader').delay('250').fadeOut(500)
    } catch (error) {
      if (error.response) {
        if (error.response.status === 403) {
          dispatch(addPermissions({ userPermissions: 'blocked' }))
          setLoading(false)
        } else {
          dispatch(addPermissions({ userPermissions: false }))
          setLoading(false)
          keycloak.logout()
        }
      } else {
        dispatch(addPermissions({ userPermissions: false }))
        setApiErrors(true)
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    if (userPermissions === undefined) {
      getPermissions()
    }
  })

  if (loading === true) {
    return <div></div>
  }

  if (userPermissions === 'blocked') {
    return <Page403Blocked />
  }

  if (apiErrors) {
    return <Page500 />
  }

  if (userPermissions !== undefined && userPermissions !== false) {
    return (
      <AuthServiceContext.Provider value={userPermissions}>
        <Router>
          <Header />
          <Preloader />
          <section className='dashboard-area'>
            <SideBar />
            <div className='dashboard-content-wrap'>
              <div className='container-fluid'>
                <Switch>
                  <Route exact path='/'></Route>
                  <PrivateRoute
                    resource='dashboard'
                    scope='retrieve'
                    exact
                    path='/dashboard'
                    component={Dashboard}
                  />
                  <PrivateRoute
                    resource='candidate'
                    scope='list'
                    exact
                    path='/candidates'
                    component={Candidate}
                  />
                  <PrivateRoute
                    resource='candidate'
                    scope='list'
                    exact
                    path='/candidates/:keycloak_id'
                    component={CandidateApplication}
                  />
                  <PrivateRoute
                    resource='client'
                    scope='create'
                    exact
                    path='/clients/create'
                    component={NewClient}
                  />
                  <PrivateRoute
                    resource='client'
                    scope='update'
                    exact
                    path='/clients/:uuid'
                    component={EditClient}
                  />
                  <PrivateRoute
                    resource='client'
                    scope='list'
                    exact
                    path='/clients'
                    component={Clients}
                  />
                  <PrivateRoute
                    resource='group'
                    scope='create'
                    exact
                    path='/groups/create'
                    component={NewGroup}
                  />
                  <PrivateRoute
                    resource='group'
                    scope='update'
                    exact
                    path='/groups/:uuid'
                    component={EditGroup}
                  />
                  <PrivateRoute
                    resource='group'
                    scope='list'
                    exact
                    path='/groups'
                    component={Groups}
                  />
                  <PrivateRoute
                    resource='behavior-area'
                    scope='create'
                    exact
                    path='/behavior-area/create'
                    component={NewBehaviorArea}
                  />
                  <PrivateRoute
                    resource='behavior-area'
                    scope='update'
                    exact
                    path='/behavior-area/:uuid'
                    component={EditBehaviorAreas}
                  />
                  <PrivateRoute
                    resource='behavior-area'
                    scope='list'
                    exact
                    path='/behavior-area'
                    component={BehaviorAreas}
                  />
                  <PrivateRoute
                    resource='behavior'
                    scope='create'
                    exact
                    path='/behavior-area/:uuid/behavior/create'
                    component={newBehavior}
                  />
                  <PrivateRoute
                    resource='behavior'
                    scope='update'
                    exact
                    path='/behavior-area/:uuid/behavior/:behavior_uuid/edit'
                    component={EditBehavior}
                  />
                  <PrivateRoute
                    resource='behavior'
                    scope='list'
                    exact
                    path='/behavior-area/:uuid/behaviors'
                    component={Behaviors}
                  />
                  <PrivateRoute
                    resource='quiz'
                    scope='list'
                    exact
                    path='/quizzes'
                    component={Quizzes}
                  />
                  <PrivateRoute
                    resource='quiz'
                    scope='create'
                    exact
                    path='/quizzes/create'
                    component={NewQuiz}
                  />
                  <PrivateRoute
                    resource='quiz'
                    scope='update'
                    exact
                    path='/quizzes/:uuid'
                    component={EditQuiz}
                  />
                  <PrivateRoute
                    resource='question'
                    scope='create'
                    exact
                    path='/quizzes/:uuid/questions'
                    component={Questions}
                  />
                  <PrivateRoute
                    resource='knowledge-area'
                    scope='create'
                    exact
                    path='/knowledge-areas/create'
                    component={NewKnowledgeArea}
                  />
                  <PrivateRoute
                    resource='knowledge-area'
                    scope='list'
                    exact
                    path='/knowledge-areas'
                    component={KnowledgeAreas}
                  />
                  <PrivateRoute
                    resource='knowledge-area'
                    scope='update'
                    exact
                    path='/knowledge-areas/:uuid'
                    component={EditKnowledgeArea}
                  />
                  <PrivateRoute
                    resource='user'
                    scope='create'
                    exact
                    path='/users/create'
                    component={NewUser}
                  />
                  <PrivateRoute
                    resource='user'
                    scope='list'
                    exact
                    path='/users'
                    component={Users}
                  />
                  <PrivateRoute
                    resource='user'
                    scope='update'
                    exact
                    path='/users/:uuid'
                    component={EditUser}
                  />
                  <PrivateRoute
                    resource='application'
                    scope='list'
                    exact
                    path='/applications/:uuid'
                    component={Applications}
                  />
                  <PrivateRoute
                    resource='application-evaluation'
                    scope='list'
                    exact
                    path='/evaluation/:uuid'
                    component={Evaluation}
                  />
                  <Route exact path='/error/:error'>
                    <ErrorPage />
                  </Route>
                  <Route path='/error_500'>
                    <Page500 />
                  </Route>
                  <Route path='/error_404'>
                    <Page404 />
                  </Route>
                  <Route path='/error_403'>
                    <Page403 />
                  </Route>
                  <Route path='*'>
                    <Page404 />
                  </Route>
                </Switch>
                <Footer />
              </div>
            </div>
          </section>
        </Router>
      </AuthServiceContext.Provider>
    )
  } else {
    return (
      <Router>
        <Route exact path='/error/:error'>
          <ErrorPage />
        </Route>
      </Router>
    )
  }
}
