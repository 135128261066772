import React, { useState } from 'react'
import Spinner from './spinner'
import './image.css'
import Message from '../../../utils/messages/message'
import HttpRequests from '../../../api/httpRequests'
import ButtonDeleteImageQuestion from '../../../utils/buttons/roundButtonDelete'

export default function UploadImage(props) {
  const [loading, setLoading] = useState(false)
  const [logo, setLogo] = useState(props.logo)
  const [selectedFile, setSelectedFile] = useState(null)
  const [msgType, setMsgType] = useState('')

  const handlerLogoFile = e => {
    setMsgType('')
    const file = e.target.files[0]
    if (file.type === 'image/png' || file.type === 'image/jpeg') {
      setSelectedFile(file)
      setMsgType('')
    } else {
      setSelectedFile(null)
      setMsgType('invalid-image')
    }
  }

  const renderLogo = () => {
    if (logo !== null) {
      return (
        <div className='client-logo-area'>
          <img src={logo} />
          <ButtonDeleteImageQuestion
            size='small'
            onClick={removeImageQuestion}
          />
        </div>
      )
    } else {
      return (
        <div className='upload-input-background'>
          <label className='label-text'>Imagem</label>
          <Message msgType={msgType} />
          <div className='form-group'>
            <div className='upload-input'>
              <input
                type='file'
                className='form-control'
                onChange={handlerLogoFile}
              />
              <div className='edit-action'>
                <ul className='edit-list'>
                  <li>
                    <span className='theme-btn edit-btn' onClick={submitLogo}>
                      <i className='la la-edit mr-1 font-size-16'></i>Upload
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  const submitLogo = async () => {
    setMsgType('')
    if (selectedFile) {
      setLoading(true)
      const formData = new FormData()
      formData.append('image', selectedFile)
      try {
        const response = await HttpRequests.uploadImageQuestion(
          formData,
          props.uuid
        )
        setLogo(response.data.data.image)
      } catch (error) {
        switch (error.response.status) {
          case 401:
            setMsgType('401')
            break

          case 403:
            setMsgType('403')
            break

          case 406:
            setMsgType('406')
            break

          case 409:
            setMsgType('409')
            break

          case 412:
            setMsgType('412')
            break

          case 423:
            setMsgType('423')
            break

          case 424:
            setMsgType('424')
            break

          default:
            setMsgType('500')
        }
      } finally {
        setLoading(false)
      }
    } else {
      setMsgType('invalid-image')
    }
  }

  const removeImageQuestion = async () => {
    setLoading(true)
    try {
      const response = await HttpRequests.patchQuestion(
        { image: null },
        props.uuid
      )
      setLogo(response.data.data.image)
    } catch (error) {
      switch (error.response.status) {
        case 401:
          setMsgType('401')
          break

        case 403:
          setMsgType('403')
          break

        case 406:
          setMsgType('406')
          break

        case 409:
          setMsgType('409')
          break

        case 412:
          setMsgType('412')
          break

        case 423:
          setMsgType('423')
          break

        case 424:
          setMsgType('424')
          break

        default:
          setMsgType('500')
      }
    } finally {
      setLoading(false)
    }
  }

  if (loading === true) {
    return (
      <div className='client-logo-area'>
        <Spinner />
      </div>
    )
  }

  return renderLogo()
}
