import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      color: '#8a8989',
      textTransform: 'capitalize',
      backgroundColor: '#c8c8c8',
      '&:hover': {
        backgroundColor: '#686868'
      }
    }
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
}))

export default function FloatingActionButtons(props) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Fab {...props} color='secondary' aria-label='edit'>
        <DeleteOutlinedIcon />
      </Fab>
    </div>
  )
}
