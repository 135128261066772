import React, { useEffect } from 'react'
import $ from 'jquery'

const Preloader = () => {
  return (
    <div className='preloader'>
      <div className='loader'>
        <svg className='spinner' viewBox='0 0 50 50'>
          <circle
            className='path'
            cx='25'
            cy='25'
            r='20'
            fill='none'
            strokeWidth='5'
          ></circle>
        </svg>
      </div>
    </div>
  )
}

export const ActivatePreloader = ({ show = false }) => {
  useEffect(() => {
    if (show) {
      $('.preloader').show()
    } else {
      $('.preloader').delay('500').fadeOut(1000)
    }
  }, [show])

  return <div></div>
}

export default Preloader
