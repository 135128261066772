import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      color: '#fff',
      textTransform: 'capitalize',
      backgroundColor: '#51be78',
      '&:hover': {
        backgroundColor: '#388554'
      }
    }
  }
}))

export default function FloatingActionButtons(props) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Fab {...props} color='primary' aria-label='add'>
        <AddIcon />
      </Fab>
    </div>
  )
}
