import React from 'react'
import { useKeycloak } from '@react-keycloak/web'

const Page403Blocked = () => {
  const { keycloak } = useKeycloak()

  return (
    <section className='error-area padding-top-70px padding-bottom-90px'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-7 mx-auto'>
            <div className='error-content text-center'>
              <div className='section-heading'>
                <h1>Erro 403</h1>
                <h3 className='section__title pb-3'>
                  Whoops! Acesso não autorizado
                </h3>
                <p className='section__desc'>
                  Seu usuário está inativo. Contate o administrador.
                </p>
                <button className='theme-btn' onClick={() => keycloak.logout()}>
                  Retornar para tela de login
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Page403Blocked
