import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const quizzesSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    addInfo(state, action) {
      const { quizName, uuid } = action.payload
      state.quizName = quizName
      state.uuid = uuid
    }
  }
})

export const { addInfo } = quizzesSlice.actions

export default quizzesSlice.reducer
