import React from 'react'

const Page500 = () => {
  return (
    <section className='error-area padding-top-70px padding-bottom-90px'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-7 mx-auto'>
            <div className='error-content text-center'>
              <h1>Erro 500</h1>
              <div className='section-heading'>
                <h3 className='section__title pb-3'>
                  Whoops! Ocorreu um erro interno
                </h3>
                <p className='section__desc'>
                  A sua solicitação não pode ser atendida no momento.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Page500
