import React from 'react'
import { useField } from 'formik'
import MaskedInput from 'react-text-mask'

export default function InputMask({ label, mask, icon, ...props }) {
  const [field, meta] = useField(props)
  return (
    <>
      <div className='input-box'>
        <label className='label-text'>{label}</label>
        <div className='form-group'>
          <MaskedInput
            guide={false}
            className='form-control'
            mask={mask}
            {...field}
            {...props}
          />
          <span className={icon}></span>
          {meta.touched && meta.error ? (
            <div style={{ color: 'red' }} className='error'>
              {meta.error}
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}
