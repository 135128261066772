import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import '../features/questions/questions.css'
import HttpRequests from '../api/httpRequests'
import Message from '../utils/messages/message'
import RoundButtonAdd from '../utils/buttons/roundButtonAdd'
import SelectInput from '../features/selectInput/selectInput'
import { ActivatePreloader } from '../template/preloader'
import FileUploader from '../utils/form/fileUploader/fileUploader'
import { SiMicrosoftexcel } from 'react-icons/si'
import QuestionAccordion from '../features/questionAccordion/questionAccordion'

export default function Questions() {
  let { uuid } = useParams()
  const [msgType, setMsgType] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [knowledgeAreas, setKnowledgeAreas] = useState([])
  const [questionFields, setQuestionFields] = useState([])
  const [quizData, setQuizData] = useState({})
  const formikValues = useRef(null)
  const [openedAccordions, setOpenedAccordions] = useState(false)

  const newQuestionType = useRef('multiple_choice')

  const retrieveQuizData = async () => {
    const response = await HttpRequests.retrieveQuiz(uuid)
    if (response.status === 200) {
      setQuizData(response.data.data)
    }
  }

  const listKnowledgeAreas = async () => {
    const response = await HttpRequests.listKnowledgeAreas({
      per_page: 200
    })
    const activeKnowledgeAreas = []
    if (response.status === 200) {
      response.data.data.map(function (knowledgeArea) {
        if (knowledgeArea.status === 'active') {
          activeKnowledgeAreas.push(knowledgeArea)
        }
      })
      setKnowledgeAreas(activeKnowledgeAreas)
    }
  }

  const listQuestions = async () => {
    const response = await HttpRequests.listQuestions({
      quiz: uuid,
      per_page: 200
    })
    if (response.status === 200) {
      const questionTreated = treatQuestionData(response.data.data)
      setQuestionFields(questionTreated)
    }
  }

  const getContents = async () => {
    setIsLoading(true)
    await listKnowledgeAreas()
    await listQuestions()
    await retrieveQuizData()
    setIsLoading(false)
  }

  const handleNewQuestionType = e => {
    newQuestionType.current = e.target.value
  }

  const treatQuestionData = questionData => {
    try {
      let questions = []
      let questionTreated = []

      questionData.map(question => {
        questionTreated = {
          uuid: question.uuid,
          description: question.description === ' ' ? '' : question.description,
          instructions:
            question.instructions === ' ' ? '' : question.instructions,
          order: question.order,
          weight: question.weight,
          type: question.type,
          allow_skip: question.allow_skip,
          image: question.image,
          knowledge_area: question.knowledge_area.uuid,
          quiz: question.quiz.uuid,
          created_at: question.created_at,
          updated_at: question.updated_at,
          answerType: 'text',
          answers: [],
          isAnswerCharacterLimit: false,
          isTime: false
        }

        if (question.time !== null) {
          questionTreated = {
            ...questionTreated,
            time: question.time,
            isTime: true
          }
        }

        if (question.answer_character_limit !== null) {
          questionTreated = {
            ...questionTreated,
            answer_character_limit: question.answer_character_limit,
            isAnswerCharacterLimit: true
          }
        }

        questions.push(questionTreated)
      })
      return questions
    } catch {
      setMsgType('500')
    }
  }

  const handleAdd = async () => {
    if (formikValues.current) {
      const response = await updateQuestion(formikValues.current)
      if (!response) {
        alert(
          'Verifique o preenchimento das questões antes de adicionar uma nova'
        )
        return
      }
    }

    setOpenedAccordions(`panel${questionFields.length}`)

    let data = {
      description: ' ',
      instructions: ' ',
      order: questionFields.length + 1,
      weight: 1,
      type: newQuestionType.current,
      allow_skip: true,
      knowledge_area: knowledgeAreas[0].uuid,
      quiz: uuid
    }

    if (newQuestionType.current === 'open_ended') {
      data.answer_character_limit = 300
    }
    const response = await HttpRequests.createQuestion(data)
    if (response.status_code === 201) {
      const questionTreated = treatQuestionData([response.data.data])
      setQuestionFields([...questionFields, ...questionTreated])
    }
    if (response.status_code === 422) {
      alert('Não é possível adicionar mais questões')
    }
  }

  const handleDelete = async uuid => {
    formikValues.current = null

    const response = await HttpRequests.deleteQuestion(uuid)
    if (response.status === 204) {
      const newQuestions = questionFields.filter(
        question => question.uuid !== uuid
      )
      setQuestionFields(newQuestions)
    }
  }

  const updateQuestion = async question => {
    let data = {
      description: question.description,
      instructions: question.instructions === '' ? null : question.instructions,
      order: question.order,
      weight: question.weight,
      allow_skip: question.allow_skip,
      answer_character_limit: question.answer_character_limit,
      knowledge_area: question.knowledge_area
    }

    if (question.time === '') {
      data.time = null
    }

    if (question.time > 0) {
      data.time = question.time
    }

    const response = await HttpRequests.patchQuestion(data, question.uuid)
    if (response.status_code === 200) {
      setMsgType('update-success')
      const newQuestions = questionFields.map(q =>
        q.uuid === question.uuid ? question : q
      )
      setQuestionFields(newQuestions)
      return true
    } else {
      return false
    }
  }

  const handleFormikChange = values => {
    formikValues.current = { ...values }
  }

  const quizExportXlsx = async quiz_id => {
    try {
      setIsLoading(true)
      const quiz = await HttpRequests.retrieveQuiz(quiz_id)
      const response = await HttpRequests.exportQuiz(quiz_id)
      let url = ''
      let link = ''
      switch (response.status) {
        case 200:
          url = window.URL.createObjectURL(response.data)
          link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${quiz.data.data.title}-quiz.xlsx`)
          document.body.appendChild(link)
          link.click()
          break
        case 204:
          alert('Quiz not found')
          break
      }
    } catch (error) {
      alert('Link não disponível')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getContents()
  }, [])

  return (
    <>
      <ActivatePreloader show={isLoading} />
      {!isLoading && (
        <div>
          <div className='row mt-5'>
            <div className='col-lg-6'>
              <h1>{quizData.title}</h1>
            </div>
            <div className='col-lg-6 export-quiz'>
              <button
                className='theme-btn btn-export-quiz'
                title='Exportar quiz'
                onClick={() => quizExportXlsx(uuid)}
              >
                Exportar questões{' '}
                <SiMicrosoftexcel
                  className='react-icons btn-export-quiz-icon'
                  size='1em'
                />
              </button>
            </div>
          </div>
          <div className='info-message'>
            <Message msgType={msgType} />
          </div>
          <QuestionAccordion
            openedAccordions={openedAccordions}
            knowledgeAreas={knowledgeAreas}
            questions={questionFields}
            newQuestionType={newQuestionType}
            updateQuestion={updateQuestion}
            handleFormikChange={handleFormikChange}
            handleDelete={handleDelete}
            setIsLoading={setIsLoading}
            setMsgType={setMsgType}
          />
          <div className='row'>
            <div className='col-md-6'>
              <div className='select-question-type'>
                <SelectInput
                  name='newQuestionType'
                  className='sort-ordering-select-no-search'
                  onChange={e => handleNewQuestionType(e)}
                >
                  <option value='multiple_choice'>Multipla escolha</option>
                  <option value='open_ended'>Aberta</option>
                </SelectInput>
              </div>
              <div className='btn-inline'>
                <RoundButtonAdd onClick={() => handleAdd()} />
                <FileUploader
                  setMsgType={setMsgType}
                  uuid={uuid}
                  getContents={getContents}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
