import React from 'react'
import { useHistory } from 'react-router-dom'
import $ from 'jquery'
import './quizzes.css'
import TablePaginated from '../features/quizzes/tablePaginated'
import HttpRequests from '../api/httpRequests'
import { useKeycloak } from '@react-keycloak/web'

export default function Quizzes() {
  let history = useHistory()
  const { keycloak } = useKeycloak()

  const columns = React.useMemo(
    () => [
      {
        Header: 'Título',
        accessor: 'title'
      },
      {
        Header: 'Link para Execução',
        accessor: 'link'
      },
      {
        Header: 'Status',
        accessor: 'status'
      },
      {
        Header: '',
        accessor: 'uuid'
      }
    ],
    []
  )

  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const fetchIdRef = React.useRef(0)

  const fetchData = React.useCallback(
    async ({
      pageSize,
      pageIndex,
      filterByTitle,
      filterByGroup,
      filterByStatus
    }) => {
      const fetchId = ++fetchIdRef.current
      setLoading(true)

      try {
        let data = {
          per_page: pageSize,
          page: pageIndex + 1
        }

        if (filterByTitle !== null || filterByTitle !== '') {
          data.title = filterByTitle
        }

        if (filterByGroup !== null || filterByGroup !== '') {
          data.group = filterByGroup
        }

        if (
          filterByStatus !== undefined &&
          filterByStatus !== '' &&
          filterByStatus.length > 0
        ) {
          let filterByStatusToString = filterByStatus.join(',')
          data.statuses = filterByStatusToString
        }

        const response = await HttpRequests.listQuizzes(data)

        if (fetchId === fetchIdRef.current) {
          setData(response.data.data)
          setLoading(false)
        }

        document.title = 'Provas - Admin Rocketquiz'
      } catch (error) {
        switch (error.response.status) {
          case 401:
            keycloak.logout()
            break

          case 403:
            history.push('/error/403')
            break

          case 404:
            history.push('/error/404')
            break

          default:
            history.push('/error/500')
        }
      } finally {
        $('.preloader').delay('250').fadeOut(500)
      }
    },
    []
  )
  return (
    <TablePaginated
      columns={columns}
      data={data}
      fetchData={fetchData}
      loading={loading}
    />
  )
}
