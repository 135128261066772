import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import QuestionForm from '../questionForm/questionForm'

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
}))

const QuestionAccordion = ({
  openedAccordions,
  knowledgeAreas,
  questions,
  updateQuestion,
  handleFormikChange,
  handleDelete,
  setMsgType
}) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(openedAccordions)

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  useEffect(() => {
    setExpanded(openedAccordions)
  }, [openedAccordions])

  return (
    <>
      <div className='row mt-5'>
        <div className='col-lg-12'>
          <div className='card-box-shared'>
            <div className='card-box-shared-title'>
              <h3 className='widget-title'>Questões</h3>
            </div>
            <div className='card-box-shared-body'>
              <div className='user-form'>
                <div className='contact-form-action'>
                  {questions.length > 0 ? (
                    questions.map((question, i) => (
                      <Accordion
                        key={i}
                        expanded={expanded === `panel${i}`}
                        onChange={handleChange(`panel${i}`)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel${i}bh-content`}
                          id={`panel${i}bh-header`}
                        >
                          <Typography className={classes.heading}>
                            {`Questão ${i + 1}`}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <QuestionForm
                            knowledgeAreas={knowledgeAreas}
                            question={question}
                            updateQuestion={updateQuestion}
                            handleFormikChange={handleFormikChange}
                            handleDelete={handleDelete}
                            setMsgType={setMsgType}
                          />
                        </AccordionDetails>
                      </Accordion>
                    ))
                  ) : (
                    <p>Nenhuma questão cadastrada</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default QuestionAccordion
