import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTable, usePagination } from 'react-table'
import './tablePaginated.css'
import $ from 'jquery'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Message from '../../utils/messages/message'
import { GiPapers } from 'react-icons/gi'
import AuthServiceContext from '../../api/authServiceContext'
import PreloaderLight from '../../template/preloaderLight'
import PopoverHelp from '../../utils/buttons/popoverHelp'
import HttpRequests from '../../api/httpRequests'

function TablePaginated({ columns, data, fetchData }) {
  const permissions = React.useContext(AuthServiceContext)
  const [loading] = React.useState(false)

  // BEGIN: Modal
  const [open, setOpen] = React.useState(false)
  const [setRowUuid] = React.useState('')

  const handleClose = () => {
    setOpen(false)
    setRowUuid('')
  }
  // END: Modal

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: -1
    },
    usePagination
  )

  const [candidateNameFilter, setCandidateNameFilter] = React.useState(null)
  const [candidateEmailFilter, setCandidateEmailFilter] = React.useState(null)

  const handleCandidateNameFilter = e => {
    setCandidateNameFilter(e.target.value)
  }

  const handleCandidateEmailFilter = e => {
    setCandidateEmailFilter(e.target.value)
  }

  const handleSearch = () => {
    $('.preloader').show()
    let filters = {
      candidate_name: candidateNameFilter,
      candidate_email: candidateEmailFilter
    }
    fetchData({ pageIndex, pageSize, filters })
  }

  const handleKeyPressInputSearch = e => {
    if (
      e.key === 'Enter' &&
      (candidateNameFilter !== null || candidateEmailFilter !== null)
    ) {
      handleSearch()
    }
  }

  let history = useHistory()

  const handleChangePage = rowCells => {
    history.push('/candidates/' + rowCells[2].value)
  }

  const [msgType] = React.useState('')

  React.useEffect(() => {
    $('.preloader').show()
    window.$('.sort-ordering-select-no-search').selectpicker()
    fetchData({ pageIndex, pageSize })
  }, [fetchData, pageIndex, pageSize])

  const exportAllApplications = async () => {
    try {
      $('.preloader').show()
      const response = await HttpRequests.exportCandidateApplications()
      let url = ''
      let link = ''
      switch (response.status) {
        case 200:
          url = window.URL.createObjectURL(response.data)
          link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `${permissions.clientInfo.name}-applications.xlsx`
          )
          document.body.appendChild(link)
          link.click()
          break
        case 204:
          alert('Quiz not found')
          break
      }
    } catch (error) {
      alert('Link não disponível')
    } finally {
      $('.preloader').delay('500').fadeOut(1000)
    }
  }

  return (
    <div>
      {loading === true ? <PreloaderLight /> : ''}
      <div className='card-box-shared'>
        <div className='card-box-shared-title'>
          <h3 className='widget-title'>Candidatos</h3>
          <div className='search-table-area'>
            <div className='search-table-input form-group'>
              <input
                onKeyPress={e => handleKeyPressInputSearch(e)}
                onChange={e => handleCandidateNameFilter(e)}
                className='form-control'
                type='text'
                name='search'
                placeholder='Pesquisar por nome'
              />
              <input
                onKeyPress={e => handleKeyPressInputSearch(e)}
                onChange={e => handleCandidateEmailFilter(e)}
                className='form-control'
                type='text'
                name='search'
                placeholder='Pesquisar por e-mail'
              />
              <span
                onClick={handleSearch}
                className='la la-search search-icon'
              ></span>
            </div>
          </div>
        </div>
        <div className='card-box-shared-body'>
          <Message msgType={msgType} />
          <div className='statement-table purchase-table table-responsive mb-5'>
            <table className='table' {...getTableProps()}>
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr
                    key={headerGroup.id}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map(column => {
                      if (column.id === 'role') {
                        return (
                          <th
                            key={column.id}
                            className=''
                            scope='col'
                            {...column.getHeaderProps()}
                          >
                            <div className='popover-help'>
                              {column.render('Header')}
                              <PopoverHelp content='Somente o Administrador pode adicionar usuários' />
                            </div>
                          </th>
                        )
                      }

                      return (
                        <th
                          key={column.id}
                          className=''
                          scope='col'
                          {...column.getHeaderProps()}
                        >
                          <div className='popover-help'>
                            {column.render('Header')}
                          </div>
                        </th>
                      )
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, rowIndex) => {
                  prepareRow(row)
                  return (
                    <tr key={`row-${rowIndex}`} {...row.getRowProps()}>
                      {row.cells.map((cell, cellIndex) => {
                        if (cell.column.id === 'candidate_keycloak_id') {
                          return (
                            <td
                              key={`cell-${rowIndex}-${cellIndex}`}
                              style={{ width: '5%' }}
                              {...cell.getCellProps()}
                            >
                              <div className='statement-info'>
                                <div className='edit-action'>
                                  <ul className='edit-list action-btn'>
                                    <li>
                                      <button
                                        type='button'
                                        onClick={() =>
                                          handleChangePage(cell.row.cells)
                                        }
                                        className='theme-btn questions-btn'
                                        title='Aplicações'
                                      >
                                        <GiPapers
                                          className='react-icons'
                                          size='1em'
                                        />
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </td>
                          )
                        }

                        return (
                          <td
                            key={`cell-${rowIndex}-${cellIndex}`}
                            {...cell.getCellProps()}
                          >
                            <div className='statement-info'>
                              {cell.render('Cell')}
                            </div>
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className='page-navigation-wrap mt-4 mb-4'>
            <div className='page-navigation-inner d-inline-block'>
              <div className='page-navigation'>
                <button
                  type='button'
                  className='btn page-go page-prev'
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <i className='la la-arrow-left icon-table'></i>
                </button>
                <ul className='page-navigation-nav'>
                  <li>
                    <span>
                      Página <strong>{pageIndex + 1}</strong>{' '}
                    </span>
                  </li>
                </ul>
                <button
                  type='button'
                  className='btn page-go page-next'
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  <i className='la la-arrow-right icon-table'></i>
                </button>
                <div className='page-size'>
                  <label>Itens por página: </label>
                  <div className='select-input sort-ordering user-form-short'>
                    <select
                      className='sort-ordering-select-no-search'
                      value={pageSize}
                      onChange={e => {
                        setPageSize(Number(e.target.value))
                      }}
                    >
                      {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          {pageSize}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className='export-candidate-applications'>
              <button
                className='theme-btn'
                onClick={() => exportAllApplications()}
              >
                Exportar todas as aplicações
              </button>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Aviso!'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Tem certeza que deseja desativar este registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className='edit-action'>
            <ul className='edit-list'>
              <li>
                <button className='theme-btn edit-btn' onClick={handleClose}>
                  cancelar
                </button>
              </li>
            </ul>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default TablePaginated
