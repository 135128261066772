import React from 'react'

const MessageClosable = props => {
  const [msgType, setMsgType] = React.useState('')
  let cssClass = ''
  let msg = ''

  switch (msgType) {
    case 'deactivate':
      cssClass = 'alert alert-success'
      msg = 'Registro desativado com sucesso!'
      break

    case 'activate':
      cssClass = 'alert alert-success'
      msg = 'Registro ativado com sucesso!'
      break

    case 'invalid-image':
      cssClass = 'alert alert-danger'
      msg = 'Selecione um arquivo JPG ou PNG.'
      break

    default:
      msg = ''
      break
  }

  React.useEffect(() => {
    setMsgType(props.msgType)
  }, [props.msgType])

  return (
    <div className={cssClass + ' alert-dismissible fade show'} role='alert'>
      {msg}
      <button
        type='button'
        className='close'
        data-dismiss='alert'
        aria-label='Close'
      >
        <span aria-hidden='true'>&times;</span>
      </button>
    </div>
  )
}
export default MessageClosable
