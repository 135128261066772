import React from 'react'

const totalCandidates = props => {
  return (
    <div className='col-lg-4 column-lmd-2-half column-md-2-full'>
      <div className='icon-box d-flex align-items-center'>
        <div className='icon-element icon-element-bg-6 flex-shrink-0'>
          <i className='la la-users'></i>
        </div>
        <div className='info-content'>
          <h4 className='info__title mb-2'>Total de candidatos</h4>
          <span className='info__count'>{props.data}</span>
        </div>
      </div>
    </div>
  )
}

export default totalCandidates
