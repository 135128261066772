import React from 'react'
import { useParams } from 'react-router-dom'
import Page500 from '../template/page500'
import Page404 from '../template/page404'
import Page403 from '../template/page403'

const Error = () => {
  const { error } = useParams()

  switch (error) {
    case '403':
      return <Page403 />

    case '404':
      return <Page404 />

    default:
      return <Page500 />
  }
}

export default Error
