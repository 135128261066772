import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import { FiHelpCircle } from 'react-icons/fi'

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
    width: '300px'
  }
}))

export default function PopoverHelp(props) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div>
      <FiHelpCircle
        style={{ cursor: 'pointer' }}
        aria-describedby={id}
        variant='contained'
        color='primary'
        onClick={handleClick}
      >
        Open Popover
      </FiHelpCircle>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Typography className={classes.typography}>{props.content}</Typography>
      </Popover>
    </div>
  )
}
