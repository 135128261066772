import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  description: Yup.string()
    .min(1, 'Deve ter entre 1 e 5000 caracteres')
    .max(5000, 'Deve ter entre 1 e 5000 caracteres')
    .required('Campo obrigatório'),
  instructions: Yup.string()
    .nullable()
    .min(1, 'Deve ter entre 1 e 5000 caracteres')
    .max(5000, 'Deve ter entre 1 e 5000 caracteres'),
  order: Yup.string()
    .min(1, 'Deve ser um número entre 1 e 500')
    .max(500, 'Deve ser um número entre 1 e 500')
    .required('Digite um número'),
  isTime: Yup.boolean(),
  time: Yup.number().when('isTime', {
    is: true,
    then: Yup.number().nullable().min(1, 'Deve ser um valor maior que zero'),
    otherwise: Yup.number()
      .nullable()
      .min(1, 'Deve ser um valor maior que zero')
  }),
  weight: Yup.string()
    .min(1, 'Deve ser um valor maior que zero')
    .required('Digite um número'),
  type: Yup.string().required('Campo obrigatório'),
  allow_skip: Yup.string().required('Campo obrigatório'),
  isAnswerCharacterLimit: Yup.boolean(),
  answer_character_limit: Yup.number().when('isAnswerCharacterLimit', {
    is: true,
    then: Yup.number()
      .min(1, 'Deve ser um número entre 1 e 5000')
      .max(5000, 'Deve ser um número entre 1 e 5000')
      .required('Campo obrigatório')
  }),
  knowledge_area: Yup.string().required('Campo obrigatório'),
  answers: Yup.array().of(
    Yup.object().shape({
      description: Yup.string()
        .min(1, 'Deve ter entre 1 e 5000 caracteres')
        .max(255, 'Deve ter entre 1 e 5000 caracteres')
    })
  )
})

export default validationSchema
