import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import $ from 'jquery'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import InputText from '../utils/form/inputText'
import SelectInput from '../utils/form/selectInput'
import HttpRequests from '../api/httpRequests'
import '../features/editBehaviorAreas/editBehaviorAreas.css'
import Message from '../utils/messages/message'

function EditBehaviorAreas() {
  let { uuid } = useParams()
  let history = useHistory()
  const [loading, setLoading] = useState(true)
  const [msgType, setMsgType] = useState('')
  const [data, setData] = useState(null)

  const retrieveBehaviorAreas = async () => {
    $('.preloader').show()
    try {
      const response = await HttpRequests.retrieveBehaviorAreas(uuid)
      setData(response.data.data)
      setLoading(false)
    } catch {
      history.push('/error_500')
    } finally {
      $('.preloader').delay('500').fadeOut(1000)
    }
  }

  useEffect(() => {
    window.$('.sort-ordering-select-no-search').selectpicker()
    if (loading === true) {
      retrieveBehaviorAreas()
    }
  })

  if (loading === false) {
    return (
      <>
        <Formik
          initialValues={{
            name: data.name,
            status: data.status
          }}
          validationSchema={Yup.object({
            name: Yup.string()
              .min(2, 'Deve ter entre 2 e 80 caracteres')
              .max(80, 'Deve ter entre 2 e 80 caracteres')
              .required('Campo obrigatório'),
            status: Yup.string().required('Campo obrigatório')
          })}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              $('.preloader').show()
              await HttpRequests.patchBehaviorAerea(
                {
                  name: values.name,
                  status: values.status
                },
                uuid
              )
              setSubmitting(false)
              setMsgType('insert-successful')
              history.push('/behavior-area')
            } catch (error) {
              switch (error.response.status) {
                case 401:
                  setMsgType('401')
                  break

                case 403:
                  setMsgType('403')
                  break

                case 406:
                  setMsgType('406')
                  break

                case 409:
                  setMsgType('409')
                  break

                default:
                  setMsgType('500')
              }

              window.scrollTo(0, 0)
            } finally {
              $('.preloader').delay('500').fadeOut(1000)
            }
          }}
        >
          <Form>
            <div className='row mt-5'>
              <div className='col-lg-12'>
                <Message msgType={msgType} />
                <div className='card-box-shared'>
                  <div className='card-box-shared-title'>
                    <h3 className='widget-title'>
                      Editar Área de Comportamento
                    </h3>
                  </div>
                  <div className='card-box-shared-body'>
                    <div className='user-form'>
                      <div className='contact-form-action'>
                        <div className='row'>
                          <div className='col-lg-4 col-sm-4'>
                            <InputText
                              label='Área de Comportamento'
                              name='name'
                              type='text'
                              icon=''
                            />
                          </div>
                          <div className='col-lg-4 col-sm-4'>
                            <SelectInput
                              label='Status'
                              name='status'
                              className='sort-ordering-select-no-search'
                            >
                              <option value='active'>Ativo</option>
                              <option value='inactive'>Inativo</option>
                            </SelectInput>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12 '>
                <div className='new-group-action-buttons'>
                  <button className='theme-btn' type='submit'>
                    Cadastrar
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </>
    )
  }
  return <div></div>
}

export default EditBehaviorAreas
