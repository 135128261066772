import React, { useEffect } from 'react'
import PopoverHelp from '../../utils/buttons/popoverHelp'
import './selectInput.css'

const SelectInput = ({ ...props }) => {
  useEffect(() => {
    window.$('.sort-ordering-select-no-search').selectpicker()
  }, [])
  return (
    <>
      <div className='input-box'>
        <div className='popover-help'>
          <label className='label-text'>{props.label}</label>
          {props.popoverHelp ? <PopoverHelp content={props.popoverHelp} /> : ''}
        </div>
        <div className='form-group'>
          <div className='sort-ordering user-form-short'>
            <select {...props} />
            {props.error && (
              <>
                <div style={{ color: 'red' }} className='error'>
                  {props.error}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default SelectInput
