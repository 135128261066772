import React from 'react'
import ReactDOM from 'react-dom'
import { AppRouter } from './routes'
import store from './app/store'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'
import Preloader from './template/preloader'
import AuthService from './api/authService'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import keycloak from './keycloak'

const keycloakInitOptions = {
  onLoad: 'login-required',
  checkLoginIframe: false
}

ReactDOM.render(
  <React.StrictMode>
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={keycloakInitOptions}
      LoadingComponent={<Preloader />}
      onEvent={event => AuthService.onKeycloakEvent(event)}
      onTokens={tokens => AuthService.onKeycloakTokens(tokens)}
    >
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </ReactKeycloakProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
