import React from 'react'
import { useHistory } from 'react-router-dom'
import $ from 'jquery'
import '../features/behaviors/behaviors.css'
import TablePaginated from '../features/behaviors/tablePaginated'
import HttpRequests from '../api/httpRequests'

export default function BehaviorAreas() {
  let history = useHistory()

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nome',
        accessor: 'name'
      },
      {
        Header: 'Status',
        accessor: 'status'
      },
      {
        Header: '',
        accessor: 'uuid'
      }
    ],
    []
  )
  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const fetchIdRef = React.useRef(0)

  const fetchData = React.useCallback(async ({ pageSize, pageIndex, uuid }) => {
    const fetchId = ++fetchIdRef.current
    setLoading(true)

    try {
      const response = await HttpRequests.listBehaviors({
        per_page: pageSize,
        page: pageIndex + 1,
        behavior_area: uuid
      })

      if (fetchId === fetchIdRef.current) {
        setData(response.data.data)
        setLoading(false)
      }
    } catch {
      history.push('/error_500')
    } finally {
      $('.preloader').delay('250').fadeOut(500)
    }
  }, [])
  return (
    <TablePaginated
      columns={columns}
      data={data}
      fetchData={fetchData}
      loading={loading}
    />
  )
}
