const convertTimestampFormated = (timestamp, showTime) => {
  const a = new Date(timestamp * 1000)
  const year = a.getFullYear()
  const month = a.getMonth() + 1
  const date = a.getDate()
  const hour = a.getHours()
  const min = a.getMinutes()
  const sec = a.getSeconds()

  if (showTime === true) {
    const time =
      (date <= 9 ? '0' + date : date) +
      '/' +
      (month <= 9 ? '0' + month : month) +
      '/' +
      year +
      ' ' +
      (hour <= 9 ? '0' + hour : hour) +
      ':' +
      (min <= 9 ? '0' + min : min) +
      ':' +
      (sec <= 9 ? '0' + sec : sec)
    return time
  } else {
    const time = date + '/' + (month <= 9 ? '0' + month : month) + '/' + year
    return time
  }
}

const convertTimestamp = timestamp => {
  const date = new Date(timestamp * 1000)
  const hours = date.getHours()
  const minutes = '0' + date.getMinutes()
  const seconds = '0' + date.getSeconds()
  hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2)
  return date
}

const convertToTimestampMidnight = (date, start) => {
  const timestempDateMidnight =
    start === true
      ? new Date(date).setHours(0, 0, 0, 0)
      : new Date(date).setHours(23, 59, 59, 999)
  return timestempDateMidnight / 1000
}

const convertToTimestamp = date => {
  const timestempDate = new Date(date).getTime() / 1000
  return timestempDate
}
const dataBaseFormat = (timestamp, showTime) => {
  const a = new Date(timestamp * 1000)
  const year = a.getFullYear()
  const month = a.getMonth() + 1
  const date = a.getDate()
  const hour = a.getHours()
  const min = a.getMinutes()
  a.getSeconds()
  if (showTime === true) {
    const time =
      year +
      '-' +
      (month <= 9 ? '0' + month : month) +
      '-' +
      date +
      ' ' +
      hour +
      ':' +
      min
    return time
  } else {
    const time = year + '-' + (month <= 9 ? '0' + month : month) + '-' + date
    return time
  }
}

export {
  convertTimestampFormated,
  convertTimestamp,
  convertToTimestamp,
  dataBaseFormat,
  convertToTimestampMidnight
}

export default { convertTimestamp }
